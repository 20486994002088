import { CardMedia } from "@mui/material";
import axios from "axios";
import { FC, useMemo } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export const CustomMediaCard: FC<any> = props => {
  const {
    config,
    __config,
    metaData,
    src,
    component,
    defaultValue: _defaultValue,
    disabledCache,
    disablePlaceholder,
    iconConfig,
    isLoadingImage,
    cursorPointer,
    info,
    pageId,
    appId,
    renderId,
    "data-bx-key": dataBxKey,
    ...restProps
  } = props;

  const location = useLocation();
  const isHttpUrl = iconConfig?.icon?.toLowerCase().match(/http[s]*:\/\//);
  const isInBuilder = location.pathname.startsWith("/buildx/form-builder/");
  const match = typeof iconConfig?.icon === "string" ? iconConfig?.icon?.match(/^([^:]+):\/\/(.+)$/) : null;
  const mediaType = match?.[1];
  const mediaName = match?.[2];
  const accessToken = localStorage.getItem("accessToken");
  const deviceToken = localStorage.getItem("accessToken-device");
  const token = accessToken || deviceToken;

  const { data } = useQuery(
    ["media", mediaType, mediaName],
    async () => {
      if (!mediaType || !mediaName) {
        return {
          items: [],
        };
      }

      const response = await axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/media", {
        params: {
          keyword: mediaName || undefined,
          type: mediaType || undefined,
          strategy: "summary",
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    },
    {
      enabled: iconConfig?.visibility === "PRIVATE" && !isHttpUrl,
      refetchOnMount: false,
    }
  );

  const items = data?.items || [];
  const imageUrlFetchedValue = items?.length > 0 && items[0]?.name === mediaName ? items[0]?.url : null;
  let imageUrl = match && !isHttpUrl ? iconConfig?.url : iconConfig?.icon || imageUrlFetchedValue;
  let defaultValue = _defaultValue;

  const disableCache = url => {
    if (!url || typeof url !== "string") return null;

    const uniqueParam = new Date().getTime();
    const separator = url?.includes("?") ? "&" : "?";
    if (url?.startsWith("blob:")) return url;
    return `${url}${separator}_bx_t=${uniqueParam}`;
  };
  let _src = src;
  const memoizedCachedImageUrl = useMemo(() => disableCache(imageUrl), [imageUrl]);
  const memoizedCachedDefaultValue = useMemo(() => disableCache(defaultValue), [defaultValue]);
  const memoizedCachedSrc = useMemo(() => disableCache(src), [src]);

  if (disabledCache) {
    imageUrl = memoizedCachedImageUrl;
    defaultValue = memoizedCachedDefaultValue;
    _src = memoizedCachedSrc;
  }
  const onErrorHandleHideImage = e => {
    if (disablePlaceholder) {
      e.currentTarget.style.display = "none";
    }
  };
  const getAutoComponentType = () => {
    const fileExtension = typeof src === "string" ? src?.split(".").pop()?.toLowerCase() : "";
    if (fileExtension) {
      if (["mp4", "webm", "ogg"].includes(fileExtension)) return "video";
      if (["mp3", "wav", "ogg"].includes(fileExtension)) return "audio";
      if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileExtension)) return "img";
    }
    return "img";
  };

  const resolvedComponent = component === "auto" ? getAutoComponentType() : component;
  const imageSrc = _src || defaultValue;
  const renderMedia = () => {
    if (resolvedComponent === "img" || !resolvedComponent) {
      return (
        <>
          {imageUrl ? (
            <img
              src={imageUrl}
              style={{
                width: "100%",
                height: "100%",
                position: isLoadingImage ? "unset" : "absolute",
                cursor: cursorPointer ? "pointer" : "default",
              }}
              alt={mediaName}
              onError={onErrorHandleHideImage}
              onLoad={e => {
                e.currentTarget.style.display = "unset";
              }}
            />
          ) : (
            <CardMedia
              component='img'
              {...restProps}
              src={imageSrc || (isInBuilder ? require("src/assets/images/icons/video-placeholder.png") : "")}
              onError={onErrorHandleHideImage}
              onLoad={e => {
                e.currentTarget.style.display = "unset";
              }}
            />
          )}
        </>
      );
    } else {
      return (
        <CardMedia
          component={resolvedComponent}
          src={imageUrl || _src}
          {...restProps}
          onError={onErrorHandleHideImage}
          onLoad={e => {
            e.currentTarget.style.display = "unset";
          }}
        />
      );
    }
  };

  return <div style={{ position: "relative", width: "100%", height: "100%" }}>{renderMedia()}</div>;
};
