import { id } from "date-fns/locale";
import _, { isArray } from "lodash";
import { memo, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useAppState } from "src/features/appState/hooks";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { selectComponentById } from "src/features/endUser/selectors";
import { useFetchData } from "src/hooks/useFetchData";
import store from "src/store/store";
import { StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { getSelectedPageInfo, stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import RenderChildren from "./RenderChildren";

function updatePagesForGroup(stepperObject: any, pageReference: string, replicaCount: number, basePath: string) {
  if (replicaCount < 0) {
    return stepperObject;
  }

  const newStepperObject = _.cloneDeep(stepperObject);

  if (!newStepperObject?.pages) return newStepperObject;

  const referencePages = _.cloneDeep(stepperObject.pages);
  newStepperObject.pages = referencePages;

  const mainPageIndex = _.findIndex(newStepperObject.pages, (page: any) => page.pageReference === pageReference);

  if (mainPageIndex !== -1) {
    if (replicaCount === 0) {
      newStepperObject.pages.splice(mainPageIndex, 1);
    } else {
      _.assign(newStepperObject.pages[mainPageIndex], {
        pageReference: `${basePath}[0]`,
        repeatReference: pageReference,
      });

      const replicas = _.times(replicaCount - 1, i =>
        _.assign(_.cloneDeep(newStepperObject.pages[mainPageIndex]), { pageReference: `${basePath}[${i + 1}]` })
      );

      newStepperObject.pages.splice(mainPageIndex + 1, 0, ...replicas);
    }
  }

  return newStepperObject;
}

const RepeatedItemsComponent = ({
  element: _element,
  elementId,
  item,
  viewsState,
  pageId,
  __data,
  currentApp,
  multiLingual,
  viewName,
  referenceStepperGroups,
  props,
  index,
}): any => {
  const { handleSelectRow: _handleSelectRow, ...restProps } = props;

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName: props?.info?.viewName });
  const { watch, setValue } = useAppState();

  // const { setViewsState } = useBXContext();
  let element = useSelector(state => selectComponentById(state, pageId, props?.info?.viewName, elementId), shallowEqual);
  const dispatch = useDispatch();

  const queryKeys = useMemo(() => [`${pageId}-${viewName}-${element?.props?.key}`], [pageId, viewName, element?.props?.key]);

  const minItem = useMemo(() => Number(element.config.repeated.minItems) || 0, [element.config.repeated.minItems]);
  const maxItem = useMemo(() => Number(element.config.repeated.maxItems) || Infinity, [element.config.repeated.maxItems]);
  const defaultNumItems = useMemo(() => Number(element.config.repeated.defaultItems), [element.config.repeated.defaultItems]);

  useFetchData({
    queryKeys,
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && element?.configData?.source,
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: viewName,
    componentKey: element?.props?.key,
    path: props?.path,
  });

  const key = useMemo(() => `${pageId}.${viewName}.${element?.props?.key}`, [pageId, viewName, element?.props?.key]);

  const repeatedState = watch(`${key}.repeatedState`, { pageId, viewName });
  const repeatedData = replaceDataPlaceholders({
    queryString: element?.config?.repeated?.data,
    item,
    viewsState,
    pageId,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    fallback: "",
    multiLingual: multiLingual,
    index,
  });

  const stepperParentKey = `${pageId}.${viewName}.${element?.config?.stepperParent?.key}.state`;
  const stepperGroup = element?.config?.stepperParent?.key ? watch(stepperParentKey, { pageId, viewName }) : null;
  const data =
    Array.isArray(repeatedState) && repeatedState.length > 0
      ? repeatedState
      : Array.from({ length: repeatedData?.length || minItem }, () => ({}));
  const repeatCount = _.clamp(data.length, minItem, maxItem);
  const repeatData = Array.from(
    {
      length: defaultNumItems && !element?.config?.repeated?.data ? _.clamp(defaultNumItems, minItem, maxItem) : repeatCount,
    },
    (_, index) => data[index] || {}
  );

  function handleSelectRow(selectedRowData) {
    const key = `${pageId}-${viewName}`;

    const currentViewsState = store.getState().buildxProvider.viewsState;
    const updatedViewsState = {
      ...currentViewsState,
      [key]: {
        ...currentViewsState?.[key],
        _selectedItem: selectedRowData,
      },
    };

    dispatch(setProviderState({ viewsState: updatedViewsState }));
  }

  useEffect(() => {
    if (repeatData && isArray(repeatData)) {
      setValue(
        `${key}.repeatedState`,
        Array.from(
          {
            length: repeatData?.length,
          },
          () => uuid()
        ),
        { pageId, viewName }
      );
    }
  }, [repeatedData]);

  useEffect(() => {
    if (stepperGroup) {
      let updatedStepperGroup = replaceDataPlaceholdersRecursively({
        obj: _.cloneDeep(updatePagesForGroup(stepperGroup, element?.props?.key, repeatData?.length, `${element?.props?.key}`)),
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: null,
      });
      if (updatedStepperGroup) {
        updatedStepperGroup.selected = getSelectedPageInfo(updatedStepperGroup.selectedPage, updatedStepperGroup.pages);
      }
      setValue(stepperParentKey, updatedStepperGroup, { pageId, viewName });
    }
  }, [stepperGroup?.groupName]);

  return repeatedState?.map?.((uuid, itemIndex) => {
    let hiddenByStepper = !!element?.config?.stepperParent?.key;
    if (element?.config?.stepperParent?.key) {
      const componentStepperState = stepperGroupComponentState(`${element.props.key}[${itemIndex}]`, stepperGroup);
      hiddenByStepper = componentStepperState !== StepperComponentState.FoundAndSelected;
    }

    return (
      <RenderChildren
        key={uuid}
        handleSelectRow={handleSelectRow}
        {...restProps}
        elementId={elementId}
        element={element}
        currentRepeatedItem={repeatedState?.[itemIndex]}
        index={itemIndex}
        parentDataIndex={index}
        repeatedParentKey={element?.props?.key}
        hiddenByStepper={hiddenByStepper}
        isRepeated={true}
        repeatedComponent={true}
      />
    );
  });
};

export default memo(RepeatedItemsComponent);
