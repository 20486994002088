import { gql } from "@apollo/client";
import axios from "axios";
import _ from "lodash";
import { osVersion } from "react-device-detect";
import { isHostAvailable } from "src/App";
import { setSession } from "src/contexts/JWTContext";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { apolloClient, encodeURLParams, replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { setEndUser } from "src/features/endUser/endUserSlice";
import store from "src/store/store";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { UserProfile } from "src/types/user";
import axiosServices from "src/utils/axios";
import externalAxiosServices from "src/utils/axiosExt";
import { isValidTargetPathAfterLogin } from "src/utils/generalUtils";
import { decompressData } from "src/utils/services";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { isLanguageRTL } from "src/views/pages/BuildX/AppBuilder/forms/ManageLanguages";
import { v4 as uuidv4 } from "uuid";
import config from "../config";

export const loadApps = async (
  location,
  navigate,
  fqdnApp,
  appRoutesMap,
  currentApp,
  fromLogin?: boolean,
  disableAutoNavigate?: boolean
) => {
  store.dispatch(setProviderState({ loadingApps: true }));
  axiosServices
    .get("/application")
    .then(async res => {
      try {
        let apps: BXApp[] = res.data?.map?.((col: any) => {
          const newApp = { ...col?.app };
          if (newApp.appConfig) {
            newApp.appConfig = decompressData(col?.app?.appConfig);
          }
          if (newApp.templateConfig) {
            newApp.templateConfig = decompressData(col?.app?.templateConfig);
          }
          if (newApp.upTemplateConfig) {
            newApp.upTemplateConfig = decompressData(col?.app?.upTemplateConfig);
          }
          return newApp;
        });
        store.dispatch(setProviderState({ appDescriptor: apps }));
        const index = location.pathname.split("/")?.[3] == "layout" ? 4 : 3;
        const _currentApp =
          apps?.find(
            (app?: BXApp) => app?.id === location.pathname.split("/")?.[index] || app?.slug === "/" + location.pathname.split("/")?.[1]
          ) || apps?.[0];

        store.dispatch(setProviderState({ currentApp: _currentApp }));
        if (apps?.length && fromLogin) {
          const firstApp = apps?.[0];
          const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
            (collection: BXAppCollection) => collection.pages.length > 0
          );

          const collectionSlug = fqdnApp ? "" : firstCollectionToHavePages?.slug;
          const landingPageUri = currentApp?.appConfig?.pageUri;

          const firstPageSlug = firstCollectionToHavePages?.pages?.filter(
            page => page?.info?.visibility !== "Hidden" && page?.unprotectedPage !== true
          )?.[0]?.slug;

          if (!disableAutoNavigate) {
            //After login and loading of app/s, try to access the target path intercepted if valid
            const targetPathAfterLogin = localStorage.getItem("targetPathAfterLogin");
            if (targetPathAfterLogin && Boolean(fqdnApp)) {
              if (isValidTargetPathAfterLogin(targetPathAfterLogin, appRoutesMap.current, _currentApp.id)) {
                navigate({ pathname: targetPathAfterLogin });
                localStorage.removeItem("targetPathAfterLogin");
              }
            } else {
              if (firstPageSlug) {
                navigate({
                  pathname: `${fqdnApp ? "" : firstApp.slug}${landingPageUri ?? `/${collectionSlug}/${firstPageSlug}`}`.replaceAll(
                    /\/+/g,
                    "/"
                  ),
                });
              } else {
                navigate({
                  pathname: `${fqdnApp ? "" : firstApp.slug}`.replaceAll(/\/+/g, "/"),
                });
              }
            }
          }
        }

        if (!_currentApp) {
          store.dispatch(setProviderState({ loadingApps: false }));
        }
      } catch (e) {
        store.dispatch(setProviderState({ loadingApps: false }));
      }
    })
    .catch(() => {
      store.dispatch(setProviderState({ loadingApps: false }));
    })
    .finally(() => {
      store.dispatch(setProviderState({ loadingApps: false }));
    });
};

export const loadPermissions = async () => {
  store.dispatch(setProviderState({ loadingApps: true }));
  return axiosServices
    .get(`/user/authorization-list`)
    .then(async res => {
      try {
        const permission = res.data;
        store.dispatch(setProviderState({ currentProfilePermissions: permission }));
      } catch (e) {}
    })
    .catch(() => {
      store.dispatch(setProviderState({ loadingApps: false }));
    })
    .finally(() => {});
};

export const addApp = (app: BXApp) => {
  const currentApps = store.getState().buildxProvider.appDescriptor;
  const updatedApps = [app, ...currentApps];
  store.dispatch(
    setProviderState({
      appDescriptor: updatedApps,
    })
  );
};

export const getAppDataSource = (appId: string, allowedApps) => {
  return allowedApps.find(app => app.id === appId);
};

export const loginToApp = async (
  appId: string,
  // <buildxProvider-change>
  allowedApps,
  currentApp,
  isAdministrationMode,
  location,
  navigate,
  appRoutesMap,
  currentProfileId,
  replaceDataPlaceholdersRecursively?: any,
  loginSuccess?: any,
  // </buildxProvider-change>
  email?: string,
  password?: string,
  recaptcha?: string,
  profileName?: string,
  cb: any = () => {},
  _profile?: any,
  _authApi?: any,
  fqdnApp?: any,
  loginType?: string,
  axiosParams?: any,
  disableAutoNavigate?: boolean
) => {
  const authApi = getAppDataSource(appId, allowedApps)?.appConfig?.auth?.authApi || _authApi;
  const enableRecaptcha = currentApp?.appConfig?.enableRecaptcha;
  if (
    !authApi?.uri ||
    currentApp?.appConfig?.auth?.type == "No Auth" ||
    isAdministrationMode ||
    location.pathname.startsWith("/buildx/form-builder")
  )
    return;
  let response = {} as any;
  const isGraphQL = authApi?.isGraphQL;

  const loginData: any = { email, password };
  if (enableRecaptcha && recaptcha) {
    loginData.recaptcha = recaptcha;
  }

  if (!loginType) {
    if (isGraphQL) {
      const isMutation = authApi?.graphQLMethod === "MUTATION" && authApi?.graphqlQuery?.includes("mutation");
      const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;

      const graphQLContext = {
        uri: encodeURLParams(replaceBaseUrl(authApi?.uri, currentApp || fqdnApp)),
        headers: replaceDataPlaceholdersRecursively({
          obj: { ...authApi?.headers },
          appId,
        }),
      };

      const operationKey = isMutation ? "mutation" : "query";
      const gqlQuery = gql`
        ${authApi?.graphqlQuery}
      `;

      const variables = {
        ...replaceDataPlaceholdersRecursively({
          obj: JSON.parse(authApi?.graphqlVariables || "{}"),
          config: authApi,
          login: loginData,
        }),
      };

      try {
        response = await operationFunction({
          [operationKey]: gqlQuery,
          variables,
          context: graphQLContext,
        });
      } catch (error) {
        console.error(`GraphQL ${isMutation ? "Mutation" : "Query"} Error:`, error);
        return;
      }
    } else {
      try {
        const authPayload = replaceDataPlaceholdersRecursively({
          obj: JSON.parse(authApi?.body || "{}"),
          login: loginData,
        });

        const filteredAuthPayload = Object.fromEntries(
          Object.entries(authPayload).filter(([_, value]) => value !== null && value !== undefined && value !== "undefined")
        );

        const computedAuthHeaders = replaceDataPlaceholdersRecursively({
          obj: { ...authApi?.headers },
          appId,
        });

        response = await externalAxiosServices.request({
          url: encodeURLParams(replaceBaseUrl(authApi?.uri, currentApp || fqdnApp)),
          method: authApi?.method || "POST",
          data: filteredAuthPayload,
          headers: computedAuthHeaders,
          params: {
            ...axiosParams,
          },
        });
      } catch (error) {
        return error;
      }
    }
  } else if (loginType == "google-login") {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    store.dispatch(
      setEndUser({
        initialStateValues: {
          props: {
            sso: {
              google: {
                clientId: fqdnApp?.appConfig.googleInfo.clientId,
                redirectUri: fqdnApp?.appConfig.googleInfo.redirectUrl,
                code,
              },
            },
          },
        },
      })
    );
    const { googleInfo } = fqdnApp?.appConfig || {};
    const { authEndpoint } = replaceDataPlaceholdersRecursively({
      obj: _.cloneDeep(googleInfo) || {},
    });

    try {
      response = await axiosServices.post(encodeURLParams(replaceBaseUrl(authEndpoint, fqdnApp as any)), {});
    } catch (e) {
      enqueueSnackbarRef?.("The user is not authorized", {
        variant: "error",
      });
    } finally {
      navigate("/login");
    }
  } else if (loginType == "shopify-login") {
    const { shopifyInfo } = fqdnApp?.appConfig || {};
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    store.dispatch(
      setEndUser({
        initialStateValues: {
          props: {
            sso: {
              shopify: {
                clientId: fqdnApp?.appConfig.shopifyInfo.clientId,
                code: token,
              },
            },
          },
        },
      })
    );
    const { authEndpoint } = replaceDataPlaceholdersRecursively({
      obj: _.cloneDeep(shopifyInfo) || {},
    });

    try {
      response = await axiosServices.post(encodeURLParams(replaceBaseUrl(authEndpoint, fqdnApp)), {});
    } catch (e) {
      enqueueSnackbarRef?.("The user is not authorized", {
        variant: "error",
      });
    } finally {
      navigate("/login");
    }
  }

  const { accessToken, principal } = {
    accessToken: _.get(response?.data, authApi?.accessTokenPath || "accessToken"),
    principal: {
      name: profileName || _.get(response?.data, authApi?.namePath || "principal.name") || uuidv4(),
      handle: _.get(response?.data, authApi?.usernamePath || "principal.handle"),
      profilePhoto: _.get(response?.data, authApi?.userPhotoPath || "principal.profilePhoto"),
    },
  };

  let _data;

  if (fqdnApp) {
    try {
      const { data } = await axiosServices.post(`app/${fqdnApp?.id}/token-exchange`, {
        token: accessToken,
        isLogin: true,
        errorMessage: "The user is not authorized",
        organizationId: fqdnApp?.org?.id,
        applicationId: fqdnApp?.id,
      });
      loginSuccess(data?.principal);
      setSession(data?.accessToken, data?.principal);
      await loadPermissions?.();
      loadApps?.(location, navigate, fqdnApp, appRoutesMap, currentApp, true, disableAutoNavigate);
    } catch (e: any) {
      enqueueSnackbarRef?.(e.message || "Wrong Services", {
        variant: "error",
      });
      setAppSession(fqdnApp?.id, currentProfileId, null, null, null, true);
    }
  }

  if (
    !_profile &&
    !localStorage.getItem("admin-login") &&
    !isHostAvailable &&
    !(fqdnApp?.appConfig?.withProfiles && fqdnApp?.appConfig?.isSingleSignOn)
      ? !fqdnApp
      : true
  ) {
    if (email && password && !_profile) {
      const { data } = await axiosServices.post(`application/${appId}/profile`, {
        profileName: profileName || _.get(response?.data, authApi?.namePath || "principal.name") || uuidv4(),
        creds: {
          username: email,
          password: password,
          recaptcha: recaptcha,
        },
      });
      _data = data;
    }
  }

  const data = _profile || _data;

  setAppSession(appId, currentProfileId, accessToken, principal, data);

  const currentAppProfiles = store.getState().buildxProvider.appProfiles;
  const updatedProfiles = {
    ...currentAppProfiles,
    [appId]: [...(currentAppProfiles[appId] || []).filter(profile => profile?.id !== data?.id), data],
  };
  store.dispatch(
    setProviderState({
      appProfiles: updatedProfiles,
    })
  );

  cb?.(response);
  return response;
};

const setAppSession = (
  appId: string,
  currentProfileId: string | null,
  accessToken?: string | null,
  user?: UserProfile | null,
  profile?: any,
  isFQDN?: boolean
) => {
  const prevAppTokens = store.getState().buildxProvider.appTokens;
  if (profile?.id) {
    localStorage.setItem(appId + "-profileId", profile?.id);
    store.dispatch(setProviderState({ currentProfileId: profile?.id }));
  }

  if (accessToken) {
    const updatedAppTokens = { ...prevAppTokens };

    updatedAppTokens[appId + `-${profile?.id}-accessToken`] = accessToken;
    updatedAppTokens[appId + `-${profile?.id}-user`] = user;
    store.dispatch(setProviderState({ appTokens: updatedAppTokens }));

    localStorage.setItem(appId + `-${profile?.id}-accessToken`, accessToken);
    localStorage.setItem(appId + `-${profile?.id}-user`, JSON.stringify(user));
  } else {
    const updatedAppTokens = { ...prevAppTokens };
    delete updatedAppTokens[appId + `-${currentProfileId}-accessToken`];
    delete updatedAppTokens[appId + `-${currentProfileId}-user`];
    store.dispatch(setProviderState({ appTokens: updatedAppTokens }));
    localStorage.removeItem(appId + `-${currentProfileId}-accessToken`);
    localStorage.removeItem(appId + `-${currentProfileId}-user`);
    if (isFQDN) {
      localStorage.removeItem(`accessToken`);
      localStorage.removeItem(`user`);
    }
  }
};

export const logoutOfApp = (appId: string, currentProfileId: string, isFQDN?: boolean) => {
  setAppSession(appId, currentProfileId, null, null, null, isFQDN);
};

export const getAuth = (
  appId: string,
  // <buildxProvider-change>
  currentProfileId,
  appProfiles,
  appTokens,
  currentApp,
  // </buildxProvider-change>
  _profileId?: string,
  noProfile?: boolean
) => {
  const profileId = _profileId || (!noProfile && currentProfileId);
  const profile = profileId ? appProfiles?.[appId]?.find((profile: any) => profile?.id == profileId) : appProfiles?.[appId]?.[0];

  // For FQDN apps, check profile-based token keys
  if (currentApp?.appConfig?.isSingleSignOn) {
    const profileToken = localStorage.getItem(appId + `-${profile?.id}-accessToken`);
    const profileUser = localStorage.getItem(appId + `-${profile?.id}-user`);
    if (profileToken) {
      return {
        token: profileToken,
        user: profileUser ? JSON.parse(profileUser) : null,
      };
    }
  }

  const token = appTokens[appId + `-${profileId || profile?.id}-accessToken`];
  const user = appTokens[appId + `-${profileId || profile?.id}-user`];

  if (
    (currentApp?.appConfig?.isSingleSignOn &&
      !localStorage.getItem("admin-login") &&
      !isHostAvailable &&
      !(currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) ||
    (!_profileId &&
      currentApp?.appConfig?.withProfiles &&
      currentApp?.appConfig?.isSingleSignOn &&
      currentApp?.appConfig?.enableGoogleSignIn)
  ) {
    return { token, user } as any;
  }
  return profile && ({ ...profile?.[appId]?.[0], token, user } as any);
};

export const registerAppDevice = async (
  appId: string,
  // <buildxProvider-change>
  allowedApps,
  currentApp,
  currentProfileId,
  appProfiles,
  appTokens,
  replaceDataPlaceholdersRecursively?: any,
  // </buildxProvider-change>
  _deviceApi?: any,
  fqdnApp?: any
) => {
  const deviceApi = getAppDataSource(appId, allowedApps)?.appConfig?.auth?.deviceApi || _deviceApi;
  const isGraphQL = deviceApi?.isGraphQL;
  if (!deviceApi?.uri) return;

  const deviceUDID = uuidv4();

  const deviceInfo = {
    appVersion: config.appVersion,
    deviceType: config.deviceType,
    deviceUDID,
    osVersion,
    pushToken: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userId: "",
  };

  const { token } = getAuth(currentApp?.id!, currentProfileId, appProfiles, appTokens, currentApp) || {};

  const isTokenExist = !!localStorage.getItem(`${appId}-accessToken-device`);
  if (isTokenExist) return;
  let res;

  if (isGraphQL) {
    const isMutation = deviceApi?.graphQLMethod === "MUTATION" && deviceApi?.graphqlQuery?.includes("mutation");
    const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;

    const graphQLContext = {
      uri: encodeURLParams(replaceBaseUrl(deviceApi?.uri, currentApp || fqdnApp)),
      headers: {
        ...replaceDataPlaceholdersRecursively({
          obj: deviceApi?.headers,
        }),
      },
    };

    const operationKey = isMutation ? "mutation" : "query";
    const gqlQuery = gql`
      ${deviceApi?.graphqlQuery}
    `;
    const variables = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(deviceApi?.graphqlVariables || "{}"),
      config: deviceInfo,
    });

    try {
      res = await operationFunction({
        [operationKey]: gqlQuery,
        variables,
        context: graphQLContext,
      });
    } catch (error) {
      console.error(`GraphQL ${isMutation ? "Mutation" : "Query"} Error:`, error);
      return;
    }
  } else if (!isGraphQL) {
    res = await axios.request({
      url: encodeURLParams(replaceBaseUrl(deviceApi?.uri, currentApp || fqdnApp)),
      method: deviceApi?.method || "POST",
      data: replaceDataPlaceholdersRecursively({
        obj: JSON.parse(deviceApi?.body || "{}"),
        config: deviceInfo,
      }),
      headers: {
        ...replaceDataPlaceholdersRecursively({
          obj: deviceApi?.headers,
        }),
      },
    });
  }
  const accessTokenResponse = _.get(res?.data, deviceApi?.accessTokenPath || "accessToken");

  if (accessTokenResponse) {
    localStorage.setItem(`${appId}-accessToken-device`, accessTokenResponse);
  }
  if (res?.data?.creationTime) {
    localStorage.setItem("token_created_at", res.data.creationTime);
  }
  localStorage.setItem("device_uuid", deviceUDID);
};

export const updateRTL = (multiLingual: any, isAdministrationMode: any, dispatch: any, data?: any) => {
  const checkIsRTL = !isAdministrationMode && isLanguageRTL(data?.selectedLanguage || multiLingual?.selectedLanguage);
  dispatch(setProviderState({ isRTL: checkIsRTL }));
  document.documentElement.dir = checkIsRTL ? "rtl" : "ltr";
};

export const handleGoogleSignInClick = (redirectUri: string, clientId: string) => {
  const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${redirectUri}&prompt=consent&response_type=code&client_id=${clientId}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&flowName=GeneralOAuthFlow`;
  window.open(googleUrl, "_self");
};
