import { FormControlLabel, Switch } from "@mui/material";
import { FC } from "react";
import { CustomSwitchProps } from "./types";

export const CustomSwitch: FC<CustomSwitchProps> = ({
  children,
  textTypo,
  format,
  formatString,
  config,
  __config,
  metaData,
  mapValuesObject,
  info,
  pageId,
  appId,
  renderId,
  wordwrap,
  defaultValue,
  onChange,
  label,
  "data-bx-key": dataBxKey,
  ...restProps
}) => {
  const defaultChecked = String(defaultValue) === "true" ? true : false;
  const e = (event: any) => {
    if (onChange) {
      onChange(event.target.checked, event);
    }
  };
  return (
    <FormControlLabel
      control={<Switch {...restProps} defaultChecked={defaultChecked} onChange={e} />}
      label={label}
      required={false}
      style={{ marginLeft: "0px", maxHeight: "100%" }}
    />
  );
};
