import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import Logo from "src/components/Logo";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import useAuth from "src/hooks/useAuth";
import store from "src/store/store";
import { BXApp } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import axiosServices from "src/utils/axios";
import { getAuth, loginToApp, registerAppDevice } from "src/utils/buildxProviderOperations";
import { checkVersion } from "src/utils/generalUtils";
import { LoginForm } from "src/views/pages/BuildX/AppBuilder/forms/LoginForm";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import UserProfiles from "../userProfiles/UserProfiles";

type PageAuthProps = {
  app: BXApp;
  children: React.ReactNode;
  page: BXPageType;
};

export const PageAuthGuard: FC<PageAuthProps> = ({ app, children, page }) => {
  const currentApp = useBuildxProviderValue("currentApp");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const allowedApps = useBuildxProviderValue("allowedApps");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const isSwitchingMode = useBuildxProviderValue("isSwitchingMode");
  const appTokens = useBuildxProviderValue("appTokens");
  const { appRoutesMap, checkAppAuth } = useBuildxContext();
  const { replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders();
  const { loginSuccess } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isEditForm, setIsEditForm] = useState<any>();
  const [isAddProfile, setIsAddProfile] = useState<any>();
  const [{ token, username, password, recaptcha }, setAuth] = useState({
    username: undefined,
    password: undefined,
    recaptcha: undefined,
    user: undefined,
    token: undefined,
    profileName: undefined,
  });
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const enableRecaptcha = currentApp?.appConfig?.enableRecaptcha;
  const recaptchaSiteKey = currentApp?.appConfig?.recaptchaSiteKey;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const auth = getAuth(app.id, currentProfileId, appProfiles, appTokens, currentApp);
      const { token } = auth || {};
      const { profileName: cProfileName, username: cUsername, password: cPassword, recaptcha: cRecaptcha } = auth?.creds || {};

      if (!_.isEmpty(cUsername) && !_.isEmpty(cPassword) && !token) {
        setLoading(true);
        registerAppDevice?.(app.id, allowedApps, currentApp, currentProfileId, appProfiles, appTokens, replaceDataPlaceholdersRecursively)
          .then(async () => {
            await loginToApp?.(
              app.id,
              allowedApps,
              currentApp,
              isAdministrationMode,
              location,
              navigate,
              appRoutesMap,
              currentProfileId,
              replaceDataPlaceholdersRecursively,
              loginSuccess,
              cUsername,
              cPassword,
              cRecaptcha,
              cProfileName
            ).finally(() => {
              setLoading(false);
              dispatch(setProviderState({ isSwitchingMode: undefined as any }));
            });
          })
          .finally(() => {});
      }
      if (!_.isNil(auth)) setAuth(auth);
    } catch (e) {}
  }, [currentApp?.id]);

  useEffect(() => {
    setError(null);
    setAuth({ username: undefined, password: undefined, user: undefined, token: undefined, profileName: undefined, recaptcha: undefined });
    setIsEditForm(undefined);
    setIsAddProfile(false);
  }, [isSwitchingMode, currentApp]);

  const onSaveClick = async (values: any, cb: any = () => {}) => {
    try {
      setDisabled(true);
      await registerAppDevice?.(
        app.id,
        allowedApps,
        currentApp,
        currentProfileId,
        appProfiles,
        appTokens,
        replaceDataPlaceholdersRecursively
      );

      const loginResult = await loginToApp?.(
        app.id,
        allowedApps,
        currentApp,
        isAdministrationMode,
        location,
        navigate,
        appRoutesMap,
        currentProfileId,
        replaceDataPlaceholdersRecursively,
        loginSuccess,
        values.email,
        values.password,
        values.recaptcha,
        values.profileName,
        async () => {
          if (!isEditForm) {
            setDisabled(false);
            dispatch(setProviderState({ isSwitchingMode: false }));
          }
          if (isEditForm) {
            setDisabled(true);
            try {
              const { data } = await axiosServices.patch(`application/${app?.id}/profile/${isEditForm?.id}`, {
                profileName: values?.profileName,
                creds: {
                  username: values?.email,
                  password: values?.password,
                  recaptcha: values?.recaptcha,
                },
              });
              const currentApps = store.getState().buildxProvider.appProfiles;
              const updatedProfiles = {
                ...currentApps,
              };
              if (app?.id) {
                updatedProfiles[app.id] = updatedProfiles[app.id]?.map((profile: any) => (profile?.id === isEditForm?.id ? data : profile));
              }
              dispatch(
                setProviderState({
                  appProfiles: updatedProfiles,
                })
              );
            } finally {
              setDisabled(false);
              setIsEditForm(undefined);
            }
          }

          setError("");
          if (typeof cb === "function") cb?.();
        },
        isEditForm,
        undefined,
        undefined,
        undefined,
        {
          skipAuthErrorClear: true,
        }
      );

      if (loginResult) {
        setDisabled(false);
        setError(loginResult.message || loginResult.errorMessage);
      }
    } catch (error: any) {
      setDisabled(false);
      setError(error.message);
      cb?.();
    }
  };

  const handleDeleteClick = (profile: any) => {
    axiosServices
      .delete(`application/${currentApp?.id}/profile/${profile?.id}`)
      .then(({ data }) => {
        const currentApps = store.getState().buildxProvider.appProfiles;
        const updatedProfiles = {
          ...currentApps,
        };
        localStorage.removeItem(currentApp?.id + `-${profile?.id}-accessToken`);
        localStorage.removeItem(currentApp?.id + `-${profile?.id}-user`);
        updatedProfiles[currentApp?.id] = (updatedProfiles[currentApp?.id] || []).filter((_profile: any) => _profile?.id !== profile?.id);
        if (currentProfileId === profile?.id) {
          const newProfileId = updatedProfiles[currentApp?.id]?.[0]?.id;
          if (newProfileId) {
            localStorage.setItem(currentApp?.id + "-profileId", newProfileId);
          }
          dispatch(setProviderState({ currentProfileId: newProfileId }));
        }

        dispatch(
          setProviderState({
            appProfiles: updatedProfiles,
          })
        );
      })
      .catch(() => {});
  };

  //Mansour change "login issue with fqdn"
  // if (loadingApps) return <></>;

  if (page?.unprotectedPage) {
    return <>{children}</>;
  }

  if (!checkVersion(currentApp?.appVersion || currentApp?.templateConfig?.appVersion)) {
    return (
      <Grid container height='50%' width='100%' alignItems={"center"} justifyContent={"center"}>
        <Typography textAlign={"center"} fontSize={38} fontWeight={"bold"}>
          Application is out dated. Please contact support.
        </Typography>
      </Grid>
    );
  }
  const appProfile = appProfiles?.[currentApp?.id as any];
  const customLoginRoute = appRoutesMap.current[`${currentApp?.slug}/login`];
  const isCustomLoginRouteValid = customLoginRoute && customLoginRoute.appId === currentApp?.id;

  // TODO: Rauf fix this issue
  // if (checkAppAuth && isCustomLoginRouteValid) {
  //   return <Navigate to={`${currentApp?.slug}/login`} />;
  // }

  if (checkAppAuth)
    return (
      <>
        {!loading && !(isEditForm || _.isEmpty(appProfile) || _.isNil(appProfile) || isAddProfile) && (
          <UserProfiles
            appId={app?.id}
            appProfiles={appProfiles}
            onSaveClick={onSaveClick}
            onEditClick={(data = {} as any) => {
              setAuth(prev => ({ ...prev, ...data?.creds }));
              setIsEditForm(data);
            }}
            onAddClick={() => {
              setIsAddProfile(true);
            }}
            onDeleteClick={handleDeleteClick}
          />
        )}
        {(isEditForm || _.isEmpty(appProfile) || _.isNil(appProfile) || isAddProfile) && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 40,
            }}
          >
            <Logo application={app} />
            {loading ? (
              <h2 style={{ marginInlineStart: 15 }}>Signing in...</h2>
            ) : (
              <LoginForm
                initial={{ username, password, recaptcha, profileName: isEditForm?.profileName }}
                id={app.id}
                error={error || ""}
                disabled={disabled}
                onSave={onSaveClick}
                onCancel={_.noop}
                isEditForm={isEditForm}
                setIsEditForm={setIsEditForm}
                isAddProfile={isAddProfile}
                setIsAddProfile={setIsAddProfile}
                enableRecaptcha={enableRecaptcha}
                recaptchaSiteKey={recaptchaSiteKey}
              />
            )}
          </div>
        )}
      </>
    );

  return <>{children}</>;
};
