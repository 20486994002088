import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";

// routes
import { useDispatch } from "react-redux";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import store from "src/store/store";
import { decompressData } from "src/utils/services";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useAuth();
  const loadingRef = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loadingApps = useBuildxProviderValue("loadingApps");
  const allowedApps = useBuildxProviderValue("allowedApps");
  const appDescriptor = useBuildxProviderValue("appDescriptor");
  const currentApp = useBuildxProviderValue("currentApp");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const { appRoutesMap, unprotectedPages } = useBuildxContext();

  useEffect(() => {
    const _currentApp = allowedApps?.find((app?: BXApp) => {
      const appPath = fqdnApp ? "/" : `/${app?.slug}`;

      return app?.id === location.pathname.split("/")?.[3] || appPath === `/${location.pathname.split("/")[1]}`;
    });

    if (_currentApp) {
      dispatch(setProviderState({ currentApp: _currentApp }));
    }
  }, [allowedApps, location]);

  useEffect(() => {
    if (
      !loadingApps &&
      currentApp &&
      (window.location.pathname == "/" ||
        window.location.pathname == `${currentApp.slug}` ||
        window.location.pathname == `${currentApp.slug}/`) &&
      isLoggedIn &&
      !!currentApp?.templateConfig?.collections?.[0]?.pages?.length
    ) {
      const firstCollectionToHavePages = currentApp?.templateConfig?.collections?.find(
        (collection: BXAppCollection) => collection.pages.length > 0
      );
      const collectionSlug = firstCollectionToHavePages?.slug;
      const firstPageSlug = firstCollectionToHavePages?.pages?.filter(page => page?.info?.visibility !== "Hidden")?.[0]?.slug;
      const landingPageUri = currentApp?.appConfig?.pageUri;
      if (firstPageSlug) {
        const pathPrefix = fqdnApp ? "" : `${currentApp?.slug}`;
        navigate({
          pathname: `${pathPrefix}/${landingPageUri || `${collectionSlug}/${firstPageSlug}`}`.replaceAll(/\/+/g, "/"),
        });
      }
    }
  }, [location.pathname, currentApp, loadingApps]);

  if (allowedApps?.length && currentApp && currentApp?.templateConfig == null && !loadingApps && !loadingRef.current && isLoggedIn) {
    loadingRef.current = true;

    axiosServices
      .get("/application/" + currentApp?.id)
      .then(({ data }) => {
        if (data?.appConfig) {
          data.appConfig = decompressData(data?.appConfig);
        }
        if (data?.templateConfig) {
          data.templateConfig = decompressData(data?.templateConfig);
        }
        if (data?.upTemplateConfig) {
          data.upTemplateConfig = decompressData(data?.upTemplateConfig);
        }
        const currentApps = store.getState().buildxProvider.appDescriptor;
        const updatedApps = currentApps.map(app => (app.id == data?.id ? data : app));
        dispatch(setProviderState({ currentApp: data, appDescriptor: updatedApps }));
        const firstApp = data;
        const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
          (collection: BXAppCollection) => collection.pages.length > 0
        );
        if (window.location.pathname == data.slug) {
          const pathPrefix = fqdnApp ? "" : firstApp.slug;
          const collectionSlug = firstCollectionToHavePages?.slug;
          const firstPageSlug = firstCollectionToHavePages?.pages?.filter(page => page?.info?.visibility !== "Hidden")?.[0]?.slug;
          if (firstPageSlug) {
            navigate({ pathname: `${pathPrefix}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
          } else {
            navigate({ pathname: `${pathPrefix}`.replaceAll(/\/+/g, "/") });
          }
        }
      })
      .finally(() => {
        loadingRef.current = false;
      });
  }

  const _filteredLoginRoutes = useMemo(() => {
    const __app = currentApp ?? fqdnApp;
    let filteredLoginRoutes = LoginRoutes;
    if (__app && !__app?.appConfig?.isBuildXLoginEnabled) {
      filteredLoginRoutes = LoginRoutes.filter(r => r.path !== "login");
    }

    //TODO: Login Page URI should be configurable
    if (filteredLoginRoutes.some(r => r.path === "login")) {
      appRoutesMap.current["/login"] = {
        isRootCollection: true,
      };
    }
    return filteredLoginRoutes;
  }, [currentApp, fqdnApp, appRoutesMap, LoginRoutes]);

  const mainRoutesMemo = useMemo(
    () => [
      ...(fqdnApp
        ? !fqdnApp?.appConfig?.isBuildXLoginEnabled
          ? _filteredLoginRoutes.filter(r => r.path === "auth/google" || r.path === "auth/shopify")
          : _filteredLoginRoutes.filter(r => r.path !== "admin/login")
        : _filteredLoginRoutes),
      ...MainRoutes(
        allowedApps?.length ? allowedApps : appDescriptor,
        currentApp,
        isLoggedIn,
        loadingApps,
        appRoutesMap,
        unprotectedPages,
        fqdnApp
      ),
    ],
    [fqdnApp, allowedApps, currentApp, _filteredLoginRoutes, appRoutesMap, unprotectedPages]
  );

  return useRoutes(mainRoutesMemo);
}
