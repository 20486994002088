import { BXApp } from "src/types/BXAppType";
import { compareVersions } from "src/utils/generalUtils";
import { decompressData } from "src/utils/services";
import { migrateTo1_0_0 } from "./1.0.0";
import { migrateTo1_0_1 } from "./1.0.1";
import { migrateTo1_0_2 } from "./1.0.2";
import { migrateTo1_0_3 } from "./1.0.3";
import { migrateTo1_0_4 } from "./1.0.4";

const migrations: any = {
  "1.0.0": migrateTo1_0_0,
  "1.0.1": migrateTo1_0_1,
  "1.0.2": migrateTo1_0_2,
  "1.0.3": migrateTo1_0_3,
  "1.0.4": migrateTo1_0_4,
};

export const handleMigrateApp = (app: BXApp) => {
  const decompressedData = decompressData(app?.templateConfig);
  const appVersion = app?.appVersion || decompressedData?.appVersion;

  const migrationsKeys = Object.keys(migrations);
  const versionKeys = !appVersion ? migrationsKeys : migrationsKeys.filter(version => compareVersions(version, appVersion) > 0);

  let appAfterMigration = app;
  for (let i = 0; i < versionKeys.length; i++) {
    const version = versionKeys[i];
    const migrationFunction = migrations[version];
    appAfterMigration = migrationFunction(appAfterMigration);
  }
  return appAfterMigration;
};
