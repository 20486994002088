import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Card, CardContent, Grid, MenuItem } from "@mui/material";
import { FC, useRef, useState } from "react";
import { FieldErrors } from "react-hook-form";
import Sound1 from "src/assets/sounds/notification-sound-1.mp3";
import Sound2 from "src/assets/sounds/notification-sound-2.mp3";
import Sound3 from "src/assets/sounds/notification-sound-3.mp3";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import BXModal from "src/components/BXUI/Modal";
import { BXApp } from "src/types/BXAppType";
import { UIElement } from "src/types/UIElement";
import { ViewInfoTypes } from "../../../../../types/BXAppBuilder";
import { MapChainActions } from "../../FormBuilder/MapChainActions";
import { handleSlugChange } from "../../FormBuilder/utils";

interface ViewInfoProps {
  view?: UIElement;
  viewType?: string;
  control: any;
  errors: FieldErrors<ViewInfoTypes>;
  watch: any;
  setValue?: any;
  getValues?: any;
  app?: BXApp;
  views?: UIElement[];
  appId?: string;
  collectionId?: string;
  pageId?: string;
}

interface SoundItem {
  label: string;
  value: string;
  ref: React.RefObject<HTMLAudioElement>;
}

export const visibilityData = ["Visible", "Hidden"];
const paginationData = ["Infinite Scroll", "Pagination Bar"];

const ViewInfo: FC<ViewInfoProps> = ({
  viewType,
  control,
  errors,
  watch,
  setValue,
  getValues,
  view,
  app,
  views,
  appId,
  collectionId,
  pageId,
}) => {
  const [isRowActionModalOpen, setIsRowActionModalOpen] = useState<boolean>(false);

  const soundRef1 = useRef<HTMLAudioElement>(null);
  const soundRef2 = useRef<HTMLAudioElement>(null);
  const soundRef3 = useRef<HTMLAudioElement>(null);

  const getHeaderName = () => {
    switch (viewType) {
      case "data-table":
        return "Table";
      case "image-grid":
        return "Image";
      case "card-list":
        return "Grid";
      case "form":
        return "Form";
      case "upload":
        return "Upload";
      case "api":
        return "API";
      case "form-builder":
        return "View Builder";
      default:
        return "Table";
    }
  };
  const prevViewNameRef = useRef<string>(watch("info.name") || "");
  const getSlug = "info.viewName";
  const addSlash = false;
  const soundItems: SoundItem[] = [
    { label: "Sound 1", value: "sound1", ref: soundRef1 },
    { label: "Sound 2", value: "sound2", ref: soundRef2 },
    { label: "Sound 3", value: "sound3", ref: soundRef3 },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BXInput
              control={control}
              name='info.name'
              error={!!errors?.info?.name}
              fullWidth
              label='View Title'
              onChange={e => handleSlugChange(e.target.value, prevViewNameRef, getValues, setValue, getSlug, addSlash)}
            />
          </Grid>
          <Grid item xs={6}>
            <BXInput control={control} name='info.viewName' error={!!errors?.info?.viewName} fullWidth label='Reference Name' />
          </Grid>
          <Grid item xs={6}>
            <BXInput control={control} name='info.visibility' error={!!errors?.info?.visibility} select fullWidth label='Visibility'>
              {visibilityData?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </BXInput>
          </Grid>
          {viewType != "form" && viewType != "form-builder" && viewType != "upload" && viewType != "api" && (
            <Grid item xs={6}>
              <BXInput control={control} name='info.showApiMode' error={!!errors?.info?.showApiMode} select fullWidth label='Show API Mode'>
                {visibilityData?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
          )}
          {
            <Grid item xs={6}>
              <BXSwitch control={control} name={"info.isShared"} error={errors?.info?.isShared} label={"Shared View"} />
            </Grid>
          }

          {(viewType == "data-table" || viewType == "card-list" || viewType == "image-grid" || viewType == "repeated-view") && (
            <Grid item xs={6}>
              <BXInput
                control={control}
                name={"info.paginationMode"}
                error={!!errors?.info?.paginationMode}
                select
                fullWidth
                label={"Pagination Mode"}
              >
                {paginationData?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
          )}
          {(viewType == "data-table" || viewType == "repeated-view") && (
            <Grid item xs={6}>
              <BXInput
                control={control}
                name={"info.emptyStateView"}
                error={!!errors?.info?.emptyStateView}
                select
                fullWidth
                label={"Empty State View"}
              >
                {[
                  {
                    id: "None",
                    info: {
                      name: "None",
                    },
                  },
                  ...(views || []),
                ].map(view => (
                  <MenuItem key={view?.id} value={view?.id}>
                    {view?.info?.name}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
          )}
          {(viewType == "data-table" || viewType == "repeated-view") && (
            <Grid item xs={6}>
              <RHFIconPicker
                name={`info.loadingIcon.icon`}
                objName={`info.loadingIcon`}
                control={control}
                label={"Loading Icon"}
                showCaption={false}
                setValue={setValue}
              />
            </Grid>
          )}

          {viewType == "data-table" && (
            <>
              <Grid item xs={6}>
                <BXSwitch control={control} name={"info.enableRowHeight"} label={"Enable Row Height"} />
              </Grid>
              {watch("info.enableRowHeight") && (
                <Grid item xs={6}>
                  <BXInput
                    control={control}
                    name={"info.rowHeight"}
                    error={!!errors?.info?.rowHeight}
                    fullWidth
                    label={"Row Height"}
                    type='number'
                  />
                </Grid>
              )}
            </>
          )}

          {viewType != "api" && viewType != "form-builder" && (
            <Grid item xs={6}>
              <Box display='flex' alignItems='center'>
                <BXSwitch control={control} name={"info.enableMultipleSelection"} label={"Enable Multiple Select"} />
              </Box>
            </Grid>
          )}
          {viewType == "data-table" && (
            <Grid item xs={6}>
              <BXSwitch control={control} name={"info.disableRowSelect"} label={"Disable Row Select"} />
            </Grid>
          )}

          {viewType == "repeated-view" && (
            <Grid item xs={6}>
              <BXSwitch
                control={control}
                name={"info.isPercentageHeight"}
                error={errors?.info?.isPercentageHeight}
                label={"Fixed Height"}
              />
            </Grid>
          )}
          {viewType === "form-builder" && (
            <Grid item xs={6}>
              <BXSwitch
                control={control}
                name={"info.disableDirtyOption"}
                error={errors?.info?.disableDirtyOption}
                label={"Disable Dirty Form"}
              />
            </Grid>
          )}

          {viewType != "form-builder" && (
            <>
              <Grid item xs={6}>
                <BXSwitch control={control} name={"info.hideViewName"} error={errors?.info?.hideViewName} label={"Hide View Name"} />
              </Grid>
              <Grid item xs={6}>
                <BXSwitch
                  control={control}
                  name={"info.hidePaginationBar"}
                  error={errors?.info?.hidePaginationBar}
                  label={"Hide Pagination Bar"}
                />
              </Grid>
              <Grid item xs={6}>
                <BXSwitch
                  control={control}
                  name={"info.hideRefreshButton"}
                  error={errors?.info?.hideRefreshButton}
                  label={"Hide Refresh Button"}
                />
              </Grid>
            </>
          )}

          {viewType == "data-table" && (
            <Grid item xs={6}>
              <BXSwitch
                control={control}
                name={"info.disableTableHeader"}
                error={errors?.info?.disableTableHeader}
                label={"Disable Header"}
              />
            </Grid>
          )}
          {viewType == "data-table" && (
            <Grid item xs={6}>
              <BXSwitch control={control} name={"info.disableBorder"} error={errors?.info?.disableBorder} label={"Disable Border"} />
            </Grid>
          )}
          {viewType == "data-table" && (
            <Grid item xs={6}>
              <BXSwitch
                control={control}
                name={"info.enableActionOnRowClick"}
                error={errors?.info?.enableActionOnRowClick}
                label={"Enable Action On Row Click"}
              />
            </Grid>
          )}
          {viewType == "data-table" && watch("info.enableActionOnRowClick") && (
            <Grid container justifyContent='center' alignItems='center' py={1}>
              <Grid item px={1} style={{ width: "100%" }}>
                <Button variant='outlined' onClick={() => setIsRowActionModalOpen(true)}>
                  Configure Action
                </Button>
              </Grid>
              <BXModal
                open={isRowActionModalOpen}
                icon={<SettingsIcon />}
                maxWidth='lg'
                title='Row Action Configuration'
                onClose={() => setIsRowActionModalOpen(false)}
              >
                <MapChainActions
                  view={view}
                  views={views}
                  setModalOpen={setIsRowActionModalOpen}
                  onchangeLabel={false}
                  appId={appId}
                  collectionId={collectionId}
                  pageId={pageId}
                  handleConfigChange={(property, value) => {
                    setValue(`info.actionRowConfig.${property}`, value);
                  }}
                  onChangeProp={newMap => {
                    setValue("info.actionRowConfig.actionsMap", newMap);
                  }}
                  isTable
                  isRowLevel
                  prefixTableActionPath={watch(`info.actionRowConfig`)}
                />
              </BXModal>
            </Grid>
          )}
          {viewType == "card-list" && (
            <>
              <Grid item xs={12}>
                <BXSwitch control={control} name={"info.autoRefresh"} error={errors?.info?.autoRefresh} label={"Auto Refresh"} />
              </Grid>
              <Grid item xs={5} marginInlineStart={2}>
                <BXInput
                  control={control}
                  name={"info.timer"}
                  error={!!errors?.info?.timer}
                  label={`Timer in (sec)`}
                  type='number'
                  disabled={!watch("info.autoRefresh")}
                />
              </Grid>
              <Grid item xs={5} marginInlineStart={2}>
                <BXInput
                  control={control}
                  name={"info.notificationMessage"}
                  error={!!errors?.info?.notificationMessage}
                  label={`Notification Message`}
                  disabled={!watch("info.autoRefresh")}
                />
              </Grid>
              <Grid item xs={5} marginInlineStart={2}>
                <BXInput
                  control={control}
                  name={"info.notificationSound"}
                  error={!!errors?.info?.notificationSound}
                  select
                  label={"Notification Sound"}
                  disabled={!watch("info.autoRefresh")}
                >
                  {[
                    { label: "Sound 1", value: "sound1", ref: soundRef1 },
                    { label: "Sound 2", value: "sound2", ref: soundRef2 },
                    { label: "Sound 3", value: "sound3", ref: soundRef3 },
                  ]?.map(item => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      onClick={() => {
                        (item.ref.current as any).play();
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </BXInput>
              </Grid>
              <audio ref={soundRef1} src={Sound1} />
              <audio ref={soundRef2} src={Sound2} />
              <audio ref={soundRef3} src={Sound3} />
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewInfo;
