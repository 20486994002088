// endUserSlice.js
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  determineLevelAndTargetKey,
  getNextSegment,
  getStringAfterNextKey,
  getTargetState,
  isComponentRepeated,
  populateInitialState,
  populateVars,
  safeConcatPath,
  selectViewSlice,
  traverseOnChildren,
  traverseOnParents,
} from "../appState/utils";

//Validation Registry for fields
export let validationRegistry: Record<string, { [key: string]: (value: any) => true | string }> = {};

// Register a validation function for a specific field
export const registerValidation = (name: string, validate: { [key: string]: (value: any) => true | string }) => {
  if (_.get(validationRegistry, name)) {
    return;
  }
  _.set(validationRegistry, name, validate);
};

// Create an Entity Adapter
export const endUserAdapter = createEntityAdapter({
  selectId: (entity: any) => entity?.props?.key,
});

const createApplicationState = () => {
  return {
    ...endUserAdapter.getInitialState({}), // page-level entity adapter
    pages: {},
  };
};
const createPageState = () => {
  return {
    ...endUserAdapter.getInitialState({}), // page-level entity adapter
    views: {},
  };
};
const createViewState = () => endUserAdapter.getInitialState({ trigger: {} });

const initialState: any = {
  application: createApplicationState(),
};

const updateStateWithPayload = (state: any, payload: any) => {
  const { name, value, options } = payload;
  const { enableDirtyCheck = false, viewName, error, repeatedComponentIndex, setWithInitialValue = false } = options;
  let { pageId, componentKey, viewIsExist } = options;

  if (viewName && !pageId) {
    pageId = Object.keys(state.application.pages)[0];
  }

  const { level, targetKey } = determineLevelAndTargetKey(name, { pageId, viewName }, viewIsExist);

  let targetState = getTargetState(level, state, pageId, viewName, name, viewIsExist);

  const nextKey = getNextSegment(name, targetKey);
  const statePath = getStringAfterNextKey(name, nextKey);

  if (targetState?.entities?.[nextKey]) {
    const component = targetState.entities[nextKey];
    const initialValue = component.initialValue;
    const isDirty = !_.isEqual(value, initialValue);
    const oldError = !isComponentRepeated(component) ? component?.errors?.error : component?.errors?.error?.[repeatedComponentIndex];

    componentKey = componentKey || component.props.key;
    const updatePayload = {
      id: componentKey,
      changes: { ...component },
    };
    _.set(updatePayload.changes, statePath, value);

    if (setWithInitialValue) {
      _.set(updatePayload.changes, !isComponentRepeated(component) ? "initialValue" : `initialValue[${repeatedComponentIndex}]`, value);
    }

    endUserAdapter.updateOne(targetState, updatePayload);

    if (enableDirtyCheck || error !== undefined) {
      traverseOnParents(targetState, nextKey, (node, nodeKey) => {
        const dirtyStatePage = state.application.pages[pageId].dirty;
        const dirtyStateView = state.application.pages[pageId].views[viewName].dirty;

        if (enableDirtyCheck) {
          const currentDirty = node.dirty || { isDirty: false, fields: [] };
          let fields = [...currentDirty.fields];

          if (isDirty && !fields.includes(nextKey)) {
            fields.push(nextKey);

            //Set in page dirty
            if (!dirtyStatePage.fields.includes(nextKey)) {
              dirtyStatePage.fields.push(nextKey);
            }
            //Set in view dirty
            if (!dirtyStateView.fields.includes(nextKey)) {
              dirtyStateView.fields.push(nextKey);
            }
          } else if (!isDirty && fields.includes(nextKey)) {
            fields = fields.filter(field => field !== nextKey);

            if (!dirtyStatePage.fields.includes(nextKey)) {
              dirtyStatePage.fields = dirtyStatePage.fields.filter(field => field !== nextKey);
            }

            if (!dirtyStateView.fields.includes(nextKey)) {
              dirtyStateView.fields = dirtyStateView.fields.filter(field => field !== nextKey);
            }
          }

          node.dirty = { isDirty: fields.length > 0, fields };
          dirtyStatePage.isDirty = fields.length > 0;
          dirtyStateView.isDirty = fields.length > 0;
        }

        if (error !== undefined) {
          const updateErrors = (nodeKey: string) => {
            const isRepeated = isComponentRepeated(node);

            const errors = _.cloneDeep(node.errors) || {
              hasError: isRepeated ? Array.from({ length: node?.state?.length }, () => false) : false,
              list: isRepeated ? Array.from({ length: node?.state?.length }, () => []) : [],
              error: isRepeated ? Array.from({ length: node?.state?.length }, () => "") : "",
            };

            if (isRepeated) {
              if (error && !errors.list?.[repeatedComponentIndex]?.includes(error)) {
                errors.list?.[repeatedComponentIndex]?.push(error);
                if (errors.hasError[repeatedComponentIndex] === false) {
                  errors.hasError[repeatedComponentIndex] = true;
                }
              } else if (!error && oldError) {
                const index = errors.list?.[repeatedComponentIndex]?.indexOf(oldError);
                if (index !== -1) {
                  errors.list?.[repeatedComponentIndex]?.splice(index, 1);
                  errors.hasError[repeatedComponentIndex] = errors.list?.[repeatedComponentIndex]?.length > 0;
                }
              }
              if (!Array.isArray(errors.error)) {
                errors.error = [];
              }

              if (!errors.error[repeatedComponentIndex]) {
                errors.error[repeatedComponentIndex] = "";
              }

              errors.error[repeatedComponentIndex] = nodeKey === nextKey ? error : "";
            } else {
              if (error && !errors.list.includes(error)) {
                errors.list.push(error);
                errors.hasError = true;
              } else if (!error && oldError) {
                const index = errors.list.indexOf(oldError);
                if (index !== -1) {
                  errors.list.splice(index, 1);
                  errors.hasError = errors.list?.length > 0;
                }
              }
              if (!errors.error) {
                errors.error = "";
              }
              errors.error = nodeKey === nextKey ? error : "";
            }

            endUserAdapter.updateOne(targetState, {
              id: nodeKey,
              changes: {
                errors,
              },
            });
          };

          updateErrors(nodeKey);
        }
      });
    }
  } else {
    const pathToUpdate = safeConcatPath(nextKey, statePath);
    if (_.isEmpty(pathToUpdate)) {
      targetState = value;
    } else {
      _.set(targetState, pathToUpdate, value);
    }
  }
};

const endUserSlice = createSlice({
  name: "endUser",
  initialState,
  reducers: {
    setEndUser(state, action) {
      const { viewName, endUserElements, initialStateValues } = action.payload;
      let { pageId } = action.payload;

      if (viewName && !pageId) {
        //Layout case, give it current page Id.
        pageId = Object.keys(state.application.pages)[0];
      }

      const { vars, props } = initialStateValues || {};

      if (!state.application?.vars) {
        // state.application = createApplicationState();
        populateVars(state.application, vars);
      }

      if (!state.application?.props) {
        state.application.props = props;
      } else if (props) {
        state.application.props = _.merge({}, state.application.props, props);
      }

      if (!state.application.pages[pageId] && pageId) {
        state.application.pages[pageId] = createPageState();
        state.application.pages[pageId].vars = {};
        populateInitialState(state.application.pages[pageId], vars);
      }

      if (!state.application?.pages?.[pageId]?.views?.[viewName] && pageId && viewName) {
        state.application.pages[pageId].views[viewName] = createViewState();
        populateInitialState(state.application.pages[pageId].views[viewName], vars);
      }

      let viewState = pageId
        ? viewName
          ? state.application.pages[pageId].views[viewName]
          : state.application.pages[pageId]
        : state.application;

      // Set all elements on the viewState
      if (endUserElements && Array.isArray(endUserElements)) {
        endUserAdapter.setAll(viewState, endUserElements);
      }
    },

    updateAppState(state, action) {
      updateStateWithPayload(state, action.payload);
    },

    updateAppStateBulk(state, action) {
      action.payload?.forEach(payload => {
        updateStateWithPayload(state, payload);
      });
    },
    removeAppField(state, action) {
      const { name, pathVariables } = action?.payload;
      const { pageId, viewName } = pathVariables || {};

      const { level, targetKey } = determineLevelAndTargetKey(name, pathVariables);

      let targetState = getTargetState(level, state, pageId, viewName);
      const nextKey = getNextSegment(name, targetKey);

      if (targetState?.entities?.[nextKey]) {
        const component = targetState?.entities?.[nextKey];
        const id = component?.props?.key;

        endUserAdapter.removeOne(targetState, id);
      } else {
        //Remove directly, page or view or app itself.
        switch (level) {
          case "views":
            _.unset(state, `application.pages.${pageId}.views.${viewName}`);
            break;
          case "pages":
            _.unset(state, `application.pages.${pageId}`);
            break;
        }
      }
    },
    removeAppValue(state, action) {
      const { name, pageId, viewName, repeatedComponentIndex } = action?.payload;

      const { level, targetKey } = determineLevelAndTargetKey(name, { pageId, viewName });

      const targetState = getTargetState(level, state, pageId, viewName);
      const nextKey = getNextSegment(name, targetKey);
      const statePath = getStringAfterNextKey(name, nextKey);

      if (targetState?.entities?.[nextKey]) {
        const component = targetState?.entities?.[nextKey];

        const removePayload = {
          id: component.props.key,
          changes: {
            ...component,
          },
        };
        const arrayIndexMatch = statePath.match(/(.+)\[(\d+)]$/);

        if (arrayIndexMatch) {
          // Extract the base path and the array index
          const basePath = arrayIndexMatch[1];
          const index = parseInt(arrayIndexMatch[2], 10);

          const array = _.get(removePayload.changes, basePath);

          if (Array.isArray(array)) {
            if (basePath === "errors.error") {
              const error = array[index];
              const updateErrors = (nodeKey: string) => {
                const node = targetState.entities[nodeKey];

                const isRepeated = isComponentRepeated(node);

                const errors = _.cloneDeep(node.errors) || {
                  hasError: isRepeated ? Array.from({ length: node?.state?.length }, () => false) : false,
                  list: isRepeated ? Array.from({ length: node?.state?.length }, () => []) : [],
                  error: isRepeated ? Array.from({ length: node?.state?.length }, () => "") : "",
                };

                if (isRepeated) {
                  if (errors.list?.[repeatedComponentIndex]?.includes(error)) {
                    const index = errors.list?.[repeatedComponentIndex]?.indexOf(error);
                    if (index !== -1) {
                      errors.list?.[repeatedComponentIndex]?.splice(index, 1);
                      errors.hasError[repeatedComponentIndex] = errors.list?.[repeatedComponentIndex].length > 0;
                    }
                  }
                  if (!Array.isArray(errors.error)) {
                    errors.error = [];
                  }

                  if (!errors.error[repeatedComponentIndex]) {
                    errors.error[repeatedComponentIndex] = "";
                  }

                  errors.error[repeatedComponentIndex] = nodeKey === nextKey ? error : "";
                } else {
                  if (errors?.list?.includes(error)) {
                    const index = errors.list.indexOf(error);
                    if (index !== -1) {
                      errors.list.splice(index, 1);
                      errors.hasError = errors.list.length > 0;
                    }
                  }
                  if (!errors.error) {
                    errors.error = "";
                  }

                  errors.error = nodeKey === nextKey ? error : "";
                }

                endUserAdapter.updateOne(targetState, {
                  id: nodeKey,
                  changes: {
                    errors,
                  },
                });
              };

              traverseOnParents(targetState, nextKey, (node, nodeKey) => {
                updateErrors(nodeKey);
              });
            }
            //If array, remove the item from the array
            array.splice(index, 1);
            if (array.length === 0) {
              _.unset(removePayload.changes, basePath);
            }
          }
        } else {
          //If standard property, remove directly
          _.unset(removePayload.changes, statePath);
        }

        endUserAdapter.updateOne(targetState, removePayload);
      } else {
        //Remove directly
        const pathToRemove = safeConcatPath(nextKey, statePath);
        if (_.isEmpty(pathToRemove)) {
          return;
        }

        _.unset(targetState, pathToRemove);
      }
    },
    setAppError(state, action) {
      const { name, error, pageId, viewName, repeatedComponentIndex } = action.payload;
      const { level, targetKey } = determineLevelAndTargetKey(name, { pageId, viewName });
      const targetState = getTargetState(level, state, pageId, viewName);
      const nextKey = getNextSegment(name, targetKey);

      const component = targetState.entities[nextKey];
      const oldError = !isComponentRepeated(component) ? component?.errors?.error : component?.errors?.error?.[repeatedComponentIndex];

      if (targetState?.entities?.[nextKey]) {
        const updateErrors = (nodeKey: string) => {
          const node = targetState.entities[nodeKey];

          const isRepeated = isComponentRepeated(node);

          const errors = _.cloneDeep(node.errors) || {
            hasError: isRepeated ? Array.from({ length: node?.state?.length }, () => false) : false,
            list: isRepeated ? Array.from({ length: node?.state?.length }, () => []) : [],
            error: isRepeated ? Array.from({ length: node?.state?.length }, () => "") : "",
          };

          if (isRepeated) {
            if (error && !errors.list?.[repeatedComponentIndex]?.includes(error)) {
              errors.list?.[repeatedComponentIndex]?.push(error);
              if (errors.hasError[repeatedComponentIndex] === false) {
                errors.hasError[repeatedComponentIndex] = true;
              }
            } else if (!error && oldError) {
              const index = errors.list?.[repeatedComponentIndex]?.indexOf(oldError);
              if (index !== -1) {
                errors.list?.[repeatedComponentIndex]?.splice(index, 1);
                errors.hasError[repeatedComponentIndex] = errors.list?.[repeatedComponentIndex].length > 0;
              }
            }
            errors.error[repeatedComponentIndex] = nodeKey === nextKey ? error : "";
          } else {
            if (error && !errors.list.includes(error)) {
              errors.list.push(error);
              errors.hasError = true;
            } else if (!error && oldError) {
              const index = errors.list.indexOf(oldError);
              if (index !== -1) {
                errors.list.splice(index, 1);
                errors.hasError = errors.list.length > 0;
              }
            }
            errors.error = nodeKey === nextKey ? error : "";
          }

          endUserAdapter.updateOne(targetState, {
            id: nodeKey,
            changes: {
              errors,
            },
          });
        };

        traverseOnParents(targetState, nextKey, (node, nodeKey) => {
          updateErrors(nodeKey);
        });
      }
    },

    clearErrors(state, action) {
      const { name, pageId, viewName } = action.payload;
      const { level, targetKey } = determineLevelAndTargetKey(name, { pageId, viewName });
      const targetState = getTargetState(level, state, pageId, viewName);
      const nextKey = getNextSegment(name, targetKey);

      if (targetState?.entities?.[nextKey]) {
        traverseOnChildren(targetState, nextKey, (node, nodeKey) => {
          endUserAdapter.updateOne(targetState, {
            id: nodeKey,
            changes: {
              errors: { error: "", list: [], hasError: false },
            },
          });
        });
      }
    },

    cleanDirtyFields(state, action) {
      const { name, pageId, viewName, cleanDirtyLevel } = action.payload;
      const { level, targetKey } = determineLevelAndTargetKey(name, { pageId, viewName });
      const targetState = getTargetState(level, state, pageId, viewName);
      const nextKey = getNextSegment(name, targetKey);

      const cleanedDirtyState = { isDirty: false, fields: [] };

      const cleanDirtyViewComponents = (viewComponents, viewState) => {
        const viewComponentsKeys = Object.keys(viewComponents);

        for (const componentKey of viewComponentsKeys) {
          endUserAdapter.updateOne(viewState, {
            id: componentKey,
            changes: {
              initialValue: viewComponents[componentKey].state,
              dirty: cleanedDirtyState,
            },
          });
        }
      };

      switch (cleanDirtyLevel) {
        case "page":
          const page = state.application.pages[pageId];
          page.dirty = cleanedDirtyState;

          Object.keys(page.views).forEach(view => {
            const viewState = page.views[view];
            viewState.dirty = cleanedDirtyState;
            cleanDirtyViewComponents(viewState.entities, viewState);
          });
          break;

        case "view":
          const view = state.application.pages[pageId].views[viewName];
          view.dirty = cleanedDirtyState;
          cleanDirtyViewComponents(view.entities, view);
          break;

        case "component":
          if (targetState?.entities?.[nextKey]) {
            traverseOnChildren(targetState, nextKey, (node, nodeKey) => {
              endUserAdapter.updateOne(targetState, {
                id: nodeKey,
                changes: {
                  initialValue: node.state,
                  dirty: cleanedDirtyState,
                },
              });
            });
          }
          break;
      }
    },
    resetAppState(state, action) {
      //TODO: Implement resetAppState for entire app
      const { name, pageId, viewName, resetLevel } = action.payload;
      const { level, targetKey } = determineLevelAndTargetKey(name, { pageId, viewName });
      const viewState = selectViewSlice(state, pageId, viewName);
      const targetState = getTargetState(level, state, pageId, viewName);
      const nextKey = getNextSegment(name, targetKey);

      const resetComponent = componentKey => {
        endUserAdapter.updateOne(viewState, {
          id: componentKey,
          changes: {
            state: viewState[componentKey].initialValue,
            dirty: { isDirty: false, fields: [] },
            errors: { hasError: false, list: [], error: "" },
          },
        });
      };

      switch (resetLevel) {
        case "page":
          const page = state.application.pages[pageId];
          page.dirty = { isDirty: false, fields: [] };

          Object.keys(page.views).forEach(view => {
            const viewState = page.views[view];
            viewState.dirty = { isDirty: false, fields: [] };
            Object.keys(viewState.entities).forEach(componentKey => resetComponent(componentKey));
          });
          break;

        case "view":
          Object.keys(viewState.entities).forEach(componentKey => resetComponent(componentKey));
          break;

        case "component":
          if (targetState?.entities?.[nextKey]) {
            traverseOnChildren(targetState, nextKey, (node, nodeKey) => {
              endUserAdapter.updateOne(targetState, {
                id: nodeKey,
                changes: {
                  state: node.initialValue,
                  dirty: { isDirty: false, fields: [] },
                  errors: { hasError: false, list: [], error: "" },
                },
              });
            });
          }
          break;
      }
    },
  },
});

export const {
  setEndUser,
  updateAppState,
  updateAppStateBulk,
  removeAppField,
  removeAppValue,
  setAppError,
  clearErrors,
  cleanDirtyFields,
  resetAppState,
} = endUserSlice.actions;
export default endUserSlice.reducer;
