import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { splitUpperCase } from "hd-utils";
import { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BXModal from "src/components/BXUI/Modal";
import { updateComponent } from "src/features/builder/builderSlice";
import { RootState } from "src/store/store";
import { CreateChartForm } from "../../../ManageComponents/CreateChartForm";
import { CreateSelectDataSource } from "../../../ManageComponents/CreateSelectDataSource";
import { SuggestedValuesForm } from "../../../ManageComponents/SuggestedValuesForm";
import { MapChainActions } from "../../MapChainActions";
import StepperConfiguration from "../../StepperConfiguration";
import { ComponentItemType } from "../../types";
import {
  alignItemsValues,
  alignSelfValues,
  componentMediaTypes,
  defaultValues,
  flexChildIncludedProps,
  flexContainerIncludedProps,
  flexDirectionValues,
  gridChildIncludedProps,
  gridContainerIncludedProps,
  justifyContentValues,
  objectFitTypes,
  overflowValues,
  selectOptions,
  stripeIconOptions,
  stripeLabelsOptions,
  stripeThemesOptions,
  typographyAlign,
  typographyComponents,
  typographyFormats,
  uploaderTypes,
  visibilityTypes,
  wrapValues,
} from "../../utils";
import { AccordionState, ComponentEditorProps } from "./componentEditorTypes";
import {
  containersTypes,
  initialAccordionState,
  JsonThemes,
  pagesOrientationTypes,
  pageTypes,
  textFieldTypes,
  uploadTypes,
  variantTypes,
} from "./componentEditorUtils";
import { ConditionalRenderer } from "./EditorComponents/ConditionalRenderer";
import EditorRenderComponent from "./EditorComponents/EditorRenderComponent";
import { IntentConfigurationModal } from "./EditorComponents/IntentConfigurationModal";
import { InteractionConfig } from "./EditorComponents/InteractionConfig";
import ItemsPropertiesAccordion from "./EditorComponents/ItemsPropertiesAccordion";
import MapValueSelector from "./EditorComponents/MapValueSelector";
import useComponentConfig from "./useComponentConfig";

const ComponentEditor: FC<ComponentEditorProps> = ({
  componentId,
  componentType,
  layoutBreak,
  onDeleteItem,
  appId,
  collectionId,
  pageId,
  multiLingual,
  setMultiLingual,
  views,
}) => {
  const [styles, setStyles] = useState();
  const [stripeVariablesState, setStripeVariablesState] = useState();
  const [hoverStyles, setHoverStyles] = useState<any>();
  const [stripeRulesState, setStripeRulesState] = useState();
  const [isStripeModalOpen, setStripeModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataSourceModalOpen, setIsDataSourceModalOpen] = useState(false);
  const [isActionsConfigModalOpen, setIsActionsConfigModalOpen] = useState(false);
  const [isInteractionConfigModalOpen, setIsInteractionConfigModalOpen] = useState(false);
  const [isSuggestDataOpen, setIsSuggestDataOpen] = useState(false);
  const [isMarkdownModalOpen, setMarkdownModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [accordionState, setAccordionState] = useState(initialAccordionState);
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const view: any = useSelector((state: RootState) => state.builder.viewConfiguration);

  const {
    handleSave,
    handleSaveSelectComponent,
    handleSaveSuggestComponent,
    saveStyleHandler,
    saveStripeVariablesHandler,
    saveStripeRulesHandler,
    handleConfigChange,
    handleDateConfigChange,
    onInteractionConfigSave,
    onIntentConfigSave,
    handleChangeProp,
    handleChangeGridTemplateColumns,
    handleSetChangeProp,
    handleSetChangeIconProp,
    handleSetChangePropStyle,
    handleChangeSelect,
    handleChangeButtonProps,
    handleMapValuesToggle,
    handleToggleProp,
    handleAdvanceToggleChange,
    handleWidthTypeChange,
    handleHeightTypeChange,
    handleSaveRules,
    handleSaveStyles,
    handleChangeStyles,
    handleEditorChange,
    saveHoverStyles,
    handleHoverChangeStyle,
  } = useComponentConfig(
    componentId,
    setIsModalOpen,
    setIsDataSourceModalOpen,
    setIsSuggestDataOpen,
    styles,
    stripeRulesState,
    stripeVariablesState,
    setIsInteractionConfigModalOpen,
    setStripeModalOpen,
    setStyles,
    setStripeVariablesState,
    setStripeRulesState,
    hoverStyles,
    setHoverStyles
  );

  const handleAccordionChange = (accordionKey: keyof AccordionState) => {
    setAccordionState(prevState => ({
      ...prevState,
      [accordionKey]: !prevState[accordionKey],
    }));
  };

  useEffect(() => {
    const translations = multiLingual?.translations;
    if (selectedRow >= 0 && translations && selectedRow < translations.length) {
      const selectedTranslation = translations[selectedRow];
      const newLabel = `{i18n.${selectedTranslation.key}}`;
      dispatch(
        updateComponent({
          id: componentId,
          changes: {
            props: {
              label: newLabel,
              children: newLabel,
            },
          },
        })
      );
    }
  }, [selectedRow]);

  if (!componentId)
    return (
      <Typography display='flex' flex={1} justifyContent='center' alignItems='center' data-testId='test-drop' marginTop={2}>
        No Selected Item
      </Typography>
    );

  return (
    <Grid container alignItems='center'>
      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <EditorRenderComponent
            type='typography'
            typographyColor='primary'
            typographyFontWeight='bold'
            typographyStyle={{ padding: 1 }}
            propPath='config.placeholderConfig.title'
            componentId={componentId}
          />
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"} spacing={2}>
          <Typography sx={{ fontSize: "12px" }}>Map values</Typography>
          <EditorRenderComponent
            type='switch'
            propPath='props.isMapValues'
            componentId={componentId}
            onChangeLabel={"isMapValues"}
            onChangeSwitch={handleMapValuesToggle}
          />
        </Grid>
      </Grid>

      <ConditionalRenderer renderConditionPaths={[{ path: "props.isMapValues", expectedValue: true }]} componentId={componentId}>
        <MapValueSelector
          key={componentId}
          componentId={componentId}
          onChangeProp={updateComponent}
          handleChangeProp={handleChangeProp}
          handleConfigChange={handleConfigChange}
        />
      </ConditionalRenderer>

      <ItemsPropertiesAccordion expanded={accordionState.general} onChange={() => handleAccordionChange("general")} label='General'>
        <Grid container px={1} py={1} spacing={2}>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='autoCompleteSyntax'
              propPath='props.key'
              componentId={componentId}
              label={"key"}
              onChangeLabel={"key"}
              fullWidth
              handleChangeProp={handleChangeProp}
              view={view}
              views={views}
            />
          </Grid>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='autoCompleteSyntax'
              propPath='props.testId'
              componentId={componentId}
              label={"ID"}
              onChangeLabel={"testId"}
              fullWidth
              handleChangeProp={handleChangeProp}
              view={view}
              views={views}
            />
          </Grid>

          {componentType === ComponentItemType.BXIframe && (
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.src'
                  componentId={componentId}
                  label={"IFrame URL"}
                  onChangeLabel={"src"}
                  fullWidth
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.title'
                  componentId={componentId}
                  label={"Title"}
                  onChangeLabel={"title"}
                  fullWidth
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.sandbox'
                  componentId={componentId}
                  label={"Sandbox"}
                  onChangeLabel={"sandbox"}
                  placeholder='e.g. allow-scripts allow-same-origin'
                  fullWidth
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.referrerPolicy'
                  componentId={componentId}
                  label={"Referrer Policy"}
                  onChangeLabel={"referrerPolicy"}
                  select={true}
                  optionsSelect={[
                    { id: "no-referrer", title: "no-referrer" },
                    { id: "no-referrer-when-downgrade", title: "no-referrer-when-downgrade" },
                    { id: "origin", title: "origin" },
                    { id: "origin-when-cross-origin", title: "origin-when-cross-origin" },
                    { id: "same-origin", title: "same-origin" },
                    { id: "strict-origin", title: "strict-origin" },
                    { id: "strict-origin-when-cross-origin", title: "strict-origin-when-cross-origin" },
                    { id: "unsafe-url", title: "unsafe-url" },
                  ]}
                  fullWidth
                  handleChangeProp={handleChangeSelect}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Allow Full Screen</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.allowFullScreen'
                  componentId={componentId}
                  onChangeLabel={"allowFullScreen"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Frame Border</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.frameBorder'
                  componentId={componentId}
                  onChangeLabel={"frameBorder"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.allow'
                  componentId={componentId}
                  label={"Allow"}
                  onChangeLabel={"allow"}
                  placeholder='e.g. scripts navigation'
                  fullWidth
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
            </Grid>
          )}
          {componentType !== ComponentItemType.FlexContainer &&
            componentType !== ComponentItemType.GridContainer &&
            componentType !== ComponentItemType.JsonViewer &&
            componentType !== ComponentItemType.FileUploadInput && (
              <>
                {(componentType === ComponentItemType.DatePicker ||
                  componentType === ComponentItemType.TimePicker ||
                  componentType === ComponentItemType.DateTimePicker) && (
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography fontSize={12}>Now</Typography>
                    <EditorRenderComponent
                      type='switch'
                      componentId={componentId}
                      propPath='config.defaultValueNow'
                      onChangeLabel='defaultValueNow'
                      onChangeSwitch={handleConfigChange}
                      onChangeSecondaryLabel={"boolean"}
                      sx={{ ml: 2 }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.defaultValue'
                    componentId={componentId}
                    label={"Default Value"}
                    fullWidth
                    onChangeLabel={"defaultValue"}
                    disabledConditions={[{ disabledPath: "config.defaultValueNow", disabledCondition: true }]}
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
              </>
            )}

          {componentType === ComponentItemType.TextField && (
            <Grid item xs={12}>
              <EditorRenderComponent
                type='textField'
                propPath='props.type'
                componentId={componentId}
                label='Type'
                fullWidth
                select={true}
                onChangeLabel={"type"}
                optionsSelect={textFieldTypes}
                handleChangeProp={handleChangeSelect}
              />
            </Grid>
          )}

          {componentType !== ComponentItemType.StepperContainer && (
            <ConditionalRenderer
              renderConditionPaths={[
                {
                  path: "config.placeholderConfig.group",
                  expectedValue: "container",
                  operation: "OR",
                },
                {
                  path: "config.isStripeWrapper",
                  expectedValue: true,
                  operation: "OR",
                },
              ]}
              componentId={componentId}
            >
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.containerType'
                  componentId={componentId}
                  fallbackValuePath='type'
                  label='Type Container'
                  fullWidth
                  select={true}
                  onChangeLabel={"containerType"}
                  optionsSelect={containersTypes}
                  handleChangeProp={handleChangeSelect}
                />
              </Grid>
            </ConditionalRenderer>
          )}

          {componentType === ComponentItemType.StepperNavigator && (
            <Grid container item xs={12} px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autocomplete'
                  disableResponsive
                  isGroupReference
                  componentId={componentId}
                  propPath='config.navigator.groupReference'
                  label={"Group Reference"}
                  fullWidth
                  onChangeLabel={"navigator.groupReference"}
                  autocompleteGetOptionLabel={component => component?.props?.key || component?.key || ""}
                  handleChangeProp={propPath => value => {
                    const selectedStepper = { id: value?.id, key: value?.props?.key };
                    handleConfigChange(propPath)(selectedStepper as any);
                  }}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  componentId={componentId}
                  label='Type Container'
                  propPath='config.navigator.bulletDisplay'
                  fullWidth
                  select={true}
                  stepperNavigator={true}
                  optionsSelect={[
                    { id: "showSelectedOnly", title: "Show Selected Only" },
                    { id: "showUpToSelected", title: "Show Up to Selected" },
                  ]}
                  onChangeLabel={"navigator.bulletDisplay"}
                  handleChangeProp={handleConfigChange}
                />
              </Grid>

              <Grid item xs={12} gap={1}>
                <Typography sx={{ fontSize: "12px", mb: 1 }}>Unselected Bullet</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.unselectedBulletOpacity'
                      label={"Opacity"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.unselectedBulletOpacity"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      label={"Color"}
                      fullWidth
                      stepperNavigator={true}
                      propPath='config.navigator.unselectedBulletColor'
                      onChangeLabel={"navigator.unselectedBulletColor"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.unselectedBulletShape'
                      label={"Shape"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.unselectedBulletShape"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.unselectedBulletWidth'
                      label={"Width"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.unselectedBulletWidth"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      label={"Height"}
                      propPath='config.navigator.unselectedBulletHeight'
                      componentId={componentId}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.unselectedBulletHeight"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1}>
                <Typography sx={{ fontSize: "12px", mb: 1 }}>Selected Bullet</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.selectedBulletOpacity'
                      label={"Opacity"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.selectedBulletOpacity"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='config.navigator.selectedBulletColor'
                      componentId={componentId}
                      label={"Color"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.selectedBulletColor"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.selectedBulletShape'
                      label={"Shape"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.selectedBulletShape"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.selectedBulletWidth'
                      label={"Width"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.selectedBulletWidth"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      label={"Height"}
                      propPath='config.navigator.selectedBulletHeight'
                      componentId={componentId}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.selectedBulletHeight"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1}>
                <Typography sx={{ fontSize: "12px", mb: 1 }}>Bullet Hover</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.bulletHoverColor'
                      label={"Color"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.bulletHoverColor"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      componentId={componentId}
                      propPath='config.navigator.bulletHoverOpacity'
                      label={"Opacity"}
                      fullWidth
                      stepperNavigator={true}
                      onChangeLabel={"navigator.bulletHoverOpacity"}
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  componentId={componentId}
                  propPath='config.navigator.orientation'
                  label='Orientation'
                  fullWidth
                  select={true}
                  stepperNavigator={true}
                  optionsSelect={[
                    { id: "horizontal", title: "Horizontal" },
                    { id: "vertical", title: "Vertical" },
                  ]}
                  onChangeLabel={"navigator.orientation"}
                  handleChangeProp={handleConfigChange}
                />
              </Grid>

              <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Clickable Bullets</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='config.navigator.bulletsClickable'
                  onChangeLabel='navigator.bulletsClickable'
                  onChangeSecondaryLabel={"boolean"}
                  onChangeSwitch={handleConfigChange}
                />
              </Grid>
            </Grid>
          )}

          {(componentType == ComponentItemType.Button || componentType == ComponentItemType.CustomIconButton) && (
            <>
              <Grid container item px={1} py={1} spacing={2}>
                <Grid item xs={12} sx={{ width: "100%" }}>
                  <EditorRenderComponent
                    type='mapValuesComponent'
                    enableMapValuesPath={"props.isMapValues"}
                    propPath='props.toolTip'
                    componentId={componentId}
                    label={"Hint"}
                    handleChangeProp={handleChangeProp}
                    valueString={"toolTip"}
                    view={view}
                    views={views}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {componentType == ComponentItemType.CustomChip && (
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <EditorRenderComponent
                  type='mapValuesComponent'
                  propPath='props.labelChip'
                  componentId={componentId}
                  enableMapValuesPath={"props.isMapValues"}
                  valueString={"labelChip"}
                  label={"Label"}
                  handleChangeProp={handleChangeProp}
                  key={"labelChip"}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='mapValuesComponent'
                  propPath='props.labelColor'
                  componentId={componentId}
                  enableMapValuesPath={"props.isMapValues"}
                  valueString={"labelColor"}
                  label={"Label Color"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='mapValuesComponent'
                  propPath='props.borderColor'
                  componentId={componentId}
                  enableMapValuesPath={"props.isMapValues"}
                  valueString={"borderColor"}
                  label={"Border Color"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='mapValuesComponent'
                  propPath='props.backgroundColor'
                  componentId={componentId}
                  enableMapValuesPath={"props.isMapValues"}
                  valueString={"backgroundColor"}
                  label={"Background Color"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.variant'
                  componentId={componentId}
                  label='variant'
                  fullWidth
                  select={true}
                  onChangeLabel={"variant"}
                  optionsSelect={variantTypes}
                  handleChangeProp={handleChangeSelect}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.ColorPicker && (
            <Grid container item px={1} py={1} spacing={2}>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Read Only</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.isReadOnly'
                  componentId={componentId}
                  onChangeLabel={"isReadOnly"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Box Color is left</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.isLeft'
                  componentId={componentId}
                  onChangeLabel={"isLeft"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Disable Alpha Value</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.disableAlpha'
                  componentId={componentId}
                  onChangeLabel={"disableAlpha"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.borderColorIconPicker'
                  componentId={componentId}
                  label={"Border Color"}
                  onChangeLabel={"borderColorIconPicker"}
                  fullWidth
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.borderRadius'
                  componentId={componentId}
                  label={"Border Radius"}
                  onChangeLabel={"borderRadius"}
                  fullWidth
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='mapValuesComponent'
                  propPath='props.placeholder'
                  componentId={componentId}
                  valueString={"placeholder"}
                  enableMapValuesPath={"props.isMapValues"}
                  label={"Place Holder"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.CustomCheckbox && (
            <Grid container item xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='translationInput'
                  componentId={componentId}
                  propPath='props.label'
                  label='Label'
                  onChangeLabel='label'
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleChangeProp={handleChangeProp}
                  fullWidth
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='translationInput'
                  componentId={componentId}
                  propPath='props.checkboxValue'
                  label='Checkbox Input Value'
                  onChangeLabel='checkboxValue'
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleChangeProp={handleChangeProp}
                  fullWidth
                  view={view}
                  views={views}
                />
              </Grid>
              <ConditionalRenderer
                renderConditionPaths={[{ path: "props.isChildContainerGroup", expectedValue: false }]}
                componentId={componentId}
              >
                <Grid container px={1} py={2} spacing={2}>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='translationInput'
                      componentId={componentId}
                      propPath='props.groupName'
                      label='Checkbox Group Name'
                      onChangeLabel='groupName'
                      appId={appId}
                      multiLingual={multiLingual}
                      setMultiLingual={setMultiLingual}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                      handleChangeProp={handleChangeProp}
                      fullWidth
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid display='flex' marginInlineStart='5px' item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontSize: "12px" }}>Single Value</Typography>
                    <EditorRenderComponent
                      type='switch'
                      componentId={componentId}
                      propPath='props.singleValue'
                      onChangeLabel='singleValue'
                      label='Single Value'
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
              </ConditionalRenderer>
            </Grid>
          )}
          {(componentType === ComponentItemType.CustomSwitch || componentType === ComponentItemType.DatePicker) && (
            <Grid container item xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='translationInput'
                  componentId={componentId}
                  propPath='props.label'
                  label='Label'
                  onChangeLabel='label'
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleChangeProp={handleChangeProp}
                  fullWidth
                  view={view}
                  views={views}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.CustomAutoCompleteBX && (
            <Grid container item xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={12} mb={1}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.minWidthAutoComplete'
                  componentId={componentId}
                  label='Min Width'
                  fullWidth
                  onChangeLabel={"minWidthAutoComplete"}
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.maxWidthAutoComplete'
                  componentId={componentId}
                  label='Max Width'
                  fullWidth
                  onChangeLabel={"maxWidthAutoComplete"}
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Auto Select Value</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.autoSelectValue'
                  onChangeLabel='autoSelectValue'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Filter Selected Options</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.filterSelectedOptions'
                  onChangeLabel='filterSelectedOptions'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Read Only</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.readOnly'
                  onChangeLabel='readOnly'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Show Checkbox</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.showCheckbox'
                  onChangeLabel='showCheckbox'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <EditorRenderComponent
                  type='typography'
                  componentId={componentId}
                  typographyStyle={{ fontSize: "12px" }}
                  typographyStaticLabel={"Change Icon Checkbox"}
                  proPathStyle={[
                    {
                      path: "props.showCheckbox",
                      conditional: value => (value === false ? "50%" : "100%"),
                      fallbackValue: "100%",
                      property: "opacity",
                    },
                  ]}
                />
                <EditorRenderComponent
                  type='switch'
                  propPath='props.changeCheckbox'
                  componentId={componentId}
                  onChangeLabel={"changeCheckbox"}
                  onChangeSwitch={handleToggleProp}
                  disabledConditions={[{ disabledPath: "props.showCheckbox", disabledCondition: false }]}
                />
              </Grid>
              <ConditionalRenderer
                renderConditionPaths={[
                  { path: "props.changeCheckbox", expectedValue: true, operation: "AND" },
                  { path: "props.showCheckbox", expectedValue: true, operation: "AND" },
                ]}
                componentId={componentId}
              >
                <Grid item xs={12} mb={1}>
                  <EditorRenderComponent
                    type='iconPicker'
                    componentId={componentId}
                    bXIconPickerName={"props.iconConfigChecked.icon"}
                    label='Icon Checked'
                    propPath='props.iconConfigChecked.icon'
                    fallbackValuePath='props.iconConfigChecked.icon'
                    objName={"props.iconConfigChecked"}
                    previewIcon={true}
                    showCaption={false}
                    onChangeLabel='props.iconConfigChecked.icon'
                    handleChangeProp={handleSetChangeProp}
                    handleSetChangeIconProp={handleSetChangeIconProp}
                  />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.colorIconChecked'
                    componentId={componentId}
                    label='Color Icon Checked'
                    fullWidth
                    onChangeLabel={"colorIconChecked"}
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <EditorRenderComponent
                    type='iconPicker'
                    componentId={componentId}
                    propPath='props.iconConfigNotChecked.icon'
                    fallbackValuePath='props.iconConfigNotChecked.icon'
                    label='Icon Not Checked'
                    bXIconPickerName='props.iconConfigNotChecked.icon'
                    objName={"props.iconConfigNotChecked"}
                    previewIcon={true}
                    showCaption={false}
                    handleChangeProp={handleSetChangeProp}
                    handleSetChangeIconProp={handleSetChangeIconProp}
                    onChangeLabel='props.iconConfigNotChecked.icon'
                    disabledConditions={[{ disabledPath: "props.addIcon", disabledCondition: false }]}
                  />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.colorIconNotChecked'
                    componentId={componentId}
                    label='Color Icon Not Checked'
                    fullWidth
                    onChangeLabel={"colorIconNotChecked"}
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
              </ConditionalRenderer>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Free Solo</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.freeSolo'
                  onChangeLabel='freeSolo'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <EditorRenderComponent
                  type='typography'
                  componentId={componentId}
                  typographyStyle={{ fontSize: "12px" }}
                  typographyStaticLabel={"Show List With Free Solo"}
                  proPathStyle={[
                    {
                      path: "props.freeSolo",
                      conditional: value => (value === false ? "50%" : "100%"),
                      fallbackValue: "100%",
                      property: "opacity",
                    },
                  ]}
                />
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.listFreeSolo'
                  onChangeLabel='listFreeSolo'
                  onChangeSwitch={handleToggleProp}
                  disabledConditions={[{ disabledPath: "props.freeSolo", disabledCondition: false }]}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <EditorRenderComponent
                  type='typography'
                  componentId={componentId}
                  typographyStyle={{ fontSize: "12px" }}
                  typographyStaticLabel={"Show Add Word"}
                  proPathStyle={[
                    {
                      path: "props.freeSolo",
                      conditional: value => (value === false ? "50%" : "100%"),
                      fallbackValue: "100%",
                      property: "opacity",
                    },
                  ]}
                />
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.addWord'
                  onChangeLabel='addWord'
                  onChangeSwitch={handleToggleProp}
                  disabledConditions={[{ disabledPath: "props.freeSolo", disabledCondition: false }]}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <EditorRenderComponent
                  type='typography'
                  componentId={componentId}
                  typographyStyle={{ fontSize: "12px" }}
                  typographyStaticLabel={"Arrow Icon Is Left"}
                  proPathStyle={[
                    {
                      path: "props.freeSolo",
                      conditional: value => (value === true ? "50%" : "100%"),
                      fallbackValue: "100%",
                      property: "opacity",
                    },
                  ]}
                />
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.arrowIcon'
                  onChangeLabel='arrowIcon'
                  onChangeSwitch={handleToggleProp}
                  disabledConditions={[{ disabledPath: "props.freeSolo", disabledCondition: true }]}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Multiple</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.multiple'
                  onChangeLabel='multiple'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px", opacity: "100%" }}>Show More</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.showMore'
                  onChangeLabel='showMore'
                  onChangeSwitch={handleToggleProp}
                  disabledConditions={[{ disabledPath: "props.multiple", disabledCondition: false }]}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.numShowMore'
                  componentId={componentId}
                  label='Number Of Item Shown'
                  fullWidth
                  onChangeLabel={"numShowMore"}
                  handleChangeProp={handleChangeProp}
                  disabledConditions={[
                    { disabledPath: "props.showMore", disabledCondition: false },
                    { disabledPath: "props.multiple", disabledCondition: false },
                  ]}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Add Icon</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.addIcon'
                  onChangeLabel='addIcon'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px", opacity: "100%" }}>Icon is right</Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.iconRight'
                  onChangeLabel='iconRight'
                  onChangeSwitch={handleToggleProp}
                  disabledConditions={[{ disabledPath: "props.addIcon", disabledCondition: false }]}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <EditorRenderComponent
                  type='iconPicker'
                  componentId={componentId}
                  propPath='props.iconConfig.icon'
                  fallbackValuePath='props.iconConfig.icon'
                  label='Icon'
                  bXIconPickerName='props.iconConfig.icon'
                  objName={"props.iconConfig"}
                  previewIcon={true}
                  showCaption={false}
                  handleChangeProp={handleSetChangeProp}
                  handleSetChangeIconProp={handleSetChangeIconProp}
                  onChangeLabel='props.iconConfig.icon'
                  disabledConditions={[{ disabledPath: "props.addIcon", disabledCondition: false }]}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.placeholder'
                  componentId={componentId}
                  label='Placeholders'
                  fullWidth
                  onChangeLabel={"placeholder"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.dataAutoComplete'
                  componentId={componentId}
                  label='Data Entry'
                  fullWidth
                  onChangeLabel={"dataAutoComplete"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.keyLabelData'
                  componentId={componentId}
                  label='label'
                  fullWidth
                  onChangeLabel={"keyLabelData"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.keyValueData'
                  componentId={componentId}
                  label='Value'
                  fullWidth
                  onChangeLabel={"keyValueData"}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Suggestion List </Typography>
                <EditorRenderComponent
                  type='switch'
                  componentId={componentId}
                  propPath='props.suggestionList'
                  onChangeLabel='suggestionList'
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              <Grid container px={2} py={1} spacing={2}>
                <Grid item px={1} style={{ width: "100%" }}>
                  <EditorRenderComponent
                    type='button'
                    componentId={componentId}
                    label='Select Suggested List'
                    onClickButton={() => setIsSuggestDataOpen(true)}
                    buttonStyle={{ width: "100%" }}
                    disabledConditions={[{ disabledPath: "props.suggestionList", disabledCondition: false }]}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.CustomRadio && (
            <Grid container item xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='translationInput'
                  componentId={componentId}
                  propPath='props.label'
                  label={"label"}
                  onChangeLabel={"label"}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <ConditionalRenderer
                renderConditionPaths={[{ path: "props.isChildContainerGroup", expectedValue: false }]}
                componentId={componentId}
              >
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='translationInput'
                    label={"Radio Group Name"}
                    componentId={componentId}
                    propPath='props.groupName'
                    onChangeLabel={"groupName"}
                    handleChangeProp={handleChangeProp}
                    appId={appId}
                    multiLingual={multiLingual}
                    setMultiLingual={setMultiLingual}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    view={view}
                    views={views}
                  />
                </Grid>
              </ConditionalRenderer>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='translationInput'
                  componentId={componentId}
                  propPath='props.radioValue'
                  label={"Radio Input Value"}
                  onChangeLabel={"radioValue"}
                  handleChangeProp={handleChangeProp}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  view={view}
                  views={views}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.JsonViewer && (
            <Grid container item xs={12} gap={2} padding={1}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.JsonTheme'
                  componentId={componentId}
                  label='Type'
                  fullWidth
                  select={true}
                  onChangeLabel={"JsonTheme"}
                  optionsSelect={JsonThemes}
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Editable</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.isEditable'
                  componentId={componentId}
                  onChangeLabel={"isEditable"}
                  onChangeSwitch={handleMapValuesToggle}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "12px" }}>Default Value</Typography>
                <EditorRenderComponent
                  type='monacoEditor'
                  propPath='props.defaultValue'
                  componentId={componentId}
                  handleEditorChange={handleEditorChange}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.Button && (
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='translationInput'
                  componentId={componentId}
                  propPath='props.children'
                  label={"Text"}
                  onChangeLabel={"children"}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.sx.backgroundColor'
                  componentId={componentId}
                  label={"Background Color"}
                  onChangeLabel={"sx.backgroundColor"}
                  customLogicOnPropPath={value => value?.replace(/!important/g, "")}
                  fullWidth
                  handleChangeProp={handleChangeButtonProps}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.sx.borderRadius'
                  componentId={componentId}
                  label={"Border Radius"}
                  onChangeLabel={"sx.borderRadius"}
                  customLogicOnPropPath={value => value?.replace(/!important/g, "")}
                  fullWidth
                  handleChangeProp={handleChangeButtonProps}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.variant'
                  componentId={componentId}
                  label='Type'
                  fullWidth
                  select={true}
                  onChangeLabel={"variant"}
                  optionsSelect={variantTypes}
                  handleChangeProp={handleChangeSelect}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <EditorRenderComponent
                  type='typography'
                  typographyFontWeight='bold'
                  typographyStyle={{ fontSize: "12px" }}
                  componentId={componentId}
                  fallbackEvaluatedValue='Enable loading'
                  conditionalValuePaths={[
                    {
                      path: "props.enableLoading",
                      expectedValue: false,
                      returnValue: "Disabled loading",
                    },
                  ]}
                />
                <EditorRenderComponent
                  type='switch'
                  propPath='props.enableLoading'
                  componentId={componentId}
                  onChangeLabel={"enableLoading"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.MarkdownViewer && (
            <Grid container item justifyContent='center' alignItems='center' py={1}>
              <Grid item px={1} style={{ width: "100%" }}>
                <EditorRenderComponent
                  type='button'
                  componentId={componentId}
                  label='Markdown Configuration'
                  onClickButton={() => setMarkdownModalOpen(true)}
                  buttonStyle={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          )}

          {(componentType === ComponentItemType.DatePicker ||
            componentType === ComponentItemType.TimePicker ||
            componentType === ComponentItemType.DateTimePicker) && (
            <Grid container item spacing={2} px={2} py={1}>
              {componentType !== ComponentItemType.TimePicker && (
                <>
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography fontSize={12}>User local time</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='config.isUserLocalTime'
                      componentId={componentId}
                      onChangeLabel={"isUserLocalTime"}
                      onChangeSecondaryLabel={"boolean"}
                      onChangeSwitch={handleConfigChange}
                    />
                  </Grid>
                  <Grid container item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography fontSize={12}>24-Hour time format</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='config.ampm'
                      componentId={componentId}
                      onChangeLabel={"ampm"}
                      onChangeSecondaryLabel={"boolean"}
                      onChangeSwitch={handleConfigChange}
                    />
                  </Grid>
                  {componentType === ComponentItemType.DatePicker && (
                    <Grid item xs={12}>
                      <Typography fontSize={12}>Display Style</Typography>
                      <EditorRenderComponent
                        type='radioGroup'
                        propPath='config.displayStyle'
                        componentId={componentId}
                        onChangeLabel='displayStyle'
                        handleChangeProp={handleConfigChange}
                        optionsSelect={[
                          { value: "DatePicker", label: "Date Picker" },
                          { value: "DateCalendar", label: "Date Calendar" },
                        ]}
                      />
                    </Grid>
                  )}
                  <Grid container item xs={12}>
                    <Typography fontSize={12} fontWeight='bold'>
                      DateTime Range
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2}>
                      <Grid xs={12}>
                        <Typography fontSize={12}>Start range</Typography>
                      </Grid>
                      <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2} spacing={1}>
                        <Typography fontSize={12}>now</Typography>
                        <EditorRenderComponent
                          type='switch'
                          propPath='config.startsNow'
                          componentId={componentId}
                          onChangeLabel={"startsNow"}
                          onChangeSecondaryLabel={"boolean"}
                          onChangeSwitch={handleConfigChange}
                          sx={{ ml: 2 }}
                        />
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='dateTimePicker'
                            propPath='config.startDate'
                            componentId={componentId}
                            onChangeLabel={"startDate"}
                            handleChangeProp={handleDateConfigChange}
                            disabledConditions={[{ disabledPath: "config.startsNow", disabledCondition: true }]}
                            sx={{ mt: 1, width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='autoCompleteSyntax'
                            propPath='props.customErrorMessageStartDate'
                            componentId={componentId}
                            label={"Custom Error Message - startDate"}
                            onChangeLabel={"customErrorMessageStartDate"}
                            fullWidth
                            handleChangeProp={handleChangeProp}
                            view={view}
                            views={views}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2}>
                      <Grid xs={12}>
                        <Typography fontSize={12}>End range</Typography>
                      </Grid>
                      <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2} spacing={1}>
                        <Typography fontSize={12}>now</Typography>
                        <EditorRenderComponent
                          type='switch'
                          propPath='config.endsNow'
                          componentId={componentId}
                          onChangeLabel={"endsNow"}
                          onChangeSecondaryLabel={"boolean"}
                          onChangeSwitch={handleConfigChange}
                          sx={{ ml: 2 }}
                        />
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='dateTimePicker'
                            propPath='config.endDate'
                            componentId={componentId}
                            onChangeLabel={"endDate"}
                            handleChangeProp={handleDateConfigChange}
                            disabledConditions={[{ disabledPath: "config.endsNow", disabledCondition: true }]}
                            sx={{ mt: 1, width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='autoCompleteSyntax'
                            propPath='props.customErrorMessageEndDate'
                            componentId={componentId}
                            label={"Custom Error Message - EndDate"}
                            onChangeLabel={"customErrorMessageEndDate"}
                            fullWidth
                            handleChangeProp={handleChangeProp}
                            view={view}
                            views={views}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {componentType !== ComponentItemType.DatePicker && (
                <>
                  <Grid item>
                    <Grid container item xs={12}>
                      <Typography fontSize={12} fontWeight='bold'>
                        Time
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={1} pl={2}>
                      {["Hours", "Minutes", "Seconds"].map(label => (
                        <Grid key={label} container item xs={12} alignItems='center' rowSpacing={1}>
                          <Grid item xs={7}>
                            <Typography fontSize={12}>{label}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <EditorRenderComponent
                              type='switch'
                              propPath={`config.[${label.toLowerCase()}Enabled]`}
                              componentId={componentId}
                              onChangeLabel={`${label.toLowerCase()}Enabled`}
                              onChangeSecondaryLabel={"boolean"}
                              onChangeSwitch={handleConfigChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <EditorRenderComponent
                              type='autoCompleteSyntax'
                              propPath={`config.${label.toLowerCase()}Step`}
                              componentId={componentId}
                              label='Steps'
                              onChangeLabel={`${label.toLowerCase()}Step`}
                              onChangeSecondaryLabel='number'
                              fullWidth
                              handleChangeProp={handleConfigChange}
                              view={view}
                              views={views}
                              disabledConditions={[
                                {
                                  path: `config.${label.toLowerCase()}Enabled`,
                                  condition: false,
                                },
                              ]}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='autoCompleteSyntax'
                              propPath={`props.customErrorMessage${label}Step`}
                              componentId={componentId}
                              label={`Custom Error Message - ${label}Step`}
                              onChangeLabel={`customErrorMessage${label}Step`}
                              fullWidth
                              handleChangeProp={handleChangeProp}
                              view={view}
                              views={views}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {componentType === ComponentItemType.CustomQR && (
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.QRCodeValue'
                  componentId={componentId}
                  label={"value"}
                  onChangeLabel={"QRCodeValue"}
                  fullWidth
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.QRSize'
                  componentId={componentId}
                  label={"number"}
                  onChangeLabel={"QRSize"}
                  fullWidth
                  handleChangeProp={handleChangeProp}
                  view={view}
                  views={views}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.Spinner && (
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='props.spinnerSize'
                  componentId={componentId}
                  label='Size'
                  textFieldType='number'
                  fullWidth
                  onChangeLabel={"spinnerSize"}
                  handleChangeProp={handleChangeProp}
                />
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.Avatar && (
            <>
              <Grid container item px={1} py={1} spacing={1}>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='iconPicker'
                    componentId={componentId}
                    propPath='props.iconConfig.icon'
                    fallbackValuePath='default.iconPath'
                    label='Icon'
                    bXIconPickerName='props.iconConfig.icon'
                    objName={"props.iconConfig"}
                    previewIcon={false}
                    showCaption={false}
                    handleChangeProp={handleSetChangeIconProp}
                    onChangeLabel='props.iconConfig.icon'
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.alt'
                    componentId={componentId}
                    label={"Alt"}
                    placeholder='Alternative'
                    onChangeLabel={"alt"}
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.Typography && (
            <>
              <Grid container item px={1} py={1} spacing={2}>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='translationInput'
                    componentId={componentId}
                    propPath='props.children'
                    label={"Text"}
                    onChangeLabel={"children"}
                    appId={appId}
                    multiLingual={multiLingual}
                    setMultiLingual={setMultiLingual}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='mapValuesComponent'
                    propPath='props.fontSize'
                    componentId={componentId}
                    valueString='fontSize'
                    enableMapValuesPath={"props.isMapValues"}
                    label={"font Size"}
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.variant'
                    componentId={componentId}
                    label='Type'
                    fullWidth
                    select={true}
                    onChangeLabel={"variant"}
                    optionsSelect={typographyComponents}
                    handleChangeProp={handleChangeSelect}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.align'
                    componentId={componentId}
                    label='Align'
                    fullWidth
                    select={true}
                    onChangeLabel={"align"}
                    optionsSelect={typographyAlign}
                    handleChangeProp={handleChangeSelect}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.format'
                    componentId={componentId}
                    label='Type'
                    placeholder='Type'
                    fullWidth
                    select={true}
                    onChangeLabel={"format"}
                    optionsSelect={typographyFormats}
                    handleChangeProp={handleChangeSelect}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.linkColor'
                    componentId={componentId}
                    label={"Link Color"}
                    onChangeLabel={"linkColor"}
                    fullWidth
                    handleChangeProp={handleConfigChange}
                  />
                </Grid>
                <ConditionalRenderer renderConditionPaths={[{ path: "props.format", compareValue: "Date Time" }]} componentId={componentId}>
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography fontSize={12}>User local time</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='config.isUserLocalTime'
                      componentId={componentId}
                      onChangeLabel={"isUserLocalTime"}
                      onChangeSecondaryLabel={"boolean"}
                      onChangeSwitch={handleConfigChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.formatString'
                      componentId={componentId}
                      label={"Date Format"}
                      placeholder='Date Format'
                      onChangeLabel={"formatString"}
                      fullWidth
                      handleChangeProp={handleChangeSelect}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </ConditionalRenderer>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='mapValuesComponent'
                    propPath='props.color'
                    componentId={componentId}
                    enableMapValuesPath={"props.isMapValues"}
                    label={"Color"}
                    valueString={"color"}
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography fontSize={12}>Underline Link</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='config.underlineLink'
                      componentId={componentId}
                      onChangeLabel={"underlineLink"}
                      onChangeSecondaryLabel={"boolean"}
                      onChangeSwitch={handleConfigChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.CustomMediaCard && (
            <>
              <Grid container item px={1} py={1} spacing={1}>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='iconPicker'
                    componentId={componentId}
                    propPath='props.iconConfig.icon'
                    fallbackValuePath='props.iconConfig.icon'
                    label='Icon'
                    bXIconPickerName='props.iconConfig.icon'
                    objName={"props.iconConfig"}
                    previewIcon={false}
                    showCaption={false}
                    handleChangeProp={handleSetChangeProp}
                    handleSetChangeIconProp={handleSetChangeIconProp}
                    onChangeLabel='props.iconConfig.icon'
                    disabledConditions={[{ disabledPath: "props.addIcon", disabledCondition: false }]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.component'
                    componentId={componentId}
                    fullWidth
                    select={true}
                    onChangeLabel={"component"}
                    defaultValue={"img"}
                    optionsSelect={componentMediaTypes}
                    handleChangeProp={handleChangeSelect}
                  />
                </Grid>

                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.sx.objectFit'
                    componentId={componentId}
                    fullWidth
                    select={true}
                    onChangeLabel={"sx.objectFit"}
                    defaultValue={"cover"}
                    optionsSelect={objectFitTypes}
                    handleChangeProp={handleChangeSelect}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    display='flex'
                    marginInlineStart={"5px"}
                    item
                    xs={12}
                    justifyContent='space-between'
                    alignItems='center'
                    gap={"1px"}
                  >
                    <Typography sx={{ fontSize: "12px" }}>Disable Show Placeholder </Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.disablePlaceholder'
                      componentId={componentId}
                      onChangeLabel={"disablePlaceholder"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    display='flex'
                    marginInlineStart={"5px"}
                    item
                    xs={12}
                    justifyContent='space-between'
                    alignItems='center'
                    gap={"1px"}
                  >
                    <Typography sx={{ fontSize: "12px" }}>Disable Cache </Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.disabledCache'
                      componentId={componentId}
                      onChangeLabel={"disabledCache"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    display='flex'
                    marginInlineStart={"5px"}
                    item
                    xs={12}
                    justifyContent='space-between'
                    alignItems='center'
                    gap={"1px"}
                  >
                    <Typography sx={{ fontSize: "12px" }}>Pointer Cursor </Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.cursorPointer'
                      componentId={componentId}
                      onChangeLabel={"cursorPointer"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <ConditionalRenderer
                  renderConditionPaths={[
                    { path: "props.component", expectedValue: true, compareValue: "video", operation: "OR" },
                    { path: "props.component", expectedValue: true, compareValue: "audio", operation: "OR" },
                  ]}
                  componentId={componentId}
                >
                  <Grid item xs={12}>
                    <Grid
                      display='flex'
                      marginInlineStart={"5px"}
                      item
                      xs={12}
                      justifyContent='space-between'
                      alignItems='center'
                      gap={"1px"}
                    >
                      <Typography sx={{ fontSize: "12px" }}>Controls</Typography>
                      <EditorRenderComponent
                        type='switch'
                        propPath={`props.controls`}
                        componentId={componentId}
                        onChangeLabel={`controls`}
                        onChangeSwitch={handleToggleProp}
                      />
                    </Grid>
                  </Grid>
                </ConditionalRenderer>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.CustomIconButton && (
            <>
              <Grid container item px={1} py={1} spacing={1}>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='iconPicker'
                    componentId={componentId}
                    propPath='props.iconConfig.icon'
                    fallbackValuePath='props.iconConfig.icon'
                    label='Icon'
                    bXIconPickerName='props.iconConfig.icon'
                    objName={"props.iconConfig"}
                    previewIcon={false}
                    showCaption={false}
                    handleChangeProp={handleSetChangeProp}
                    handleSetChangeIconProp={handleSetChangeIconProp}
                    onChangeLabel='props.iconConfig.icon'
                    disabledConditions={[{ disabledPath: "props.addIcon", disabledCondition: false }]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='actionConfig.label'
                    componentId={componentId}
                    label='Label'
                    placeholder='Label'
                    onChangeLabel={"actionConfig.label"}
                    handleChangeProp={handleSetChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.style.borderRadius'
                    componentId={componentId}
                    label={"Border radius"}
                    placeholder='Border radius'
                    onChangeLabel={"props.style.borderRadius"}
                    handleChangeProp={handleSetChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='mapValuesComponent'
                    propPath='props.iconSize'
                    componentId={componentId}
                    enableMapValuesPath={"props.isMapValues"}
                    valueString='iconSize'
                    label={"Icon Size"}
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.borderColor'
                    componentId={componentId}
                    label='Border Color'
                    placeholder='Border Color'
                    onChangeLabel={"props.borderColor"}
                    handleChangeProp={handleSetChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.borderWidth'
                    componentId={componentId}
                    label='Border Width'
                    placeholder='Border Width'
                    onChangeLabel={"props.borderWidth"}
                    handleChangeProp={handleSetChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.iconColor'
                    componentId={componentId}
                    label='Icon Color'
                    placeholder='Icon Color'
                    onChangeLabel={"props.iconColor"}
                    fullWidth
                    handleChangeProp={handleSetChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.backgroundColor'
                    componentId={componentId}
                    label='Background Color'
                    placeholder='Background Color'
                    onChangeLabel={"backgroundColor"}
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"start"} alignItems={"center"} spacing={2}>
                  <EditorRenderComponent
                    type='typography'
                    typographyStyle={{ fontSize: "12px" }}
                    propPath='config.placeholderConfig.title'
                    componentId={componentId}
                    fallbackEvaluatedValue='Enable loading'
                    conditionalValuePaths={[
                      {
                        path: "props.enableLoading",
                        expectedValue: false,
                        returnValue: "Disabled loading",
                      },
                    ]}
                  />
                  <EditorRenderComponent
                    type='switch'
                    propPath='props.enableLoading'
                    componentId={componentId}
                    onChangeLabel={"enableLoading"}
                    defaultValue={true}
                    onChangeSwitch={handleToggleProp}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.PaginationBar && (
            <>
              <Grid container item px={1} py={1} spacing={1}>
                <Grid container px={1} py={1} spacing={1}>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='config.dataPagination'
                      componentId={componentId}
                      label={"Data"}
                      placeholder='Data'
                      onChangeLabel={"dataPagination"}
                      fullWidth
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.maxPrev'
                      componentId={componentId}
                      label={"Max Prev"}
                      placeholder='Max Prev'
                      onChangeLabel={"maxPrev"}
                      textFieldType={"number"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} px={1} py={1}>
                  <Typography sx={{ fontSize: "12px", mb: 1 }}>Unselected Page</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.unselectedPageBackgroundColor'
                        componentId={componentId}
                        label={"BG Color"}
                        placeholder='BG Color'
                        onChangeLabel={"unselectedPageBackgroundColor"}
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.unselectedPageColor'
                        componentId={componentId}
                        label={"Color"}
                        onChangeLabel={"unselectedPageColor"}
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.unselectedPageColorHover'
                        componentId={componentId}
                        label={"Hover Color"}
                        onChangeLabel={"unselectedPageColorHover"}
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} px={1} py={1}>
                  <Typography sx={{ fontSize: "12px", mb: 1 }}>Selected Page</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.selectedPageBackgroundColor'
                        componentId={componentId}
                        label={"BG Color"}
                        onChangeLabel={"selectedPageBackgroundColor"}
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.selectedPageColor'
                        componentId={componentId}
                        label={"BG Color"}
                        onChangeLabel={"selectedPageColor"}
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.selectedPageColorHover'
                        componentId={componentId}
                        label={"Hover Color"}
                        onChangeLabel={"selectedPageColorHover"}
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} px={1} py={1}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.pageType'
                    componentId={componentId}
                    label='Page Type'
                    fullWidth
                    select={true}
                    onChangeLabel={"pageType"}
                    optionsSelect={pageTypes}
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
                <Grid item xs={12} px={1} py={1}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.pagesOrientation'
                    componentId={componentId}
                    label='Orientation'
                    placeholder='Orientation'
                    fullWidth
                    select={true}
                    onChangeLabel={"pagesOrientation"}
                    optionsSelect={pagesOrientationTypes}
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.FileUploadInput && (
            <ItemsPropertiesAccordion expanded={accordionState.upload} onChange={() => handleAccordionChange("upload")} label='Upload'>
              {componentType === ComponentItemType.FileUploadInput && (
                <Grid container px={1} py={1} spacing={1}>
                  <Grid item xs={12}>
                    <FormControl size='small' fullWidth>
                      <Typography variant='subtitle1' gutterBottom>
                        Allowed File Types
                      </Typography>
                      <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"} mb={2}>
                        <Typography sx={{ fontSize: "12px" }}>Custom Type</Typography>
                        <EditorRenderComponent
                          type='switch'
                          propPath='config.uploadConfig.customEnabled'
                          componentId={componentId}
                          onChangeLabel={"uploadConfig.customEnabled"}
                          onChangeSecondaryLabel={"boolean"}
                          onChangeSwitch={handleConfigChange}
                          defaultValue={true}
                        />
                      </Grid>
                      <ConditionalRenderer
                        renderConditionPaths={[{ path: "config.uploadConfig.customEnabled", expectedValue: false }]}
                        componentId={componentId}
                      >
                        <EditorRenderComponent
                          type='multiSelect'
                          componentId={componentId}
                          propPath='config.uploadConfig.uploadAllowedTypes'
                          optionsSelect={uploadTypes}
                          label='Allowed Upload Types'
                          onChangeLabel={"uploadConfig.uploadAllowedTypes"}
                          handleChangeProp={handleConfigChange}
                        />
                      </ConditionalRenderer>
                      <ConditionalRenderer
                        renderConditionPaths={[{ path: "config.uploadConfig.customEnabled", expectedValue: true }]}
                        componentId={componentId}
                      >
                        <EditorRenderComponent
                          type='textField'
                          propPath='config.repeated.customFileExtensions'
                          componentId={componentId}
                          label={"File Extensions"}
                          placeholder='e.g., .jpg,.png,.pdf'
                          onChangeLabel={"repeated.customFileExtensions"}
                          fullWidth
                          handleChangeProp={handleConfigChange}
                        />
                      </ConditionalRenderer>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginInlineStart={1}>
                    <EditorRenderComponent
                      type='radioGroup'
                      componentId={componentId}
                      propPath='config.uploadConfig.uploadUrlType'
                      optionsSelect={[
                        { value: "aws", label: "AWS" },
                        { value: "custom", label: "Custom" },
                      ]}
                      onChangeLabel='uploadConfig.uploadUrlType'
                      handleChangeProp={handleConfigChange}
                    />
                  </Grid>

                  <ConditionalRenderer
                    renderConditionPaths={[
                      { path: "config.uploadConfig.uploadUrlType", expectedValue: true, compareValue: "aws", operation: "OR" },
                      { path: "config.uploadConfig.uploadUrlType", expectedValue: false, operation: "OR" },
                    ]}
                    componentId={componentId}
                  >
                    <>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='config.uploadConfig.signedUrl'
                          componentId={componentId}
                          label='Signed Url'
                          onChangeLabel={"uploadConfig.signedUrl"}
                          fullWidth
                          handleChangeProp={handleConfigChange}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='config.uploadConfig.finalizedUrl'
                          componentId={componentId}
                          label='Finalized Url'
                          onChangeLabel={"uploadConfig.finalizedUrl"}
                          fullWidth
                          handleChangeProp={handleConfigChange}
                          view={view}
                          views={views}
                        />
                      </Grid>
                    </>
                  </ConditionalRenderer>

                  <ConditionalRenderer
                    renderConditionPaths={[{ path: "config.uploadConfig.uploadUrlType", expectedValue: true, inverseCompareValue: "aws" }]}
                    componentId={componentId}
                  >
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='config.uploadConfig.finalizedUrl'
                        componentId={componentId}
                        label='Custom Url'
                        onChangeLabel={"uploadConfig.customUrl"}
                        fullWidth
                        handleChangeProp={handleConfigChange}
                        view={view}
                        views={views}
                      />
                    </Grid>
                  </ConditionalRenderer>

                  <Grid container marginTop={1}>
                    <Grid item xs={12} paddingInlineStart={2}>
                      <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                        <Typography sx={{ fontSize: "12px" }}>Multiple files</Typography>
                        <EditorRenderComponent
                          type='switch'
                          propPath='config.uploadConfig.multipleFiles'
                          componentId={componentId}
                          onChangeLabel={"uploadConfig.multipleFiles"}
                          onChangeSecondaryLabel={"boolean"}
                          onChangeSwitch={handleConfigChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} paddingInlineStart={2}>
                      <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                        <Typography sx={{ fontSize: "12px" }}>Crop image</Typography>
                        <EditorRenderComponent
                          type='switch'
                          propPath={`config.uploadConfig.isCropImage`}
                          componentId={componentId}
                          onChangeLabel={`uploadConfig.isCropImage`}
                          onChangeSecondaryLabel={"boolean"}
                          onChangeSwitch={handleConfigChange}
                          disabledConditions={[{ disabledPath: "config.uploadConfig.uploadAllowedTypes", disabledCondition: true }]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='config.uploadConfig.fileNumber'
                      componentId={componentId}
                      textFieldType='number'
                      label={"Number of files"}
                      onChangeLabel={"uploadConfig.fileNumber"}
                      fullWidth
                      handleChangeProp={handleConfigChange}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              )}
            </ItemsPropertiesAccordion>
          )}

          {(componentType === ComponentItemType.StripePaymentElements ||
            componentType === ComponentItemType.StripeCardNumberElement ||
            componentType === ComponentItemType.StripeAddressElement ||
            componentType === ComponentItemType.StripeCardCvcElement ||
            componentType === ComponentItemType.StripeCardExpiryElement) && (
            <Grid container item spacing={2} p={1}>
              <Grid item xs={12}>
                {componentType === ComponentItemType.StripePaymentElements && (
                  <>
                    <Box>
                      <EditorRenderComponent
                        type='autocomplete'
                        propPath='props.stripeTheme'
                        componentId={componentId}
                        label='Stripe Theme'
                        placeholder={"Stripe Theme"}
                        fullWidth
                        autocompleteOptions={stripeThemesOptions}
                        handleChangeProp={handleChangeProp}
                      />
                    </Box>
                    <Box py={2}>
                      <EditorRenderComponent
                        type='autocomplete'
                        propPath='props.stripeLabels'
                        componentId={componentId}
                        label='Stripe Labels'
                        placeholder={"Stripe Labels"}
                        fullWidth
                        autocompleteOptions={stripeLabelsOptions}
                        handleChangeProp={handleChangeProp}
                      />
                    </Box>
                  </>
                )}
                {componentType === ComponentItemType.StripeCardNumberElement && (
                  <>
                    <Box>
                      <EditorRenderComponent
                        type='autocomplete'
                        propPath='props.stripeIconStyle'
                        componentId={componentId}
                        label='Stripe Icon'
                        placeholder={"Stripe Icon"}
                        fullWidth
                        autocompleteOptions={stripeIconOptions}
                        handleChangeProp={handleChangeProp}
                      />
                    </Box>
                    <Box py={2}>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Typography sx={{ fontSize: "12px" }}>Show Icon</Typography>
                        <EditorRenderComponent
                          type='switch'
                          propPath='props.stripeShowIcon'
                          componentId={componentId}
                          onChangeLabel={"stripeShowIcon"}
                          onChangeSwitch={handleToggleProp}
                        />
                      </Grid>
                    </Box>
                  </>
                )}
                {(componentType === ComponentItemType.StripeCardNumberElement ||
                  componentType === ComponentItemType.StripeCardCvcElement ||
                  componentType === ComponentItemType.StripeCardExpiryElement) && (
                  <>
                    <Grid item xs={12}>
                      <EditorRenderComponent
                        type='textField'
                        propPath='props.stripePlaceholder'
                        componentId={componentId}
                        label='Placeholder'
                        placeholder='Placeholder'
                        fullWidth
                        handleChangeProp={handleChangeProp}
                        onChangeLabel={`stripePlaceholder`}
                      />
                    </Grid>
                    <Box px={1} py={1}>
                      <Box display={"flex"} alignItems='center' justifyContent='space-between' mb={2}>
                        <Typography sx={{ fontSize: "16px" }}>Variables</Typography>
                        <EditorRenderComponent
                          type='button'
                          componentId={componentId}
                          label='Save'
                          buttonVariant='contained'
                          onClickButton={saveStripeVariablesHandler}
                          stateProps={{
                            propPath: "props.stripeVariables",
                            state: stripeVariablesState,
                            setState: setStripeVariablesState,
                          }}
                        />
                      </Box>
                      <Box mb={2}>
                        <EditorRenderComponent
                          type='autoCompleteCSSComponent'
                          propPath='props.stripeVariables'
                          componentId={componentId}
                          handleChangeProp={handleSaveStyles}
                        />
                      </Box>
                    </Box>
                  </>
                )}
                {componentType === ComponentItemType.StripePaymentElements && (
                  <Box px={1} py={1}>
                    <Box display={"flex"} alignItems='center' justifyContent='space-between' mb={2}>
                      <Typography sx={{ fontSize: "16px" }}>Rules</Typography>
                      <EditorRenderComponent
                        type='button'
                        componentId={componentId}
                        label='Save'
                        buttonVariant='contained'
                        onClickButton={saveStripeRulesHandler}
                        propPath='props.stripeRules'
                        stateProps={{ propPath: "props.stripeRules", state: stripeRulesState, setState: setStripeRulesState }}
                      />
                    </Box>
                    <Box mb={2}>
                      <EditorRenderComponent
                        type='autoCompleteCSSComponent'
                        propPath='props.stripeRules'
                        componentId={componentId}
                        handleChangeProp={handleSaveRules}
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}

          {componentType === ComponentItemType.GoogleMapAutocomplete && (
            <>
              <Grid container item spacing={2} p={1}>
                <Grid container spacing={2} p={1}>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.googlePlacesApiKey'
                      componentId={componentId}
                      label={"Google Places Api Key"}
                      onChangeLabel={"googlePlacesApiKey"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.CustomGoogleMap && (
            <>
              <Grid container item spacing={2} p={1}>
                <Grid item xs={12} paddingInlineStart={2} pb={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>keep Pin Centered</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.keepPinCentered'
                      componentId={componentId}
                      onChangeLabel={"keepPinCentered"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} paddingInlineStart={2} pb={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>Map Type</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.enableMapTypeControl'
                      componentId={componentId}
                      onChangeLabel={"enableMapTypeControl"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} paddingInlineStart={2} pb={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>Full Screen</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.enableFullscreenControl'
                      componentId={componentId}
                      onChangeLabel={"enableFullscreenControl"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} paddingInlineStart={2} pb={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>Zoom</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.enableZoomControl'
                      componentId={componentId}
                      onChangeLabel={"enableZoomControl"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} paddingInlineStart={2} pb={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>Street View</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.enableStreetViewControl'
                      componentId={componentId}
                      onChangeLabel={"enableStreetViewControl"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                </Grid>
                <Grid
                  display='flex'
                  marginInlineStart={"5px"}
                  item
                  xs={12}
                  gap={1}
                  justifyContent='space-between'
                  alignItems='center'
                  pb={2}
                >
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.latitudeDefaultValue'
                      componentId={componentId}
                      label={"Longitude Default Value"}
                      onChangeLabel={"latitudeDefaultValue"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.longitudeDefaultValue'
                      componentId={componentId}
                      label={"Latitude Default Value"}
                      onChangeLabel={"longitudeDefaultValue"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} gap={1} justifyContent='space-between' alignItems='center'>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.longitudeInputValue'
                      componentId={componentId}
                      label={"Longitude Value"}
                      onChangeLabel={"longitudeInputValue"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.latitudeInputValue'
                      componentId={componentId}
                      label={"Latitude Value"}
                      onChangeLabel={"latitudeInputValue"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} p={1}>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.googleMapApiKey'
                      componentId={componentId}
                      label={"Google Map Api Key"}
                      onChangeLabel={"googleMapApiKey"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} p={1}>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.referenceLatAndLngValues'
                      componentId={componentId}
                      label={"Google Places Reference"}
                      onChangeLabel={"referenceLatAndLngValues"}
                      fullWidth
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {(componentType === ComponentItemType.SplineChart ||
            componentType === ComponentItemType.ColumnChart ||
            componentType === ComponentItemType.CircularChart ||
            componentType === ComponentItemType.PieChart) && (
            <>
              <Grid container item justifyContent='center' alignItems='center' py={1}>
                <Grid item px={1} style={{ width: "100%" }}>
                  <Button variant='outlined' onClick={() => setIsModalOpen(true)} style={{ width: "100%" }}>
                    Chart Configuration
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          {componentType === ComponentItemType.BXSelect && (
            <>
              <Grid container item px={1} py={1} spacing={2}>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.dataEntry'
                    componentId={componentId}
                    label={"Data Entry"}
                    onChangeLabel={"dataEntry"}
                    fullWidth
                    handleChangeProp={handleConfigChange}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.keyLabel'
                    componentId={componentId}
                    label={"label"}
                    onChangeLabel={"keyLabel"}
                    fullWidth
                    handleChangeProp={handleConfigChange}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.keyValue'
                    componentId={componentId}
                    label={"Value"}
                    onChangeLabel={"keyValue"}
                    fullWidth
                    handleChangeProp={handleConfigChange}
                    view={view}
                    views={views}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {(componentType === ComponentItemType.Typography || componentType === ComponentItemType.FlexContainer) && (
            <Grid container item spacing={2}>
              {componentType === ComponentItemType.Typography && (
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.sx.fontSize'
                    componentId={componentId}
                    fallbackValuePath='props.sx.fontSize'
                    label={"Font Size"}
                    layoutBreak={layoutBreak}
                    placeholder='Font Size'
                    onChangeLabel={`props.sx.fontSize`}
                    getClosestPropValue={true}
                    fullWidth
                    handleChangeProp={handleSetChangeProp}
                  />
                </Grid>
              )}
              {componentType === ComponentItemType.FlexContainer && (
                <>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='textField'
                      propPath='props.sx.padding'
                      componentId={componentId}
                      fallbackValuePath='props.sx.padding'
                      label={"Padding"}
                      placeholder='Padding'
                      layoutBreak={layoutBreak}
                      onChangeLabel={`props.sx.padding.${layoutBreak}`}
                      getClosestPropValue={true}
                      fullWidth
                      handleChangeProp={handleSetChangeProp}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='textField'
                      propPath='props.sx.flexDirection'
                      componentId={componentId}
                      fallbackValuePath='props.sx.flexDirection'
                      label={"Flex Direction"}
                      placeholder='Flex Direction'
                      layoutBreak={layoutBreak}
                      onChangeLabel={`props.sx.flexDirection.${layoutBreak}`}
                      getClosestPropValue={true}
                      fullWidth
                      handleChangeProp={handleSetChangeProp}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='textField'
                      propPath='props.sx.gap'
                      componentId={componentId}
                      fallbackValuePath='props.sx.gap'
                      label={"Gap"}
                      placeholder='Gap'
                      layoutBreak={layoutBreak}
                      onChangeLabel={`props.sx.gap.${layoutBreak}`}
                      getClosestPropValue={true}
                      fullWidth
                      handleChangeProp={handleSetChangeProp}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {(componentType === ComponentItemType.Button || componentType === ComponentItemType.TextField) && (
            <ConditionalRenderer
              renderConditionPaths={[{ path: "props.type", expectedValue: true, inverseCompareValue: "Password" }]}
              componentId={componentId}
            >
              <Grid container item px={1} py={1} spacing={2}>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='iconPicker'
                    componentId={componentId}
                    propPath='props.iconConfig.icon'
                    label='Icon'
                    fallbackValuePath={"props.iconConfig.icon"}
                    bXIconPickerName='props.iconConfig.icon'
                    onChangeLabel='props.iconConfig.icon'
                    objName={"props.iconConfig"}
                    handleChangeProp={handleSetChangeProp}
                    handleSetChangeIconProp={handleSetChangeIconProp}
                    previewIcon={false}
                    showCaption={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.componentIconSpacing'
                    componentId={componentId}
                    label={"Icon Spacing"}
                    onChangeLabel={"componentIconSpacing"}
                    fullWidth
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.iconColor'
                    componentId={componentId}
                    label={"Icon Color"}
                    placeholder='Color'
                    onChangeLabel={"iconColor"}
                    fullWidth
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.iconSize'
                    componentId={componentId}
                    label={"Icon Size"}
                    placeholder='Color'
                    onChangeLabel={"iconSize"}
                    fullWidth
                    handleChangeProp={handleChangeProp}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Icon is at left</Typography>
                  <EditorRenderComponent
                    type='switch'
                    propPath='props.isLeft'
                    componentId={componentId}
                    onChangeLabel={"isLeft"}
                    onChangeSwitch={handleToggleProp}
                  />
                </Grid>
              </Grid>
            </ConditionalRenderer>
          )}

          <ConditionalRenderer
            renderConditionPaths={[{ path: "config.acceptTypes", includes: "CustomCheckbox" }]}
            componentId={componentId}
          >
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='config.dataEntry'
                  label={"Data Entry"}
                  componentId={componentId}
                  onChangeLabel={"dataEntry"}
                  fullWidth
                  handleChangeProp={handleConfigChange}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='config.keyLabel'
                  label={"label"}
                  componentId={componentId}
                  onChangeLabel={"keyLabel"}
                  fullWidth
                  handleChangeProp={handleConfigChange}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='config.keyValue'
                  componentId={componentId}
                  label={"Value"}
                  onChangeLabel={"keyValue"}
                  fullWidth
                  handleChangeProp={handleConfigChange}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Single Value</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.singleValue'
                  componentId={componentId}
                  onChangeLabel={"singleValue"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
            </Grid>
          </ConditionalRenderer>

          <ConditionalRenderer renderConditionPaths={[{ path: "config.isStripeWrapper", expectedValue: true }]} componentId={componentId}>
            <>
              <Grid container item justifyContent='center' alignItems='center' py={1} pb={2}>
                <Grid item px={1} style={{ width: "100%" }}>
                  <EditorRenderComponent
                    type='button'
                    componentId={componentId}
                    label='Intent Configuration'
                    onClickButton={() => setStripeModalOpen(true)}
                    buttonStyle={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </>
          </ConditionalRenderer>

          <ConditionalRenderer renderConditionPaths={[{ path: "config.acceptTypes", includes: "CustomRadio" }]} componentId={componentId}>
            <Grid container item px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.dataEntry'
                  componentId={componentId}
                  label={"Data Entry"}
                  onChangeLabel={"dataEntry"}
                  fullWidth
                  handleChangeProp={handleConfigChange}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.keyLabel'
                  componentId={componentId}
                  label={"label"}
                  onChangeLabel={"keyLabel"}
                  fullWidth
                  handleChangeProp={handleConfigChange}
                  view={view}
                  views={views}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='autoCompleteSyntax'
                  propPath='props.keyValue'
                  componentId={componentId}
                  label={"Value"}
                  onChangeLabel={"keyValue"}
                  fullWidth
                  handleChangeProp={handleConfigChange}
                  view={view}
                  views={views}
                />
              </Grid>
            </Grid>
          </ConditionalRenderer>

          <ConditionalRenderer
            renderConditionPaths={[{ path: "config.isStepperContainer", expectedValue: true }]}
            componentId={componentId}
          >
            <Grid container item xs={12} px={1} py={1} spacing={2}>
              <StepperConfiguration componentId={componentId} deleteComponent={onDeleteItem} />
            </Grid>
          </ConditionalRenderer>

          <ConditionalRenderer renderConditionPaths={[{ path: "config.isGroup", expectedValue: true }]} componentId={componentId}>
            <Grid item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='props.defaultValue'
                componentId={componentId}
                label={"Default Value"}
                fullWidth
                onChangeLabel={"defaultValue"}
                handleChangeProp={handleChangeProp}
                view={view}
                views={views}
              />
            </Grid>
          </ConditionalRenderer>
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion
        expanded={accordionState.dataSource}
        onChange={() => handleAccordionChange("dataSource")}
        label='Data Source'
      >
        <Grid container px={1} py={1} spacing={2}>
          <Grid item px={1} style={{ width: "100%" }}>
            <EditorRenderComponent
              type='button'
              componentId={componentId}
              label='Select Data Source'
              onClickButton={() => setIsDataSourceModalOpen(true)}
              buttonStyle={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion expanded={accordionState.size} onChange={() => handleAccordionChange("size")} label='Size'>
        <Grid container px={1} py={1} spacing={2}>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='textField'
              propPath='props.widthType'
              componentId={componentId}
              label='Width Type'
              fullWidth
              handleChangeProp={handleWidthTypeChange}
              eventNeeded={true}
              select={true}
              optionsSelect={[
                { id: "fixed", title: "Fixed" },
                { id: "dynamic", title: "Dynamic" },
                { id: "percentage", title: "Percentage" },
              ]}
              fallbackEvaluatedValue='percentage'
              conditionalValuePaths={[
                { path: `config.fixedWidth`, expectedValue: true, returnValue: "fixed" },
                { path: `config.isDynamicWidth`, expectedValue: true, returnValue: "dynamic" },
              ]}
            />
          </Grid>
          <ConditionalRenderer
            renderConditionPaths={[
              { path: `config.fixedWidth`, expectedValue: true, operation: "AND" },
              { path: `config.isDynamicWidth`, expectedValue: false, operation: "AND" },
            ]}
            componentId={componentId}
          >
            <Grid item xs={12}>
              <EditorRenderComponent
                type='textField'
                propPath='config.widthPx'
                componentId={componentId}
                label={"Width Px"}
                fullWidth
                getClosestPropValue={true}
                handleChangeProp={handleConfigChange}
                onChangeLabel={`widthPx`}
                onChangeSecondaryLabel={"number"}
              />
            </Grid>
          </ConditionalRenderer>
          <ConditionalRenderer
            renderConditionPaths={[
              { path: `config.fixedWidth`, expectedValue: false, operation: "AND" },
              { path: `config.isDynamicWidth`, expectedValue: false, operation: "AND" },
            ]}
            componentId={componentId}
          >
            <Grid item xs={12}>
              <EditorRenderComponent
                type='textField'
                propPath='config.widthPercentage'
                componentId={componentId}
                label={"Width %"}
                fullWidth
                // getClosestPropValue={true}
                handleChangeProp={handleConfigChange}
                onChangeLabel={`widthPercentage`}
              />
            </Grid>
          </ConditionalRenderer>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='textField'
              propPath='props.heightType'
              componentId={componentId}
              label='Height Type'
              fullWidth
              handleChangeProp={handleHeightTypeChange}
              eventNeeded={true}
              select={true}
              optionsSelect={[
                { id: "fixed", title: "Fixed" },
                { id: "dynamic", title: "Dynamic" },
                { id: "percentage", title: "Percentage" },
              ]}
              fallbackEvaluatedValue='percentage'
              conditionalValuePaths={[
                { path: `config.isPercentageHeight`, expectedValue: false, returnValue: "fixed" },
                { path: `config.isDynamicHeight`, expectedValue: true, returnValue: "dynamic" },
              ]}
            />
          </Grid>
          <ConditionalRenderer
            renderConditionPaths={[
              { path: `config.isPercentageHeight`, expectedValue: false, operation: "AND" },
              { path: `config.isDynamicHeight`, expectedValue: false, operation: "AND" },
            ]}
            componentId={componentId}
          >
            <Grid item xs={12}>
              <EditorRenderComponent
                type='textField'
                propPath='config.heightPx'
                componentId={componentId}
                label={"Height Px"}
                fullWidth
                // getClosestPropValue={true}
                handleChangeProp={handleConfigChange}
                onChangeLabel={`heightPx`}
                onChangeSecondaryLabel={"number"}
              />
            </Grid>
          </ConditionalRenderer>
          <ConditionalRenderer
            renderConditionPaths={[
              { path: `config.isPercentageHeight`, expectedValue: true, operation: "AND" },
              { path: `config.isDynamicHeight`, expectedValue: false, operation: "AND" },
            ]}
            componentId={componentId}
          >
            <Grid item xs={12}>
              <EditorRenderComponent
                type='textField'
                propPath='config.heightPercentage'
                componentId={componentId}
                label={"Height %"}
                fullWidth
                // getClosestPropValue={true}
                handleChangeProp={handleConfigChange}
                onChangeLabel={`heightPercentage`}
              />
            </Grid>
          </ConditionalRenderer>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='autocomplete'
              propPath='config.overflow'
              componentId={componentId}
              label='Overflow'
              placeholder={"Overflow"}
              fullWidth
              autocompleteOptions={overflowValues}
              handleChangeProp={handleConfigChange}
            />
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion
        expanded={accordionState.actionConfig}
        onChange={() => handleAccordionChange("actionConfig")}
        label='Action'
      >
        <Grid container justifyContent='center' alignItems='center' py={1}>
          <Grid item px={1} style={{ width: "100%" }}>
            <Button variant='outlined' onClick={() => setIsActionsConfigModalOpen(true)} style={{ width: "100%" }}>
              Actions Configuration
            </Button>
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>

      {(componentType === ComponentItemType.TextField ||
        componentType === ComponentItemType.FileUploadInput ||
        componentType === ComponentItemType.DatePicker ||
        componentType === ComponentItemType.CustomSwitch ||
        componentType === ComponentItemType.CustomCheckbox ||
        componentType === ComponentItemType.CustomRadio ||
        componentType === ComponentItemType.DatePicker ||
        componentType === ComponentItemType.ColorPicker ||
        componentType === ComponentItemType.JsonViewer ||
        componentType === ComponentItemType.CustomAutoCompleteBX ||
        componentType === ComponentItemType.DateTimePicker) && (
        <>
          <ItemsPropertiesAccordion
            expanded={accordionState.validation}
            onChange={() => handleAccordionChange("validation")}
            label='Validation'
          >
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid container alignItems='center' justifyContent='space-between' style={{ marginLeft: 5 }}>
                    <Typography style={{ fontSize: 12 }}>Show Error</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='props.showError'
                      componentId={componentId}
                      onChangeLabel={"showError"}
                      onChangeSwitch={handleToggleProp}
                    />
                  </Grid>
                  <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                    <Grid item xs={12}>
                      <EditorRenderComponent
                        type='customErrorMessage'
                        componentId={componentId}
                        propPath={"props.required"}
                        label='Required'
                        validationTypeString={"required"}
                        onChangeLabel={"customRequiredMessageContent"}
                        customMessageContentPath={"props.customRequiredMessageContent"}
                        handleToggleProp={handleToggleProp}
                        handleChangeProp={handleChangeProp}
                      />
                    </Grid>
                  </Grid>

                  {componentType !== ComponentItemType.CustomAutoCompleteBX && (
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Grid item xs={12}>
                        <EditorRenderComponent
                          type='customErrorMessage'
                          componentId={componentId}
                          propPath={"props.matchValidation"}
                          label='Match'
                          validationTypeString={"matchValidation"}
                          onChangeLabel={"customMatchMessageContent"}
                          customMessageContentPath={"props.customMatchMessageContent"}
                          handleToggleProp={handleToggleProp}
                          handleChangeProp={handleChangeProp}
                        />
                        <ConditionalRenderer
                          renderConditionPaths={[{ path: "props.matchValidation", expectedValue: true }]}
                          componentId={componentId}
                        >
                          <Grid item xs={12} marginTop={2}>
                            <EditorRenderComponent
                              type='mapValuesComponent'
                              propPath='props.matchValue'
                              componentId={componentId}
                              label={"Match Value"}
                              enableMapValuesPath={"props.isMapValues"}
                              valueString={"matchValue"}
                              handleChangeProp={handleChangeProp}
                              view={view}
                              views={views}
                            />
                          </Grid>
                        </ConditionalRenderer>
                      </Grid>
                    </Grid>
                  )}

                  {componentType == ComponentItemType.TextField && (
                    <>
                      <ConditionalRenderer
                        renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "Text" }]}
                        componentId={componentId}
                      >
                        <Grid
                          container
                          display='flex'
                          marginInlineStart={"5px"}
                          item
                          xs={12}
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='customErrorMessage'
                              componentId={componentId}
                              propPath={"props.isRegex"}
                              label='Regex'
                              validationTypeString={"isRegex"}
                              onChangeLabel={"customRegexMessageContent"}
                              customMessageContentPath={"props.customRegexMessageContent"}
                              handleToggleProp={handleToggleProp}
                              handleChangeProp={handleChangeProp}
                            />
                          </Grid>
                          <ConditionalRenderer
                            renderConditionPaths={[{ path: "props.isRegex", expectedValue: true }]}
                            componentId={componentId}
                          >
                            <Grid item xs={12}>
                              <EditorRenderComponent
                                type='mapValuesComponent'
                                propPath='props.textFieldRegex'
                                componentId={componentId}
                                valueString={"textFieldRegex"}
                                enableMapValuesPath={"props.isMapValues"}
                                label={"Regex"}
                                handleChangeProp={handleChangeProp}
                                view={view}
                                views={views}
                              />
                            </Grid>
                          </ConditionalRenderer>
                        </Grid>
                        <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='customErrorMessage'
                              componentId={componentId}
                              propPath={"props.stringValidation"}
                              label='Text'
                              validationTypeString={"stringValidation"}
                              onChangeLabel={"customStringMessageContent"}
                              customMessageContentPath={"props.customStringMessageContent"}
                              handleToggleProp={handleToggleProp}
                              handleChangeProp={handleChangeProp}
                            />
                          </Grid>
                        </Grid>
                        <ConditionalRenderer
                          renderConditionPaths={[{ path: "props.stringValidation", expectedValue: true }]}
                          componentId={componentId}
                        >
                          <Grid item xs={6}>
                            <EditorRenderComponent
                              type='autoCompleteSyntax'
                              propPath='props.minLength'
                              textFieldType='number'
                              componentId={componentId}
                              label={"Min Length"}
                              onChangeLabel={"minLength"}
                              handleChangeProp={handleChangeProp}
                              view={view}
                              views={views}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <EditorRenderComponent
                              type='autoCompleteSyntax'
                              propPath='props.maxLength'
                              textFieldType='number'
                              componentId={componentId}
                              label={"Max Length"}
                              onChangeLabel={"maxLength"}
                              handleChangeProp={handleChangeProp}
                              view={view}
                              views={views}
                            />
                          </Grid>
                        </ConditionalRenderer>
                      </ConditionalRenderer>
                    </>
                  )}

                  {componentType == ComponentItemType.TextField && (
                    <>
                      <ConditionalRenderer
                        renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "Number" }]}
                        componentId={componentId}
                      >
                        <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='customErrorMessage'
                              componentId={componentId}
                              propPath={"props.numberValidation"}
                              label='Number'
                              validationTypeString={"numberValidation"}
                              onChangeLabel={"customNumberMessageContent"}
                              customMessageContentPath={"props.customNumberMessageContent"}
                              handleToggleProp={handleToggleProp}
                              handleChangeProp={handleChangeProp}
                            />
                          </Grid>
                        </Grid>

                        <ConditionalRenderer
                          renderConditionPaths={[{ path: "props.numberValidation", expectedValue: true }]}
                          componentId={componentId}
                        >
                          <Grid item xs={6}>
                            <EditorRenderComponent
                              type='autoCompleteSyntax'
                              propPath='props.minValue'
                              componentId={componentId}
                              label={"Min Value"}
                              onChangeLabel={"minValue"}
                              handleChangeProp={handleChangeProp}
                              view={view}
                              views={views}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <EditorRenderComponent
                              type='autoCompleteSyntax'
                              propPath='props.maxValue'
                              componentId={componentId}
                              label={"Max Value"}
                              onChangeLabel={"maxValue"}
                              handleChangeProp={handleChangeProp}
                              view={view}
                              views={views}
                            />
                          </Grid>
                        </ConditionalRenderer>
                      </ConditionalRenderer>
                    </>
                  )}

                  {componentType == ComponentItemType.ColorPicker && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='customErrorMessage'
                            componentId={componentId}
                            propPath={"props.colorPickerInvalid"}
                            label='Color Picker'
                            validationTypeString={"colorPickerInvalid"}
                            defaultValue={true}
                            onChangeLabel={"customColorMessageContent"}
                            customMessageContentPath={"props.customColorMessageContent"}
                            handleToggleProp={handleToggleProp}
                            handleChangeProp={handleChangeProp}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {componentType === ComponentItemType.TextField && (
                    <ConditionalRenderer
                      renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "Email" }]}
                      componentId={componentId}
                    >
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='customErrorMessage'
                            componentId={componentId}
                            propPath={"props.emailValidation"}
                            label='Email'
                            validationTypeString={"emailValidation"}
                            defaultValue={true}
                            onChangeLabel={"customEmailMessageContent"}
                            customMessageContentPath={"props.customEmailMessageContent"}
                            handleToggleProp={handleToggleProp}
                            handleChangeProp={handleChangeProp}
                          />
                        </Grid>
                      </Grid>
                    </ConditionalRenderer>
                  )}

                  {componentType === ComponentItemType.FileUploadInput && (
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Grid item xs={12}>
                        <EditorRenderComponent
                          type='customErrorMessage'
                          componentId={componentId}
                          propPath={"props.fileSizeLimit"}
                          label={"File Size Limit"}
                          textFieldType={"number"}
                          validationTypeString={"fileSizeLimit"}
                          onChangeLabel={"customFileSizeLimitMessageContent"}
                          customMessageContentPath={"props.customFileSizeLimitMessageContent"}
                          handleToggleProp={handleToggleProp}
                          handleChangeProp={handleChangeProp}
                        />
                        <ConditionalRenderer
                          renderConditionPaths={[{ path: "props.fileSizeLimit", expectedValue: true }]}
                          componentId={componentId}
                        >
                          <Grid item xs={12} marginTop={2}>
                            <EditorRenderComponent
                              type='textField'
                              propPath='props.fileSizeLimitValue'
                              componentId={componentId}
                              label={"File Size Limit in Bytes"}
                              fullWidth
                              onChangeLabel={"fileSizeLimitValue"}
                              handleChangeProp={handleChangeProp}
                            />
                          </Grid>
                        </ConditionalRenderer>
                      </Grid>
                    </Grid>
                  )}

                  {componentType === ComponentItemType.FileUploadInput && (
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Grid item xs={12}>
                        <EditorRenderComponent
                          type='customErrorMessage'
                          componentId={componentId}
                          propPath={"props.fileValidation"}
                          label='File'
                          validationTypeString={"fileValidation"}
                          onChangeLabel={"customPasswordMessageContent"}
                          customMessageContentPath={"props.customPasswordMessageContent"}
                          handleToggleProp={handleToggleProp}
                          handleChangeProp={handleChangeProp}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {componentType === ComponentItemType.TextField && (
                    <ConditionalRenderer
                      renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "Password" }]}
                      componentId={componentId}
                    >
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='customErrorMessage'
                            componentId={componentId}
                            propPath={"props.passwordValidation"}
                            label='Password'
                            validationTypeString={"passwordValidation"}
                            onChangeLabel={"customPasswordMessageContent"}
                            customMessageContentPath={"props.customPasswordMessageContent"}
                            handleToggleProp={handleToggleProp}
                            handleChangeProp={handleChangeProp}
                          />
                        </Grid>
                      </Grid>

                      <ConditionalRenderer
                        renderConditionPaths={[{ path: "props.passwordValidation", expectedValue: true }]}
                        componentId={componentId}
                      >
                        <Grid container marginInlineStart={"15px"} xs={12}>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='checkbox'
                              componentId={componentId}
                              propPath={"props.includeLowercase"}
                              checkboxName={"includeLowercase"}
                              label='Include Lowercase Characters'
                              onChangeLabel={"includeLowercase"}
                              handleToggleProp={handleToggleProp}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='checkbox'
                              componentId={componentId}
                              propPath={"props.includeUppercase"}
                              checkboxName={"includeUppercase"}
                              onChangeLabel={"includeUppercase"}
                              label='Include Uppercase Characters'
                              handleToggleProp={handleToggleProp}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='checkbox'
                              componentId={componentId}
                              propPath={"props.includeNumbers"}
                              checkboxName={"includeNumbers"}
                              onChangeLabel={"includeNumbers"}
                              label='Include Numbers'
                              handleToggleProp={handleToggleProp}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EditorRenderComponent
                              type='checkbox'
                              componentId={componentId}
                              propPath={"props.includeSymbols"}
                              checkboxName={"includeSymbols"}
                              onChangeLabel={"includeSymbols"}
                              label='Include Symbols'
                              handleToggleProp={handleToggleProp}
                            />
                          </Grid>

                          <Grid container xs={12} marginTop={2}>
                            <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"start"}>
                              <Typography sx={{ fontSize: "12px" }}>Min Password Length:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <EditorRenderComponent
                                type='autoCompleteSyntax'
                                propPath='props.minLengthPassword'
                                textFieldType={"number"}
                                componentId={componentId}
                                label={"Max Value"}
                                onChangeLabel={"minLengthPassword"}
                                handleChangeProp={handleChangeProp}
                                view={view}
                                views={views}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </ConditionalRenderer>
                    </ConditionalRenderer>
                  )}

                  {componentType === ComponentItemType.TextField && (
                    <ConditionalRenderer
                      renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "URL" }]}
                      componentId={componentId}
                    >
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='customErrorMessage'
                            componentId={componentId}
                            propPath={"props.urlValidation"}
                            label='URL'
                            validationTypeString={"urlValidation"}
                            onChangeLabel={"customUrlMessageContent"}
                            handleToggleProp={handleToggleProp}
                            handleChangeProp={handleChangeProp}
                          />
                        </Grid>
                      </Grid>
                      <ConditionalRenderer
                        renderConditionPaths={[{ path: "props.urlValidation", expectedValue: true }]}
                        componentId={componentId}
                      >
                        <Grid container marginInlineStart={"15px"} xs={12}>
                          <Grid item xs={6}>
                            <EditorRenderComponent
                              type='checkbox'
                              componentId={componentId}
                              propPath={"props.enableHttpValidation"}
                              checkboxName={"enableHttpValidation"}
                              label='Enable http'
                              onChangeLabel={"enableHttpValidation"}
                              handleToggleProp={handleToggleProp}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <EditorRenderComponent
                              type='checkbox'
                              componentId={componentId}
                              propPath={"props.enableHttpsValidation"}
                              checkboxName={"enableHttpsValidation"}
                              label='Enable https'
                              onChangeLabel={"enableHttpsValidation"}
                              handleToggleProp={handleToggleProp}
                            />
                          </Grid>
                        </Grid>
                      </ConditionalRenderer>
                    </ConditionalRenderer>
                  )}

                  {componentType === ComponentItemType.TextField && (
                    <ConditionalRenderer
                      renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "UUID" }]}
                      componentId={componentId}
                    >
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='customErrorMessage'
                            componentId={componentId}
                            propPath={"props.uuidValidation"}
                            defaultValue={true}
                            label='UUID'
                            validationTypeString={"uuidValidation"}
                            onChangeLabel={"customUUidMessageContent"}
                            handleToggleProp={handleToggleProp}
                            handleChangeProp={handleChangeProp}
                          />
                        </Grid>
                      </Grid>
                    </ConditionalRenderer>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}

      {(componentType === ComponentItemType.FlexContainer || componentType === ComponentItemType.StepperContainer) && (
        <ItemsPropertiesAccordion
          expanded={accordionState.flexContainer}
          onChange={() => handleAccordionChange("flexContainer")}
          label='Flex Container'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid item xs={12}>
              <EditorRenderComponent
                type='mapValuesComponent'
                propPath='props.backgroundColor'
                componentId={componentId}
                enableMapValuesPath={"props.isMapValues"}
                label={"Background Color"}
                handleChangeProp={handleChangeProp}
                valueString={"backgroundColor"}
                view={view}
                views={views}
              />
            </Grid>
            <Grid item xs={12}>
              <EditorRenderComponent
                type='mapValuesComponent'
                propPath='props.background'
                componentId={componentId}
                enableMapValuesPath={"props.isMapValues"}
                label={"Background"}
                handleChangeProp={handleChangeProp}
                valueString={"background"}
                view={view}
                views={views}
              />
            </Grid>
            <Grid item xs={12}>
              <EditorRenderComponent
                type='mapValuesComponent'
                propPath='props.border'
                componentId={componentId}
                enableMapValuesPath={"props.isMapValues"}
                label={"Border"}
                handleChangeProp={handleChangeProp}
                valueString={"border"}
                view={view}
                views={views}
              />
            </Grid>
            {flexContainerIncludedProps.map((name, index) => {
              let inputField;
              const label = splitUpperCase(name)?.join(" ");
              if (["justifyContent", "flexWrap", "alignItems", "flexDirection"].includes(name)) {
                const options = {
                  flexWrap: wrapValues,
                  justifyContent: justifyContentValues,
                  alignItems: alignItemsValues,
                  flexDirection: flexDirectionValues,
                };

                const currentOptions = options[name as keyof typeof options];
                inputField = (
                  <>
                    <EditorRenderComponent
                      type='autocomplete'
                      propPath={`props.sx.${name}`}
                      componentId={componentId}
                      placeholder={label}
                      label={label}
                      fullWidth
                      onChangeLabel={`props.sx.${name}`}
                      autocompleteOptions={currentOptions}
                      handleChangeProp={handleSetChangePropStyle}
                    />
                  </>
                );
              } else {
                inputField = (
                  <>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath={`props.sx.${name}`}
                      componentId={componentId}
                      label={label}
                      onChangeLabel={`props.sx.${name}`}
                      fullWidth
                      handleChangeProp={handleSetChangeProp}
                      view={view}
                      views={views}
                    />
                  </>
                );
              }
              return (
                <>
                  <Grid item xs={12} key={index}>
                    {inputField}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {componentType === ComponentItemType.GridContainer && (
        <ItemsPropertiesAccordion
          expanded={accordionState.gridContainer}
          onChange={() => handleAccordionChange("gridContainer")}
          label='Grid Container'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center' gap={"1px"}>
              <EditorRenderComponent
                type='typography'
                typographyStyle={{ fontSize: "12px" }}
                componentId={componentId}
                fallbackEvaluatedValue='Basic'
                conditionalValuePaths={[
                  {
                    path: "props.isAdvance",
                    expectedValue: true,
                    returnValue: "Advance",
                  },
                ]}
              />
              <EditorRenderComponent
                type='switch'
                propPath='props.isAdvance'
                componentId={componentId}
                onChangeLabel={"isAdvance"}
                onChangeSwitch={handleToggleProp}
              />
            </Grid>
            {gridContainerIncludedProps.map((name, index) => {
              const isSelectField = Object.keys(selectOptions).includes(name);
              const isSxProps =
                name === "justifyContent" || name === "align-content" || name === "margin" || name === "padding" || name === "gap";
              const label = splitUpperCase(name)?.join(" ");
              const inputField = isSelectField ? (
                <>
                  <EditorRenderComponent
                    type={"textField"}
                    componentId={componentId}
                    propPath={`props.sx.${name}`}
                    fallbackValuePath={`props.${name}`}
                    fallbackAsValue={defaultValues[name]}
                    label={label}
                    fullWidth
                    select={true}
                    onChangeLabel={name}
                    handleChangeProp={handleChangeGridTemplateColumns}
                    optionsSelect={selectOptions[name]}
                  />
                </>
              ) : (
                <>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath={isSxProps ? `props.sx.${name}` || "" : `props.${name}` || ""}
                    fallbackValuePath={`props.${name}`}
                    componentId={componentId}
                    placeholder={label}
                    label={label}
                    onChangeLabel={isSxProps ? `props.sx.${name}` : name}
                    fullWidth
                    handleChangeProp={isSxProps ? handleSetChangeProp : handleChangeGridTemplateColumns}
                    view={view}
                    views={views}
                  />
                </>
              );
              return (
                <>
                  <ConditionalRenderer renderConditionPaths={[{ path: "props.isAdvance", expectedValue: false }]} componentId={componentId}>
                    {name == "gridTemplateColumns" ||
                    name == "justifyContent" ||
                    name == "gridTemplateRows" ||
                    name == "gridTemplateAreas" ||
                    name == "gridTemplate" ||
                    name == "gridColumnGap" ||
                    name == "gridRowGap" ||
                    name == "grid" ||
                    name == "justifyItems" ||
                    name == "alignItems" ||
                    name == "placeItems" ||
                    name == "placeContent" ||
                    name == "justifyContent" ||
                    name == "alignContent" ? null : (
                      <Grid item xs={12} key={index}>
                        {inputField}
                      </Grid>
                    )}
                  </ConditionalRenderer>
                  <ConditionalRenderer renderConditionPaths={[{ path: "props.isAdvance", expectedValue: true }]} componentId={componentId}>
                    {name == "extraSmall" || name == "small" || name == "medium" || name == "large" || name == "extraLarge" ? null : (
                      <Grid item xs={12} key={index}>
                        {inputField}
                      </Grid>
                    )}
                  </ConditionalRenderer>
                </>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      <ConditionalRenderer renderConditionPaths={[]} isParentFlexEnable={true} componentId={componentId}>
        <ItemsPropertiesAccordion
          expanded={accordionState.flexChildIncludedProps}
          onChange={() => handleAccordionChange("flexChildIncludedProps")}
          label='Flex Child'
        >
          <Grid container px={1} py={1} spacing={1} sx={{ width: "100%" }}>
            {flexChildIncludedProps.map((name, index) => {
              let inputField;
              const label = splitUpperCase(name)?.join(" ");
              if (["alignSelf"].includes(name)) {
                const options: any = {
                  alignSelf: alignSelfValues,
                };
                const currentOptions = options[name as keyof typeof options];
                inputField = (
                  <>
                    <EditorRenderComponent
                      type='autocomplete'
                      propPath={`config.parentStyle.${name}`}
                      componentId={componentId}
                      label={label}
                      placeholder={label}
                      fullWidth
                      onChangeLabel={`config.parentStyle.${name}`}
                      autocompleteOptions={currentOptions}
                      handleChangeProp={handleSetChangeProp}
                    />
                  </>
                );
              } else {
                inputField = (
                  <>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath={`config.parentStyle.${name}`}
                      textFieldName={name}
                      componentId={componentId}
                      label={label}
                      placeholder={label}
                      onChangeLabel={`config.parentStyle.${name}`}
                      fullWidth
                      handleChangeProp={handleSetChangeProp}
                      view={view}
                      views={views}
                    />
                  </>
                );
              }
              return (
                <Grid item xs={6} key={index}>
                  {inputField}
                </Grid>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      </ConditionalRenderer>

      <ConditionalRenderer renderConditionPaths={[]} isParentGridEnabled={true} componentId={componentId}>
        <ItemsPropertiesAccordion
          expanded={accordionState.gridChildIncludedProps}
          onChange={() => handleAccordionChange("gridChildIncludedProps")}
          label='Grid Child'
        >
          <Grid container px={1} py={1} spacing={1} sx={{ width: "100%" }}>
            {gridChildIncludedProps.map((name, index) => {
              let inputField;
              const label = splitUpperCase(name)?.join(" ");
              inputField = (
                <>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath={`config.parentStyle${name}`}
                    textFieldName={name}
                    componentId={componentId}
                    label={label}
                    placeholder={label}
                    onChangeLabel={`config.parentStyle${name}`}
                    fullWidth
                    handleChangeProp={handleSetChangeProp}
                    view={view}
                    views={views}
                  />
                </>
              );
              return (
                <Grid item xs={6} key={index}>
                  {inputField}
                </Grid>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      </ConditionalRenderer>

      {(componentType === ComponentItemType.TextField ||
        componentType === ComponentItemType.FileUploadInput ||
        componentType === ComponentItemType.DatePicker ||
        componentType === ComponentItemType.DateTimePicker ||
        componentType === ComponentItemType.CustomSwitch ||
        componentType === ComponentItemType.BXSelect) && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.display} onChange={() => handleAccordionChange("display")} label='Display'>
            <Grid container px={1} py={1} spacing={1}>
              <ConditionalRenderer
                renderConditionPaths={[{ path: "props.uploaderType", expectedValue: true, compareValue: "TextField" }]}
                componentId={componentId}
              >
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadLabel'
                    componentId={componentId}
                    label='Label'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadLabel`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadLabelColor'
                    componentId={componentId}
                    defaultValue={"white"}
                    label='Label Color'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadLabelColor`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadLabelSize'
                    componentId={componentId}
                    label='Label Size'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadLabelSize`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadLabelWeight'
                    componentId={componentId}
                    label='Label Weight'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadLabelWeight`}
                  />
                </Grid>
              </ConditionalRenderer>
              <ConditionalRenderer
                renderConditionPaths={[{ path: "props.uploaderType", expectedValue: true, compareValue: "Typography" }]}
                componentId={componentId}
              >
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadLabelTypography'
                    componentId={componentId}
                    defaultValue={"Typography"}
                    label='Placeholder'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadLabelTypography`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadColorTypography'
                    componentId={componentId}
                    label='Typography Color'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadColorTypography`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditorRenderComponent
                    type='textField'
                    propPath='props.uploadSizeTypography'
                    componentId={componentId}
                    label='Typography Size'
                    fullWidth
                    handleChangeProp={handleChangeProp}
                    onChangeLabel={`uploadSizeTypography`}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Border Bottom</Typography>
                  <EditorRenderComponent
                    type='switch'
                    propPath='props.borderBottomTypography'
                    componentId={componentId}
                    onChangeLabel={"borderBottomTypography"}
                    onChangeSwitch={handleToggleProp}
                  />
                </Grid>
              </ConditionalRenderer>

              <ConditionalRenderer
                renderConditionPaths={[
                  { path: "props.uploaderType", expectedValue: true, compareValue: "Default", operation: "OR" },
                  { path: "type", expectedValue: true, compareValue: "TextField", operation: "OR" },
                ]}
                componentId={componentId}
              >
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='translationInput'
                    componentId={componentId}
                    propPath='props.label'
                    label={"label"}
                    onChangeLabel={"label"}
                    appId={appId}
                    multiLingual={multiLingual}
                    setMultiLingual={setMultiLingual}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EditorRenderComponent
                    type='autoCompleteSyntax'
                    propPath='props.placeholder'
                    componentId={componentId}
                    label={"Place Holder"}
                    onChangeLabel={"placeholder"}
                    handleChangeProp={handleChangeProp}
                    view={view}
                    views={views}
                  />
                </Grid>
              </ConditionalRenderer>
            </Grid>

            <Grid container px={1} py={1} spacing={2}>
              {componentType === ComponentItemType.FileUploadInput && (
                <>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='textField'
                      propPath='props.uploaderType'
                      componentId={componentId}
                      label='Type'
                      placeholder='Type'
                      fullWidth
                      select={true}
                      onChangeLabel={"uploaderType"}
                      defaultValue={"Default"}
                      optionsSelect={uploaderTypes}
                      handleChangeProp={handleChangeSelect}
                    />
                  </Grid>
                  <ConditionalRenderer
                    renderConditionPaths={[{ path: "props.uploaderType", expectedValue: true, compareValue: "IconButton" }]}
                    componentId={componentId}
                  >
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='autoCompleteSyntax'
                        propPath='props.iconName'
                        componentId={componentId}
                        label={"Icon Name"}
                        onChangeLabel={"iconName"}
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='mapValuesComponent'
                        propPath='props.IconBackgroundColor'
                        componentId={componentId}
                        enableMapValuesPath={"props.isMapValues"}
                        valueString={"IconBackgroundColor"}
                        label='Background Color'
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='mapValuesComponent'
                        propPath='props.IconBorderColor'
                        componentId={componentId}
                        enableMapValuesPath={"props.isMapValues"}
                        label='Border Color'
                        valueString={"IconBorderColor"}
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='mapValuesComponent'
                        propPath='props.iconSize'
                        componentId={componentId}
                        enableMapValuesPath={"props.isMapValues"}
                        label='Border Color'
                        valueString={"iconSize"}
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='mapValuesComponent'
                        propPath='props.IconBorderWidth'
                        componentId={componentId}
                        enableMapValuesPath={"props.isMapValues"}
                        label='Border Width'
                        valueString={"IconBorderWidth"}
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EditorRenderComponent
                        type='mapValuesComponent'
                        propPath='props.iconColor'
                        componentId={componentId}
                        enableMapValuesPath={"props.isMapValues"}
                        label='Icon Color'
                        valueString={"iconColor"}
                        handleChangeProp={handleChangeProp}
                        view={view}
                        views={views}
                      />
                    </Grid>
                  </ConditionalRenderer>
                  <ConditionalRenderer
                    renderConditionPaths={[{ path: "props.uploaderType", expectedValue: true, inverseCompareValue: "IconButton" }]}
                    componentId={componentId}
                  >
                    <>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='props.startIcon'
                          componentId={componentId}
                          label={"Start Icon"}
                          onChangeLabel={"startIcon"}
                          handleChangeProp={handleChangeProp}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='props.endIcon'
                          componentId={componentId}
                          label={"End Icon"}
                          onChangeLabel={"endIcon"}
                          handleChangeProp={handleChangeProp}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='props.startIconColor'
                          componentId={componentId}
                          label={"Start Icon Color"}
                          onChangeLabel={"startIconColor"}
                          handleChangeProp={handleChangeProp}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='props.endIconColor'
                          componentId={componentId}
                          label={"End Icon Color"}
                          onChangeLabel={"endIconColor"}
                          handleChangeProp={handleChangeProp}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='props.startIconSize'
                          componentId={componentId}
                          label={"Start Icon Size"}
                          onChangeLabel={"startIconSize"}
                          handleChangeProp={handleChangeProp}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='props.endIconSize'
                          componentId={componentId}
                          label={"End Icon Size"}
                          onChangeLabel={"endIconSize"}
                          handleChangeProp={handleChangeProp}
                          view={view}
                          views={views}
                        />
                      </Grid>
                    </>
                  </ConditionalRenderer>
                  <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontSize: "12px" }}>Media Preview</Typography>
                    <EditorRenderComponent
                      type='switch'
                      propPath='config.uploadConfig.withPreview'
                      componentId={componentId}
                      onChangeLabel={"uploadConfig.withPreview"}
                      onChangeSecondaryLabel={"boolean"}
                      onChangeSwitch={handleConfigChange}
                    />
                  </Grid>
                  <ConditionalRenderer
                    renderConditionPaths={[{ path: "props.withPreview", expectedValue: true }]}
                    componentId={componentId}
                  >
                    <Grid container px={1} py={1} spacing={2} sx={{ ml: 0.1 }}>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='config.uploadConfig.widthPreview'
                          componentId={componentId}
                          label={"Width Px"}
                          textFieldType={"number"}
                          onChangeLabel={"uploadConfig.widthPreview"}
                          onChangeSecondaryLabel={"number"}
                          handleChangeProp={handleConfigChange}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='config.uploadConfig.heightPreview'
                          componentId={componentId}
                          label={"Height Px"}
                          textFieldType={"number"}
                          onChangeLabel={"uploadConfig.heightPreview"}
                          onChangeSecondaryLabel={"number"}
                          handleChangeProp={handleConfigChange}
                          view={view}
                          views={views}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditorRenderComponent
                          type='autoCompleteSyntax'
                          propPath='config.uploadConfig.borderRadiusPreview'
                          componentId={componentId}
                          label={"border Radius"}
                          textFieldType={"number"}
                          onChangeLabel={"uploadConfig.borderRadiusPreview"}
                          onChangeSecondaryLabel={"number"}
                          handleChangeProp={handleConfigChange}
                          view={view}
                          views={views}
                        />
                      </Grid>
                    </Grid>
                  </ConditionalRenderer>
                </>
              )}
              {componentType === ComponentItemType.TextField && (
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Max Number of Characters</Typography>
                  <EditorRenderComponent
                    type='switch'
                    propPath='props.enableMaxNumberOfChars'
                    componentId={componentId}
                    onChangeLabel={"enableMaxNumberOfChars"}
                    onChangeSwitch={handleToggleProp}
                  />
                </Grid>
              )}

              <ConditionalRenderer
                renderConditionPaths={[{ path: "props.enableMaxNumberOfChars", expectedValue: true }]}
                componentId={componentId}
              >
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Grid item xs={12}>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.maxNumberOfChars'
                      componentId={componentId}
                      label={"Max Number of Characters"}
                      textFieldType={"number"}
                      onChangeLabel={"maxNumberOfChars"}
                      onChangeSecondaryLabel={"number"}
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </ConditionalRenderer>

              {componentType === ComponentItemType.TextField && (
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Multiline</Typography>
                  <EditorRenderComponent
                    type='switch'
                    propPath='props.multiline'
                    componentId={componentId}
                    onChangeLabel={"multiline"}
                    onChangeSwitch={handleToggleProp}
                  />
                </Grid>
              )}

              <ConditionalRenderer renderConditionPaths={[{ path: "props.multiline", expectedValue: true }]} componentId={componentId}>
                <Grid item container alignItems={"space-between"} justifyContent={"space-between"}>
                  <Grid display='flex' item alignItems='center'>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.borderRadiusPreview'
                      componentId={componentId}
                      label={"Rows"}
                      textFieldType={"number"}
                      onChangeLabel={"rows"}
                      onChangeSecondaryLabel={"number"}
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                  <Grid display='flex' item alignItems='center'>
                    <EditorRenderComponent
                      type='autoCompleteSyntax'
                      propPath='props.maxRows'
                      componentId={componentId}
                      label={"Rows"}
                      textFieldType={"number"}
                      onChangeLabel={"rows"}
                      onChangeSecondaryLabel={"maxRows"}
                      handleChangeProp={handleChangeProp}
                      view={view}
                      views={views}
                    />
                  </Grid>
                </Grid>
              </ConditionalRenderer>

              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Disabled</Typography>
                <EditorRenderComponent
                  type='switch'
                  propPath='props.disabled'
                  componentId={componentId}
                  onChangeLabel={"disabled"}
                  onChangeSwitch={handleToggleProp}
                />
              </Grid>
              {componentType === ComponentItemType.TextField && (
                <ConditionalRenderer
                  renderConditionPaths={[{ path: "props.type", expectedValue: true, compareValue: "Password" }]}
                  componentId={componentId}
                >
                  <>
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Typography sx={{ fontSize: "12px" }}>Display show password toggle</Typography>
                      <EditorRenderComponent
                        type='switch'
                        propPath='props.showPasswordToggle'
                        componentId={componentId}
                        onChangeLabel={"showPasswordToggle"}
                        onChangeSwitch={handleToggleProp}
                      />
                    </Grid>
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Typography sx={{ fontSize: "12px" }}>Disable Browser Prefill</Typography>
                      <EditorRenderComponent
                        type='switch'
                        defaultValue={false}
                        propPath='props.disablePrefill'
                        componentId={componentId}
                        onChangeLabel={"disablePrefill"}
                        onChangeSwitch={handleToggleProp}
                      />
                    </Grid>
                    <ConditionalRenderer
                      renderConditionPaths={[{ path: "props.showPasswordToggle", expectedValue: true }]}
                      componentId={componentId}
                    >
                      <>
                        <Grid item xs={12} marginInlineStart={"5px"}>
                          <Typography sx={{ fontSize: "12px" }}>Icon when the password is hidden</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='iconPicker'
                            componentId={componentId}
                            propPath='props.iconConfigHidden.icon'
                            fallbackValuePath='props.iconConfigHidden.icon'
                            label='Icon'
                            bXIconPickerName='props.iconConfigHidden.icon'
                            objName={"props.iconConfigHidden"}
                            previewIcon={false}
                            showCaption={false}
                            handleSetChangeIconProp={handleSetChangeIconProp}
                            handleChangeProp={handleSetChangeProp}
                            onChangeLabel='props.iconConfigHidden.icon'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <EditorRenderComponent
                            type='textField'
                            propPath='props.iconColorHidden'
                            componentId={componentId}
                            label={"Color"}
                            fullWidth
                            handleChangeProp={handleChangeProp}
                            onChangeLabel={`iconColorHidden`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <EditorRenderComponent
                            type='textField'
                            propPath='props.IconOpacityHidden'
                            componentId={componentId}
                            label={"Opacity"}
                            fullWidth
                            defaultValue={"0.3"}
                            handleChangeProp={handleChangeProp}
                            onChangeLabel={`IconOpacityHidden`}
                          />
                        </Grid>
                        {/* hidden */}
                        <Grid item xs={12} marginInlineStart={"5px"}>
                          <Typography sx={{ fontSize: "12px" }}>Icon when the password is visible</Typography>
                        </Grid>
                        <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                          <Typography sx={{ fontSize: "12px" }}>Same Hidden Icon</Typography>
                          <EditorRenderComponent
                            type='switch'
                            propPath='props.sameHiddenIcon'
                            componentId={componentId}
                            onChangeLabel={"sameHiddenIcon"}
                            onChangeSwitch={handleToggleProp}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EditorRenderComponent
                            type='iconPicker'
                            componentId={componentId}
                            propPath='props.iconConfig.icon'
                            fallbackValuePath='props.iconConfig.icon'
                            label='Icon'
                            bXIconPickerName='props.iconConfig.icon'
                            objName={"props.iconConfig"}
                            previewIcon={false}
                            showCaption={false}
                            handleSetChangeIconProp={handleSetChangeIconProp}
                            handleChangeProp={handleSetChangeProp}
                            onChangeLabel='props.iconConfig.icon'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <EditorRenderComponent
                            type='textField'
                            propPath='props.iconColorVisible'
                            componentId={componentId}
                            label={"Color"}
                            fullWidth
                            handleChangeProp={handleChangeProp}
                            onChangeLabel={`iconColorVisible`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <EditorRenderComponent
                            type='textField'
                            propPath='props.IconOpacityVisible'
                            componentId={componentId}
                            defaultValue={"1"}
                            label={"Color"}
                            fullWidth
                            handleChangeProp={handleChangeProp}
                            onChangeLabel={`IconOpacityVisible`}
                          />
                        </Grid>
                      </>
                    </ConditionalRenderer>
                  </>
                </ConditionalRenderer>
              )}
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}

      <ItemsPropertiesAccordion
        expanded={accordionState.visibility}
        onChange={() => handleAccordionChange("visibility")}
        label='Visibility'
      >
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='autoCompleteSyntax'
              propPath={`config.visibility.visibilityCondition.${layoutBreak}`}
              componentId={componentId}
              label='visibility condition'
              placeholder='visibility condition'
              onChangeLabel={`visibility.visibilityCondition.${layoutBreak}`}
              fullWidth
              defaultValue={""}
              handleChangeProp={handleConfigChange}
              view={view}
              views={views}
            />
          </Grid>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='textField'
              propPath={`config.visibility.selectedVisibilityType.${layoutBreak}`}
              componentId={componentId}
              label='Type'
              placeholder='Type'
              fullWidth
              select={true}
              defaultValue={"Hidden"}
              fallbackValuePath='config.visibility.selectedVisibilityType.lg'
              onChangeLabel={`visibility.selectedVisibilityType.${layoutBreak}`}
              optionsSelect={visibilityTypes}
              layoutBreak={layoutBreak}
              handleChangeProp={handleConfigChange}
            />
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion expanded={accordionState.disable} onChange={() => handleAccordionChange("disable")} label='Disable'>
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <EditorRenderComponent
              type='autoCompleteSyntax'
              propPath='config.disable.disableCondition'
              componentId={componentId}
              label={"disable condition"}
              placeholder='disable condition'
              onChangeLabel={"disable.disableCondition"}
              fullWidth
              handleChangeProp={handleConfigChange}
              view={view}
              views={views}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>With overlay</Typography>
              <EditorRenderComponent
                type='switch'
                propPath='config.disable.withOverlay'
                componentId={componentId}
                onChangeLabel={"disable.withOverlay"}
                defaultValue={false}
                onChangeSecondaryLabel={"boolean"}
                onChangeSwitch={handleConfigChange}
              />
            </Grid>
          </Grid>
          <ConditionalRenderer
            renderConditionPaths={[{ path: "config.disable.withOverlay", expectedValue: true }]}
            componentId={componentId}
          >
            <Grid item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.disable.overlayColor'
                componentId={componentId}
                label={"overlay color"}
                onChangeLabel={"disable.overlayColor"}
                defaultValue={"#000000"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>
            <Grid item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.disable.overlayOpacity'
                componentId={componentId}
                label={"overlay opacity"}
                onChangeLabel={"disable.overlayOpacity"}
                defaultValue={"0.5"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>
          </ConditionalRenderer>
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion expanded={accordionState.repeated} onChange={() => handleAccordionChange("repeated")} label='Repeated'>
        <Grid container px={1} py={1} spacing={2}>
          <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
            <Typography sx={{ fontSize: "12px" }}>Enabled</Typography>
            <EditorRenderComponent
              type='switch'
              propPath='config.repeated.enabled'
              componentId={componentId}
              onChangeLabel={"repeated.enabled"}
              onChangeSecondaryLabel={"boolean"}
              onChangeSwitch={handleConfigChange}
            />
          </Grid>
          <ConditionalRenderer renderConditionPaths={[{ path: "config.repeated.enabled", expectedValue: true }]} componentId={componentId}>
            <Grid marginInlineStart={"5px"} item xs={12}>
              <EditorRenderComponent
                type='textField'
                propPath='config.repeated.data'
                componentId={componentId}
                label={"Data"}
                onChangeLabel={"repeated.data"}
                fullWidth
                handleChangeProp={handleConfigChange}
              />
            </Grid>
            <Grid marginInlineStart={"5px"} item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.repeated.minItems'
                componentId={componentId}
                label={"Min items"}
                onChangeLabel={"repeated.minItems"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>
            <Grid marginInlineStart={"5px"} item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.repeated.maxItems'
                componentId={componentId}
                label={"Max items"}
                onChangeLabel={"repeated.maxItems"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>
            <Grid marginInlineStart={"5px"} item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.repeated.defaultItems'
                componentId={componentId}
                label={"Default number of items"}
                onChangeLabel={"repeated.defaultItems"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>
          </ConditionalRenderer>

          {/* {!!item?.config?.repeated?.enabled && (
            <>
              <Grid marginInlineStart={"5px"} item xs={12}>
                <AutoCompleteSyntax
                  size='small'
                  fullWidth
                  label={"Data"}
                  value={item?.config?.repeated?.data || ""}
                  onChange={handleConfigChange("repeated.data")}
                  view={view}
                  views={views}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid marginInlineStart={"5px"} item xs={12}>
                <AutoCompleteSyntax
                  size='small'
                  fullWidth
                  label={"Min items"}
                  value={item?.config?.repeated?.minItems || ""}
                  onChange={handleConfigChange("repeated.minItems")}
                  view={view}
                  views={views}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid marginInlineStart={"5px"} item xs={12}>
                <AutoCompleteSyntax
                  size='small'
                  fullWidth
                  label={"Max items"}
                  value={item?.config?.repeated?.maxItems || ""}
                  onChange={handleConfigChange("repeated.maxItems")}
                  view={view}
                  views={views}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid marginInlineStart={"5px"} item xs={12}>
                <AutoCompleteSyntax
                  size='small'
                  fullWidth
                  disabled={item?.config?.repeated?.data?.trim()}
                  label={"Default number of items"}
                  view={view}
                  views={views}
                  value={item?.config?.repeated?.defaultItems || ""}
                  onChange={handleConfigChange("repeated.defaultItems")}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </>
          )} */}
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion expanded={accordionState.styles} onChange={() => handleAccordionChange("styles")} label='Styles'>
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <Grid display='flex' alignItems='center' justifyContent='space-between'>
              <Typography sx={{ fontSize: "12px" }}>Position</Typography>
              <EditorRenderComponent
                type='switch'
                propPath={`config.position.enabled`}
                componentId={componentId}
                onChangeLabel={`position.enabled`}
                onChangeSecondaryLabel={"boolean"}
                onChangeSwitch={handleConfigChange}
              />
            </Grid>
          </Grid>
          <ConditionalRenderer renderConditionPaths={[{ path: "config.position.enabled", expectedValue: true }]} componentId={componentId}>
            <>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='textField'
                  propPath={`config.position.type.${layoutBreak}`}
                  componentId={componentId}
                  label='Type'
                  placeholder='Type'
                  fullWidth
                  defaultValue={"fixed"}
                  layoutBreak={layoutBreak}
                  fallbackValuePath={"config.position.type.lg"}
                  handleChangeProp={handleSetChangeProp}
                  onChangeLabel={`config.position.type.${layoutBreak}`}
                  select={true}
                  optionsSelect={[
                    { id: "fixed", title: "fixed" },
                    { id: "sticky", title: "sticky" },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='textField'
                  propPath={`config.position.top.${layoutBreak}`}
                  componentId={componentId}
                  label='Top'
                  placeholder='Top'
                  fullWidth
                  handleChangeProp={handleSetChangeProp}
                  onChangeLabel={`config.position.top.${layoutBreak}`}
                />
              </Grid>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='textField'
                  propPath={`config.position.bottom.${layoutBreak}`}
                  componentId={componentId}
                  label='Bottom'
                  placeholder='Bottom'
                  fullWidth
                  handleChangeProp={handleSetChangeProp}
                  onChangeLabel={`config.position.bottom.${layoutBreak}`}
                />
              </Grid>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='textField'
                  propPath={`config.position.left.${layoutBreak}`}
                  componentId={componentId}
                  label='Left'
                  placeholder='Left'
                  fullWidth
                  handleChangeProp={handleSetChangeProp}
                  onChangeLabel={`config.position.left.${layoutBreak}`}
                />
              </Grid>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='textField'
                  propPath={`config.position.right.${layoutBreak}`}
                  componentId={componentId}
                  label='Right'
                  placeholder='Right'
                  fullWidth
                  handleChangeProp={handleSetChangeProp}
                  onChangeLabel={`config.position.right.${layoutBreak}`}
                />
              </Grid>
              <Grid item xs={6}>
                <EditorRenderComponent
                  type='textField'
                  propPath={`config.position.zIndex.${layoutBreak}`}
                  componentId={componentId}
                  label='z-index'
                  placeholder='z-index'
                  fullWidth
                  handleChangeProp={handleSetChangeProp}
                  onChangeLabel={`config.position.zIndex.${layoutBreak}`}
                />
              </Grid>
            </>
          </ConditionalRenderer>
          <Grid item xs={12}>
            <Grid display='flex' alignItems='center' justifyContent='space-between'>
              <Typography sx={{ fontSize: "12px" }}>Enable Transition</Typography>
              <EditorRenderComponent
                type='switch'
                propPath={`config.transition.enabled`}
                componentId={componentId}
                onChangeLabel={`transition.enabled`}
                onChangeSecondaryLabel={"boolean"}
                onChangeSwitch={handleConfigChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box px={1} py={1}>
          <Box display={"flex"} alignItems='center' justifyContent='space-between' mb={2}>
            <Typography sx={{ fontSize: "16px" }}></Typography>
            <EditorRenderComponent
              type='button'
              componentId={componentId}
              label='Save'
              buttonVariant='contained'
              onClickButton={saveStyleHandler}
              stateProps={{ propPath: "props.sx", state: styles, setState: setStyles }}
            />
          </Box>
          <Box mb={2} sx={{ fontSize: "column" }}>
            <EditorRenderComponent
              type='autoCompleteCSSComponent'
              propPath='props.sx'
              componentId={componentId}
              handleChangeProp={handleChangeStyles}
            />
          </Box>
          <Box marginTop={2} mb={2}>
            <Button fullWidth onClick={() => onDeleteItem?.([componentId])} variant='outlined'>
              Delete Item
            </Button>
          </Box>
        </Box>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion expanded={accordionState.hover} onChange={() => handleAccordionChange("hover")} label='Hover'>
        <Box px={1} py={1}>
          <Box display={"flex"} alignItems='center' justifyContent='space-between' mb={2}>
            <Typography sx={{ fontSize: "16px" }}></Typography>
            <EditorRenderComponent
              type='button'
              componentId={componentId}
              label='Save'
              buttonVariant='contained'
              onClickButton={saveHoverStyles}
            />
          </Box>
          <Box mb={2} sx={{ fontSize: "column" }}>
            <EditorRenderComponent
              type='autoCompleteCSSComponent'
              propPath='config.hover.styles'
              componentId={componentId}
              handleChangeProp={handleHoverChangeStyle}
            />
          </Box>
        </Box>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion
        expanded={accordionState.interactionConfig}
        onChange={() => handleAccordionChange("interactionConfig")}
        label='Interactions'
      >
        <Grid container justifyContent='center' alignItems='center' py={1}>
          <Grid item px={1} style={{ width: "100%" }}>
            <Button variant='outlined' onClick={() => setIsInteractionConfigModalOpen(true)} style={{ width: "100%" }}>
              Interactions Configuration
            </Button>
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>

      {(componentType === ComponentItemType.BXLayout || componentType === ComponentItemType.BXView) && (
        <ItemsPropertiesAccordion expanded={accordionState.loading} onChange={() => handleAccordionChange("loading")} label='Loading'>
          <Grid container px={1} py={1} spacing={2}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Enable Loading View</Typography>
              <EditorRenderComponent
                type='switch'
                propPath='config.loadingConfig.isLoadingViewEnabled'
                componentId={componentId}
                onChangeLabel={"loadingConfig.isLoadingViewEnabled"}
                onChangeSecondaryLabel={"boolean"}
                onChangeSwitch={handleConfigChange}
                sx={{ ml: 2 }}
              />
            </Grid>
            <ConditionalRenderer
              renderConditionPaths={[{ path: "config.loadingConfig.isLoadingViewEnabled", expectedValue: true }]}
              componentId={componentId}
            >
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='radioGroup'
                  propPath='config.loadingConfig.loadingViewType'
                  componentId={componentId}
                  onChangeLabel='loadingConfig.loadingViewType'
                  handleChangeProp={handleConfigChange}
                  optionsSelect={[
                    { value: "cover", label: "Cover" },
                    { value: "centered", label: "Centered" },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='textField'
                  propPath='config.loadingConfig.loadingView'
                  componentId={componentId}
                  label='Select Loading View'
                  fullWidth
                  select={true}
                  onChangeLabel={"loadingConfig.loadingView"}
                  optionsSelect={views}
                  handleChangeProp={handleConfigChange}
                />
              </Grid>
            </ConditionalRenderer>

            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Enable Loading Image</Typography>
              <EditorRenderComponent
                type='switch'
                propPath='config.loadingConfig.isLoadingImageEnabled'
                componentId={componentId}
                onChangeLabel={"loadingConfig.isLoadingImageEnabled"}
                onChangeSecondaryLabel={"boolean"}
                onChangeSwitch={handleConfigChange}
                sx={{ ml: 2 }}
              />
            </Grid>
            <ConditionalRenderer
              renderConditionPaths={[{ path: "config.loadingConfig.isLoadingImageEnabled", expectedValue: true }]}
              componentId={componentId}
            >
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='radioGroup'
                  propPath='config.loadingConfig.loadingImageType'
                  componentId={componentId}
                  onChangeLabel='loadingConfig.loadingImageType'
                  handleChangeProp={handleConfigChange}
                  optionsSelect={[
                    { value: "cover", label: "Cover" },
                    { value: "centered", label: "Centered" },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <EditorRenderComponent
                  type='iconPicker'
                  componentId={componentId}
                  propPath='config.loadingConfig.loadingImage'
                  fallbackValuePath='default.iconPath'
                  label='Icon'
                  bXIconPickerName='props.iconConfig.icon'
                  previewIcon={false}
                  showCaption={false}
                  handleChangeProp={handleConfigChange}
                  onChangeLabel='loadingConfig.loadingImage'
                />
              </Grid>
            </ConditionalRenderer>

            <Grid item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.loadingConfig.loadingOverlayColor'
                componentId={componentId}
                label='Overlay color'
                placeholder='Overlay color'
                onChangeLabel={"loadingConfig.loadingOverlayColor"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>

            <Grid item xs={12}>
              <EditorRenderComponent
                type='autoCompleteSyntax'
                propPath='config.loadingConfig.loadingOverlayOpacity'
                componentId={componentId}
                label='Overlay opacity'
                placeholder='Overlay opacity'
                onChangeLabel={"loadingConfig.loadingOverlayOpacity"}
                fullWidth
                handleChangeProp={handleConfigChange}
                view={view}
                views={views}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {/* Stripe Modal */}
      <BXModal
        open={isStripeModalOpen}
        icon={<SettingsIcon />}
        maxWidth='md'
        title='Intent Configuration'
        onClose={() => setStripeModalOpen(false)}
      >
        <IntentConfigurationModal componentId={componentId} onSave={onIntentConfigSave} />
      </BXModal>
      {/*  Chart Modal */}
      <BXModal open={isModalOpen} icon={<SettingsIcon />} maxWidth='md' title='Chart Configuration' onClose={() => setIsModalOpen(false)}>
        <CreateChartForm onSave={handleSave} componentId={componentId} dataPropPath={"configData"} type={componentType} />
      </BXModal>
      {/* Interaction Modal */}
      <BXModal
        open={isInteractionConfigModalOpen}
        icon={<SettingsIcon />}
        maxWidth='md'
        title='Interaction Configuration'
        onClose={() => setIsInteractionConfigModalOpen(false)}
      >
        <InteractionConfig componentId={componentId} onSave={onInteractionConfigSave} />
      </BXModal>
      {/* Markdown Modal*/}
      <BXModal
        open={isMarkdownModalOpen}
        icon={<SettingsIcon />}
        maxWidth='md'
        title='Markdown Configuration'
        onClose={() => setMarkdownModalOpen(false)}
      >
        <EditorRenderComponent
          type='markdownEditor'
          propPath='props.label'
          componentId={componentId}
          handleChangeProp={handleChangeProp}
          onClose={() => setMarkdownModalOpen(false)}
        />
      </BXModal>
      {/* Suggest Data Modal */}
      <BXModal
        open={isSuggestDataOpen}
        icon={<SettingsIcon />}
        maxWidth='md'
        title='Select Suggested values'
        onClose={() => setIsSuggestDataOpen(false)}
      >
        <SuggestedValuesForm onSave={handleSaveSuggestComponent} componentId={componentId} />
      </BXModal>
      {/* ActionsConfig Modal */}
      <BXModal
        open={isActionsConfigModalOpen}
        icon={<SettingsIcon />}
        maxWidth='lg'
        title='Actions Configuration'
        onClose={() => setIsActionsConfigModalOpen(false)}
      >
        <MapChainActions
          componentId={componentId}
          view={view}
          views={views}
          setModalOpen={setIsActionsConfigModalOpen}
          onchangeLabel={false}
          appId={appId}
          collectionId={collectionId}
          pageId={pageId}
          handleConfigChange={handleConfigChange}
          onChangeProp={updateComponent}
          isTable={false}
          prefixTableActionPath={{}}
        />
      </BXModal>
      {/* Map Data Source Modal */}
      <BXModal
        open={isDataSourceModalOpen}
        icon={<SettingsIcon />}
        maxWidth='md'
        title='Select Data Source'
        onClose={() => setIsDataSourceModalOpen(false)}
      >
        <CreateSelectDataSource
          componentId={componentId}
          isMapDataSourcePath={"config.isMapDataSource"}
          onSave={handleSaveSelectComponent}
          isBuilder={true}
          handleConfigChange={handleConfigChange}
          onChangeProp={updateComponent}
        />
      </BXModal>
    </Grid>
  );
};

export default memo(ComponentEditor);
