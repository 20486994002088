import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { IconX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, memo, useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import BXModal from "src/components/BXUI/Modal";
import { setViewBuilder, upsertComponents } from "src/features/builder/builderSlice";
import normalizeFormBuilder from "src/features/builder/normalizeFormBuilder";
import { selectAllComponentsArray } from "src/features/builder/selectors";
import store from "src/store/store";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import ImportIcons from "../../../../assets/images/icons/Import.svg";
import UploadIcons from "../../../../assets/images/icons/Upload Minimalistic.svg";
import CreateTemplateModal from "../ManageTemplates/CreateTemplateModal";
import TemplatesTable from "../ManageTemplates/TemplatesTable";
import OASSelector from "../OASSelector";
import { LayoutToggleButtons } from "./components/LayoutToggleButtons";
import { ComponentItemType } from "./types";
import { changeChildren, layoutBreakPoints } from "./utils";

const denormalizeFormBuilder = () => {
  const normalizedState = selectAllComponentsArray(store.getState());

  return normalizedState?.filter((item: any) => !item.parentId).map(changeChildren);
};

const ViewBuilderHeader: FC<any> = ({
  handleBackButton,
  templateData,
  isEditingTemplate,
  handleChangeLayout,
  template,
  addTemplate,
  isHistory,
  viewName,
  appBuilderMode,
  params,
  handleSelectComponent,
  onSave,
  getCanvasDimension,
}) => {
  const dispatch = useDispatch();
  const pathParams = useParams();
  // const { templateId } = pathParams;
  const { appId, collectionId, pageId, viewId, templateId, historyId, layoutId, componentId: componentIdFromParams } = params || pathParams;

  const [openTemplateList, setOpenTemplateList] = useState(false);
  const [openExportTemplate, setOpenExportTemplate] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [syncWithBreakpoint, setSyncWithBreakpoint] = useState("lg");
  const [duplicates, setDuplicates] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isIconRotated, setIsIconRotated] = useState(false);
  const [hasLayoutReset, setHasLayoutReset] = useState<boolean>(false);
  const layoutBreak = useSelector((state: any) => state.builder.layoutBreak);

  const { saveAsTemplate, editView } = useBXBuilderContext();

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsIconRotated(!isIconRotated);

    if (!isIconRotated) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsIconRotated(!isIconRotated);
  };

  const handleSelectOption = (option: any) => {
    handleCloseMenu();
    if (option === "Upload") {
      setOpenExportTemplate(true);
      setIsIconRotated(!isIconRotated);
    } else if (option === "Import") {
      setOpenTemplateList(true);
      setIsIconRotated(!isIconRotated);
    }
    handleCloseMenu();
  };

  const handleSynchronizeLayout = () => {
    const builderElements = selectAllComponentsArray(store.getState());
    const elements = _.cloneDeep(builderElements);

    const synchronizeBreakpoints = obj => {
      if (obj && typeof obj === "object") {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (value && typeof value === "object" && Object.keys(value).some(bp => ["xl", "lg", "md", "xs"].includes(bp))) {
              if (value[syncWithBreakpoint] !== undefined) {
                obj[key][layoutBreak] = value[syncWithBreakpoint];
              }
            } else if (typeof value === "object") {
              synchronizeBreakpoints(value);
            }
          }
        }
      }
    };

    elements.forEach((element: any) => {
      if (element.props) {
        synchronizeBreakpoints(element.props);
      }
      if (element.config) {
        synchronizeBreakpoints(element.config);
      }
      if (element?.props?.isMapValues && element?.optionMap) {
        Object.values(element.optionMap).forEach((mapValueOptionElement: any) => {
          if (mapValueOptionElement.props) {
            synchronizeBreakpoints(mapValueOptionElement.props);
          }

          if (mapValueOptionElement.config) {
            synchronizeBreakpoints(mapValueOptionElement.config);
          }
        });
      }
    });

    dispatch(setViewBuilder({ builderElements: elements }));
  };

  const url = window.location.pathname;
  let defaultType;
  if (appBuilderMode) {
    defaultType = "APPLICATION";
  } else {
    if (url.startsWith("/buildx/page-builder/")) {
      defaultType = "PAGE";
    } else {
      defaultType = "VIEW";
    }
  }

  const { mutateAsync: saveTemplate, isLoading } = useMutation({ mutationFn: (d: any) => saveAsTemplate(d, templateId) });

  async function onUpdateTemplate(data: any) {
    try {
      const { isDynamicHeight, isFlexCanvasEnabled, isCanvasFullHeight } = store.getState().builder;
      await saveTemplate({
        ...data,
        config: {
          formBuilder: denormalizeFormBuilder(),
          dynamicHeight: Boolean(isDynamicHeight),
          flexCanvas: Boolean(isFlexCanvasEnabled),
          canvasFullHeight: Boolean(isCanvasFullHeight),
        },
      });
      enqueueSnackbarRef?.("Posted Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbarRef?.("Save Failed Due To missing Component Key or Id", {
        variant: "error",
      });
    }
  }

  async function onExportTemplate(data: any, _: any) {
    try {
      const { isDynamicHeight, isFlexCanvasEnabled, isCanvasFullHeight } = store.getState().builder;
      await saveTemplate({
        type: data?.type,
        name: data?.name,
        visibility: data?.visibility,
        config: {
          formBuilder: denormalizeFormBuilder(),
          dynamicHeight: Boolean(isDynamicHeight),
          flexCanvas: Boolean(isFlexCanvasEnabled),
          canvasFullHeight: Boolean(isCanvasFullHeight),
        },
      });
      enqueueSnackbarRef?.("Exported Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbarRef?.("Export Failed due to missing Component Key or ID", {
        variant: "error",
      });
    }
  }
  function processItem(item) {
    let newItem = { ...item };

    if (newItem.children && Array.isArray(newItem.children)) {
      newItem.children = newItem.children.map(child => processItem(child));
    }

    if (newItem.optionMap && newItem.selectedType) {
      const { optionMap, selectedType, ...restOfItem } = newItem;

      newItem.optionMap = {
        ...optionMap,
        [selectedType]: restOfItem,
      };
    }

    return newItem;
  }
  const checkUniqueKeysComponent = allComponents => {
    const seenKeys = new Map();

    for (const el of allComponents) {
      const id = el.id;
      const { key } = el.props;
      const typeName = el.type;

      if (!seenKeys.has(key)) {
        seenKeys.set(key, []);
      }
      seenKeys.get(key).push({
        type: typeName,
        id: id,
      });
    }

    // Filter out keys that have more than one element (i.e., duplicates)
    const duplicates: any = [];
    for (const [keyValue, typeList] of seenKeys.entries()) {
      if (typeList.length > 1) {
        typeList.forEach(item => {
          duplicates.push({ key: keyValue, type: item.type, id: item.id });
        });
      }
    }

    const isUnique = duplicates.length === 0;

    return {
      isUnique,
      duplicates,
    };
  };

  const handleBuilderSave = useCallback(() => {
    const allComponent: any = selectAllComponentsArray(store.getState());
    const elementError = allComponent?.find((item: any) => !item?.id || !item?.props?.key);
    const flexCanvasEnabled = store.getState().builder.isFlexCanvasEnabled;

    if (elementError) {
      handleSelectComponent(elementError);
      enqueueSnackbarRef?.("Save Failed Due To missing Component Key or ID", {
        variant: "error",
      });

      return;
    }

    // const isUnique = checkUniqueVarKeys();
    // if (!isUnique) {
    //   enqueueSnackbarRef?.("Vars Keys is Not Unique", {
    //     variant: "error",
    //   });

    //   return;
    // }
    const { isUnique, duplicates } = checkUniqueKeysComponent(allComponent);
    if (!isUnique) {
      // Save duplicates to state so we can show them in modal
      setDuplicates(duplicates);
      enqueueSnackbarRef?.("Component Keys is Not Unique", { variant: "error" });
      const allIds = duplicates.map((duplicate): any => duplicate.id);
      dispatch(
        setViewBuilder({
          selectedItemsId: allIds,
          activeComponent: undefined,
          leftTabValue: 1,
          duplicateKeyIds: allIds,
          addToHistory: false,
        })
      );
      return;
    } else {
      dispatch(
        setViewBuilder({
          duplicateKeyIds: [],
          addToHistory: false,
        })
      );
    }
    function updateChildrenIsRepeated(allComponent, childIds) {
      childIds.forEach(childId => {
        const childIndex = allComponent.findIndex(c => c.id === childId);
        if (childIndex !== -1) {
          const updatedChild = {
            ...allComponent[childIndex],
            isRepeated: true,
          };

          allComponent[childIndex] = updatedChild;

          if (updatedChild.children && updatedChild.children.length > 0) {
            updateChildrenIsRepeated(allComponent, updatedChild.children);
          }
        }
      });
    }

    const updatedComponents = allComponent.map(component => {
      let updatedComponent = { ...component };
      const isRepeated = component?.config?.repeated?.enabled;
      const stepperId = component.config?.stepperParent?.id || component.config?.stepperParent;
      if (stepperId) {
        const stepperParentComponent = allComponent.find(c => c.id === stepperId);
        if (stepperParentComponent) {
          updatedComponent = {
            ...updatedComponent,
            config: {
              ...updatedComponent.config,
              stepperParent: {
                key: stepperParentComponent?.props?.key,
                id: stepperParentComponent?.id,
              },
            },
          };
        }
      }
      if (component?.type === ComponentItemType.StepperNavigator) {
        const stepperNavigatorComponent = allComponent.find(c => c.id === component?.config?.navigator?.groupReference?.id);
        if (stepperNavigatorComponent) {
          updatedComponent = {
            ...updatedComponent,
            config: {
              ...updatedComponent.config,
              navigator: {
                ...updatedComponent?.config?.navigator,
                groupReference: {
                  key: stepperNavigatorComponent?.props?.key,
                  id: stepperNavigatorComponent?.id,
                },
              },
            },
          };
        }
      }
      if (isRepeated) {
        updatedComponent = {
          ...updatedComponent,
          isRepeated: true,
        };
        const childIds = component.children || [];
        updateChildrenIsRepeated(allComponent, childIds);
      }
      if (component.actionsMap) {
        const updatedActionMap = { ...component.actionsMap };
        Object.keys(updatedActionMap).forEach(key => {
          if (Array.isArray(updatedActionMap[key])) {
            updatedActionMap[key] = updatedActionMap[key].map(action => {
              const stepperId = action?.actionConfig?.stepperGroupReference?.id || action?.actionConfig?.stepperGroupReference;
              if (stepperId) {
                const stepperComponent = allComponent.find(c => c.id === stepperId);
                if (stepperComponent) {
                  return {
                    ...action,
                    actionConfig: {
                      ...action.actionConfig,
                      stepperGroupReference: {
                        id: stepperComponent?.id,
                        key: stepperComponent?.props.key,
                      },
                    },
                  };
                }
              }
              return action;
            });
          }
        });
        updatedComponent.actionsMap = updatedActionMap;
      }

      return updatedComponent;
    });

    dispatch(upsertComponents(updatedComponents));

    let updatedChildren = updatedComponents?.filter(item => item.isCustomCanvas).map(changeChildren);
    if (updatedChildren.length == 0) {
      updatedChildren = updatedComponents?.filter(item => !item.parentId).map(changeChildren);
    }

    const updatedFormBuilder = updatedChildren.map(item => processItem(item));
    getCanvasDimension(false);

    const viewHeight = store.getState().builder.height;

    const currentViewConfig: any = store.getState().builder.viewConfiguration;
    const updatedHeights = Object.entries(viewHeight || {}).map(([key, value]) => [key, Number(value)]);
    const updatedView = {
      ...currentViewConfig,
      dataSource: {
        ...currentViewConfig?.dataSource,
        formBuilder: updatedFormBuilder,
        varsConfig: store.getState().builder.varsConfig,
        formBuilderConfig: {
          isCanvasGrid: store.getState().builder.isCanvasGrid,
          isStepperEnabled: store.getState().builder.isStepperEnabled,
          isSyncTreeWithCanvasEnabled: store.getState().builder.isSyncTreeWithCanvasEnabled,
          isDynamicHeight: store.getState().builder.isDynamicHeight,
          isCanvasFullHeight: store.getState().builder.isCanvasFullHeight,
          isFlexCanvasEnabled: store.getState().builder.isFlexCanvasEnabled,
          isContainersBorderEnabled: store.getState().builder.isContainersBorderEnabled,
          isGridOverlayEnabled: store.getState().builder.isGridOverlayEnabled,
        },
      },
      height: viewHeight,
    };
    if (flexCanvasEnabled) {
      _.set(updatedView, "dataSource.formBuilder[0].config.heightPx", Object.fromEntries(updatedHeights));
    }
    setIsSaving(true);
    if (onSave) {
      onSave?.(updatedView, () => {
        setIsSaving(false);
        enqueueSnackbarRef?.("Posted Successfully", {
          variant: "success",
        });
        // saveButtonClickedRef.current = false;
      });
    } else {
      editView?.(
        appId!,
        collectionId!,
        pageId!,
        viewId!,
        updatedView,
        () => {
          setIsSaving(false);

          // saveButtonClickedRef.current = false;
        },
        () => {
          setIsSaving(false);
        }
      );
    }
  }, [appId, collectionId, editView, handleSelectComponent, pageId, viewId]);

  function handleSyncWithBreakpointChange(valueToSyncWith) {
    setSyncWithBreakpoint(valueToSyncWith.target.value);
  }

  return (
    <Box gap={2} display={"flex"} ml={2} height={"45px"}>
      <LoadingButton loading={isLoading} variant='outlined' onClick={handleBackButton}>
        {isEditingTemplate ? "Back" : "Back to config"}
      </LoadingButton>
      {!isHistory && (
        <>
          {isEditingTemplate ? (
            <LoadingButton
              variant='contained'
              loading={isSaving}
              onClick={() => {
                onUpdateTemplate(templateData?.data);
              }}
            >
              Update Template
            </LoadingButton>
          ) : (
            <LoadingButton variant='contained' loading={isSaving} onClick={handleBuilderSave}>
              Save
            </LoadingButton>
          )}
        </>
      )}
      {!isHistory && (
        <>
          <Button
            onClick={event => {
              handleOpenMenu(event);
            }}
            aria-controls='template-menu'
            aria-haspopup='true'
            endIcon={
              <ArrowDropUpIcon
                style={{
                  fontSize: 28,
                  transform: isIconRotated ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            }
          >
            Template
          </Button>
          <Menu id='template-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {!isEditingTemplate && (
              <MenuItem onClick={() => handleSelectOption("Upload")}>
                <ListItemIcon>
                  <img src={UploadIcons} alt='Upload Icon' width='24' height='24' />
                </ListItemIcon>
                Export
              </MenuItem>
            )}
            <MenuItem onClick={() => handleSelectOption("Import")}>
              <ListItemIcon>
                <img src={ImportIcons} alt='Import Icon' width='24' height='24' />
              </ListItemIcon>
              Import
            </MenuItem>
          </Menu>
        </>
      )}
      <LayoutToggleButtons activeLayout={layoutBreak} onLayoutChange={handleChangeLayout} />

      {!isHistory && (
        <Grid display='flex' item xs={12} alignItems='center' gap={"1px"}>
          <OASSelector
            swaggerProps={{
              type: "form-builder",
              onlyModels: true,
              formBuilder: denormalizeFormBuilder(),
              fillFormBuilder: true,
              onSuccess: (values: any) => {
                const normalized = normalizeFormBuilder(values.fromBuilder) as any;
                const formBuilderArray = normalized?.entities ? Object.values(normalized.entities) : [];

                dispatch(setViewBuilder({ builderElements: formBuilderArray }));
              },
            }}
          />
        </Grid>
      )}

      {!isHistory && (
        <>
          <BXModal
            title={"Import template"}
            open={!!openTemplateList}
            onClose={() => {
              setOpenTemplateList(false);
            }}
          >
            <TemplatesTable
              isImport={true}
              setOpenTemplateList={setOpenTemplateList}
              setSelectedRow={setSelectedRow}
              setOpenReplaceModal={setOpenReplaceModal}
              addTemplate={addTemplate}
              templateId={template}
              defaultType={defaultType}
            />
          </BXModal>
          <Dialog
            open={openReplaceModal}
            onClose={() => setOpenReplaceModal(false)}
            closeAfterTransition
            keepMounted={false}
            BackdropProps={{
              timeout: 500,
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle display={"flex"} alignItems='center'>
              <Box flex={1} display={"flex"} alignItems='center'>
                <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
                  Do you want to merge or replace?
                </Typography>
              </Box>
              <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => setOpenReplaceModal(false)}>
                <IconX />
              </Box>
            </DialogTitle>
            <Divider />

            <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
              <Button
                autoFocus
                variant='contained'
                onClick={() => {
                  addTemplate(selectedRow, false);
                  setOpenReplaceModal(false);
                }}
              >
                Merge
              </Button>
              <Button
                variant='outlined'
                autoFocus
                onClick={() => {
                  addTemplate(selectedRow, true);
                  setOpenReplaceModal(false);
                }}
              >
                Replace
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {!isEditingTemplate && (
        <>
          <CreateTemplateModal
            open={openExportTemplate}
            isExport={true}
            templateID={template}
            defaultVisibility={"PUBLIC"}
            defaultType={defaultType}
            onSubmit={onExportTemplate}
            onClose={() => {
              setOpenExportTemplate(false);
            }}
          />
        </>
      )}

      {layoutBreak !== "lg" && (
        <Button variant='outlined' onClick={handleSynchronizeLayout}>
          Synchronize Layout
        </Button>
      )}

      {layoutBreak !== "lg" && (
        <TextField
          select
          value={syncWithBreakpoint}
          onChange={handleSyncWithBreakpointChange}
          label='Sync with breakpoint'
          variant='outlined'
          sx={{ minWidth: 200 }}
        >
          {layoutBreakPoints
            .filter(l => l.id !== layoutBreak)
            .map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.id.toUpperCase()}
              </MenuItem>
            ))}
        </TextField>
      )}
    </Box>
  );
};

export default memo(ViewBuilderHeader);
