import { Box } from "@mui/material";
import { FC } from "react";
import { CustomContainerProps } from "./types";

export const CustomContainer: FC<CustomContainerProps> = props => {
  const {
    config,
    __config,
    metaData,
    onChange,
    info,
    pageId,
    appId,
    renderId,
    "data-bx-key": dataBxKey,
    background,
    border,
    extraSmall,
    small,
    medium,
    large,
    extraLarge,
    placeContent,
    placeItems,
    hover,
    ...restProps
  } = props;
  return (
    <Box
      {...restProps}
      sx={{
        ...(background && { background: background }),
        ...(border && { border: border }),
        ...(hover?.styles && { "&:hover": { ...hover.styles } }),
      }}
    >
      {props?.children}
    </Box>
  );
};
