import Box from "@mui/material/Box";
import { FC, useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { setEndUser } from "src/features/endUser/endUserSlice";
import normalizeEndUser from "src/features/endUser/normalizeEndUser";
import { selectTopLevelComponents } from "src/features/endUser/selectors";
import { useFetchData } from "src/hooks/useFetchData";
import store from "src/store/store";
import { ElementBaseProps } from "src/types/UIElement";
import RenderChildren from "./RenderChildren";

type FormBuilderProps = {
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
    disableDirtyOption?: boolean;
  };
  __data?: any;
  pageId?: string;
  path?: string;
} & ElementBaseProps;

const FormBuilder: FC<FormBuilderProps> = props => {
  const {
    id = "",
    height,
    dataSource,
    info,
    __data = {},
    closeModal,
    parentIds = [],
    toolTip,
    pageId,
    data,
    path,
    handleSelectRow,
    pageOutlet,
    index,
    disablePageDirty,
    disabled,
    dataEntry: _dataEntry,
    viewName,
    isConfirmationModal,
  } = props;

  const { getValue: _getValues } = useAppState();

  // const stripeEndpoints = useBuildxProviderValue("stripeEndpoints");
  const firstLoad = useRef(true);

  const dispatch = useDispatch();
  const topLevelIds: any = useSelector(selectTopLevelComponents(pageId, info?.viewName), shallowEqual);

  const queryKeys = [`${pageId}-${info?.viewName}`];

  const getValues = useCallback(
    (path?: string) => {
      return _getValues(`${pageId}.${info?.viewName}${path ? `.${path}.` : ""}.state`, { pageId, viewName: info?.viewName });
    },
    [pageId, info?.viewName]
  );

  useEffect(() => {
    const topLevel = dataSource?.formBuilder?.filter(item => !item.parentId);
    const normalized = normalizeEndUser(topLevel) as any;
    const endUserArray = normalized?.entities ? Object.values(normalized.entities) : [];
    if (endUserArray.length > 0) {
      const existingEntry: any = store.getState().endUser.application?.pages?.[pageId || 0]?.views?.[info?.viewName || 0];
      if (!existingEntry || isConfirmationModal) {
        const fqdnApp = store.getState().buildxProvider.fqdnApp;
        dispatch(
          setEndUser({
            pageId: pageId,
            viewName: info?.viewName,
            initialStateValues: {
              vars: dataSource?.varsConfig?.vars,
              props: {
                sso: {
                  google: {
                    clientId: fqdnApp?.appConfig.googleInfo.clientId,
                    redirectUri: fqdnApp?.appConfig.googleInfo.redirectUrl,
                  },
                  shopify: {
                    clientId: fqdnApp?.appConfig?.shopifyInfo?.clientId,
                  },
                },
              },
            },
          })
        );
        dispatch(
          setEndUser({
            pageId: pageId,
            viewName: info?.viewName,
            endUserElements: endUserArray,
          })
        );
      }
    }
  }, []);

  return (
    <FormBuilderHandlers
      __data={__data}
      id={id}
      dataSource={dataSource}
      pageId={pageId}
      info={info}
      path={path}
      firstLoad={firstLoad}
      viewName={viewName}
    >
      <PermissibleRender isAllowed path={path} action={["VIEW"]}>
        {({ permitted }) => {
          return (
            permitted && (
              <>
                <Box
                  sx={{
                    position: "relative",
                    height: dataSource?.formBuilderConfig?.isCanvasFullHeight
                      ? "100%"
                      : dataSource?.formBuilderConfig?.isDynamicHeight
                      ? "auto"
                      : height,
                    boxSizing: "border-box",
                  }}
                >
                  {!!topLevelIds?.length &&
                    topLevelIds.map((elementId, key) => {
                      return (
                        <RenderChildren
                          key={elementId}
                          data={elementId}
                          elementId={elementId}
                          pageId={pageId}
                          parentKey={`${pageId}.${info?.viewName}`}
                          index={index}
                          __data={__data}
                          getValues={getValues}
                          parentIds={parentIds}
                          closeModal={closeModal}
                          toolTip={toolTip}
                          info={info}
                          dataSource={dataSource}
                          dataEntry={_dataEntry}
                          viewName={viewName}
                          path={path}
                          handleSelectRow={handleSelectRow}
                          pageOutlet={pageOutlet}
                          disabled={disabled}
                          enableDirtyCheck={info?.disableDirtyOption}
                          disablePageDirty={disablePageDirty}
                        />
                      );
                    })}
                </Box>
              </>
            )
          );
        }}
      </PermissibleRender>
    </FormBuilderHandlers>
  );
};

export default FormBuilder;

const FormBuilderHandlers = props => {
  const { __data, id, dataSource, pageId, info, path, firstLoad, viewName, children } = props;
  const { setValue, watch, cleanTrigger } = useAppState();

  const {
    data: apiData,
    isFetching,
    refetchDataSource,
    fetchNextPage,
  } = useFetchData({
    __data,
    viewId: id,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    dataEntry: dataSource?.dataEntry,
    pageId,
    options: {
      enabled: dataSource?.sourceType == "API" || dataSource?.sourceType == "SIMPLE",
    },
    dataSource: dataSource,
    viewName: info?.viewName,
    path: path,
    queryKeys: [],
  });

  const viewKey: string = `${pageId}.${info?.viewName}`;
  const trigger = watch(`${viewKey}.trigger`, { pageId, viewName: info?.viewName });

  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        await refetchDataSource?.();
        trigger?.payload?.resolver?.();
        cleanTrigger(viewKey, { pageId, viewName: info?.viewName });
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        await fetchNextPage?.(cursor, true);
        trigger?.payload?.resolver?.();
        cleanTrigger(viewKey, { pageId, viewName: info?.viewName });
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  useEffect(() => {
    if (dataSource?.enablePageLoading && firstLoad.current) {
      if (isFetching) {
        setValue(`${pageId}.isLoading`, true, { pageId, viewName });
        setValue(`${pageId}.${info?.viewName}.isLoading`, true, { pageId, viewName });
      } else {
        firstLoad.current = false;
        setValue(`${pageId}.isLoading`, false, { pageId, viewName });
        setValue(`${pageId}.${info?.viewName}.isLoading`, false, { pageId, viewName });
      }
    }
  }, [isFetching]);

  if (isFetching && dataSource?.enablePageLoading && firstLoad.current) {
    return <Box width={"100%"} height={"100vh"} />;
  }

  return children;
};
