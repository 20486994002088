import { Box, BoxProps, Divider, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { IconBackspace } from "@tabler/icons-react";
import { NestedMenuItem } from "mui-nested-menu";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById } from "src/features/builder/selectors";
import store from "src/store/store";
import { v4 as uuid } from "uuid";
import CustomContainerImage from "../../../../../assets/images/icons/custom.svg";
import GridContainerImage from "../../../../../assets/images/icons/GridContainer.svg";
import FlexContainerImage from "../../../../../assets/images/icons/rectangle.svg";
import { ComponentItemType } from "../types";
import { ActionPopoverProps } from "./TreeTypes";

const ActionPopover: React.FC<ActionPopoverProps> = ({
  onClose,
  open,
  handleDeleteItem,
  handleCopyItems,
  activeComponent,
  handlePasteItems,
  selectedItemsId,
  contextMenuPosition,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    if (selectedItemsId?.length > 0) {
      if (handleDeleteItem) {
        handleDeleteItem(selectedItemsId);
      }
    } else if (activeComponent) {
      if (handleDeleteItem) {
        handleDeleteItem([activeComponent?.id]);
      }
    }
    onClose();
  };

  const handleCopy = () => {
    if (handleCopyItems) {
      const activeComponentId = activeComponent?.id;
      handleCopyItems(activeComponentId, selectedItemsId);
    }
    onClose();
  };

  const handlePaste = () => {
    if (handlePasteItems) {
      handlePasteItems();
    }
    onClose();
  };

  const getRandomValue = (min, max) => Math.random() * (max - min) + min;

  const findSelectedItems = selectedItemsId => {
    const normalizedIds = Array.isArray(selectedItemsId) ? selectedItemsId : [selectedItemsId];
    let foundItems: any = [];
    normalizedIds.forEach(itemId => {
      const item = selectComponentById(store.getState(), itemId);
      if (item) {
        foundItems.push(item);
      }
    });

    return foundItems;
  };

  const findParentComponent = selectedItemsId => {
    const normalizedIds = Array.isArray(selectedItemsId) ? selectedItemsId : [selectedItemsId];
    for (const itemId of normalizedIds) {
      // Get the current item
      const item = selectComponentById(store.getState(), itemId);
      if (!item) continue;
      // Get the parent component by comparing parentId
      const parent = item.parentId ? selectComponentById(store.getState(), item.parentId) : null;
      if (parent) {
        return parent;
      }
    }

    return null;
  };

  const getLevel = element => {
    let level = 0;
    let current = element;
    while (current) {
      const parent = findParentComponent(current.id);
      if (parent) {
        level += 1;
        current = parent;
      } else {
        break;
      }
    }
    return level;
  };

  const areAllSelectedItemsAtSameLevel = selectedItemsId => {
    const normalizedIds = Array.isArray(selectedItemsId) ? selectedItemsId : [selectedItemsId];
    const selectedItems = findSelectedItems(normalizedIds);
    if (selectedItems?.length === 0) return false;

    const levels = selectedItems.map(item => getLevel(item));
    return levels.every(level => level === levels[0]);
  };

  const handlePlaceInContainer = (containerType: string) => {
    const newId = uuid();

    const images = {
      FlexContainer: FlexContainerImage,
      GridContainer: GridContainerImage,
      CustomContainer: CustomContainerImage,
    };

    const typeMappings = {
      FlexContainer: ComponentItemType.FlexContainer,
      GridContainer: ComponentItemType.GridContainer,
      CustomContainer: ComponentItemType.CustomContainer,
    };

    const randomWidthPx = getRandomValue(100, 300);
    const randomHeightPx = getRandomValue(50, 200);
    const randomTop = getRandomValue(50, 500);
    const randomLeft = getRandomValue(50, 500);

    const containerParent = {
      id: newId,
      type: typeMappings[containerType] || ComponentItemType.FlexContainer,
      props: {
        sx: {
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          padding: "10px",
          flexDirection: "column",
        },
        id: `${containerType}-${newId}`,
        key: `${containerType}-${newId}`,
        testId: "",
      } as BoxProps,
      config: {
        defaultWidth: 300,
        defaultHeight: 200,
        minWidth: 0,
        minHeight: 0,
        fixedWidth: false,
        fixedHeight: false,
        isPercentageHeight: {
          lg: true,
        },
        isDynamicHeight: {
          lg: true,
        },
        isDynamicWidth: {
          lg: true,
        },
        sideMenuSx: {
          border: "1px dashed #57585c",
          minHeight: "100px",
        },
        placeholderConfig: {
          title: "Flex",
          image: images[containerType] || FlexContainerImage,
          group: "container",
        },
        widthPx: {
          xs: 300,
          lg: 300,
        },
        heightPx: {
          xs: 200,
          lg: 200,
        },
        widthPercentage: {
          xs: `${(randomWidthPx / 1182) * 100}%`,
          lg: `${(randomWidthPx / 1182) * 100}%`,
        },
        heightPercentage: {
          xs: `${(randomHeightPx / 485) * 100}%`,
          lg: `${(randomHeightPx / 485) * 100}%`,
        },
      },
      level: null,
      isSideMenu: true,
      hasCustom: false,
      top: {
        xs: randomTop,
        lg: randomTop,
      },
      left: {
        xs: randomLeft,
        lg: randomLeft,
      },
      leftPercentage: {
        xs: `${(randomLeft / 1182) * 100}%`,
        lg: `${(randomLeft / 1182) * 100}%`,
      },
      index: 1,
      boxPosition: {
        width: 1182,
        height: 485,
        y: randomTop,
        x: randomLeft,
      },
      children: [],
    };

    // Get the selected items
    let selectedItems = findSelectedItems(selectedItemsId);
    if (selectedItems.length === 0 && activeComponent) {
      selectedItems = findSelectedItems([activeComponent.id]);
    }
    if (!selectedItems || selectedItems.length === 0) {
      return;
    }

    const idsToMove = selectedItemsId?.length > 0 ? selectedItemsId : activeComponent ? [activeComponent.id] : [];
    const parentComponent = findParentComponent(idsToMove[0]);

    // Create the new container with the selected items as children
    const newContainer = {
      ...containerParent,
      children: idsToMove,
    };

    if (parentComponent) {
      // Remove the selected items from their current parent
      const updatedParentComponent = {
        ...parentComponent,
        children: parentComponent.children.filter(childId => !idsToMove.includes(childId)),
      };

      // Add the new container to the parent's children
      updatedParentComponent.children.push(newId);

      // Update both the parent and the new container
      dispatch(
        upsertComponents([
          updatedParentComponent,
          newContainer,
          ...selectedItems.map(item => ({
            ...item,
            parentId: newId,
          })),
        ])
      );
    } else {
      // If there's no parent, just update the new container and the selected items
      dispatch(
        upsertComponents([
          newContainer,
          ...selectedItems.map(item => ({
            ...item,
            parentId: newId,
          })),
        ])
      );
    }
    onClose();
  };

  const actionMenuItems = [
    { label: "Copy", shortcut: "Ctrl + C", action: handleCopy },
    { label: "Paste", shortcut: "Ctrl + V", action: handlePaste },
    {
      label: "Delete",
      shortcut: "",
      action: handleDelete,
      icon: <IconBackspace width={18} height={18} />,
      divider: true,
    },
  ];

  const nestedActionMenuItems = [
    { label: "Custom Container", type: "CustomContainer" },
    { label: "Grid Container", type: "GridContainer" },
    { label: "Flex Container", type: "FlexContainer" },
  ];

  const getNestedActionMenuItems = (actionMenuItems: Array<any>, name: string) => {
    return (
      <NestedMenuItem parentMenuOpen={true} label={name}>
        {actionMenuItems &&
          actionMenuItems.map((option, index) =>
            option && option.items && option.items.length > 0 ? (
              getNestedActionMenuItems(option.items, option.name)
            ) : (
              <MenuItem key={index} onClick={() => handlePlaceInContainer(option.type)}>
                {option.label}
              </MenuItem>
            )
          )}
      </NestedMenuItem>
    );
  };

  return (
    <>
      <Popover
        open={open}
        onClose={onClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenuPosition
            ? {
                top: contextMenuPosition.top,
                left: contextMenuPosition.left,
              }
            : undefined
        }
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            maxHeight: "80vh",
            overflow: "auto",
          },
        }}
        marginThreshold={16}
      >
        <Stack spacing={1} sx={{ padding: "4px" }}>
          {actionMenuItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.divider && <Divider />}
              <MenuItem onClick={item.action} sx={{ marginBottom: item.divider ? "8px" : "0px" }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: "100%" }}>
                  <Typography sx={{ marginRight: 10 }}>{item.label}</Typography>
                  <Stack direction='row' alignItems='center'>
                    {item.shortcut && <Typography sx={{ opacity: 0.6, fontSize: "10px" }}>{item.shortcut}</Typography>}
                    {item.icon && (
                      <>
                        <Box sx={{ opacity: 0.6 }}>{item.icon}</Box>
                      </>
                    )}
                  </Stack>
                </Stack>
              </MenuItem>
            </React.Fragment>
          ))}
          {areAllSelectedItemsAtSameLevel(selectedItemsId?.length === 0 ? [activeComponent?.id] : selectedItemsId) && (
            <React.Fragment>{getNestedActionMenuItems(nestedActionMenuItems, "Place in Container")}</React.Fragment>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default memo(ActionPopover);
